import React from "react";
import "../styles/Table.css";
import { Fragment } from "react";
import {
    useTable,
    useFilters,
    useSortBy,
    useGlobalFilter,
    usePagination,
    useRowSelect,
} from "react-table";
import updown_sort from "../../assests/image/updown_sort.svg";
import { TableCheckbox } from "../tableComponents/TableCheckbox";
import pendingScholarship from "../../../redux/actionDispatcher/account/pendingScholarshipTableDispatcher";
import AllUrl from "../../../redux/constants/url";
import { connect } from "react-redux";
import SkeletonColor from "../../../helpers/Skeletrone";
import { ToastContainer } from "react-toastify";
import Pagination from "../../assests/common/Pagination";
import { Tooltip, Whisper } from "rsuite";
import NoDataFound from "../../assests/common/NoDataFound";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import allUrls from "../../../redux/constants/url";
import { isSuperAdmin } from "../../../helpers/SuperAdmin";
import { isAccountAdmin } from "../../../helpers/AccountAdmin";
import { CSVLink } from "react-csv";
import { DeactivateButton } from "../../assests/common/Color";

export const MultipleFilter = (rows, accessor, filterValue) => {
    const arr = [];
    rows.forEach((val) => {
        if (filterValue.includes(val.original[accessor])) arr.push(val);
    });
    return arr;
};

function BusFeesDetailsTable({
    scholarData,
    fetchData,
}) {
    const token = localStorage.getItem("token");
    const { sessionId, track } = useParams();
    const [sessionsList, setSessionsList] = useState(null);
    const [selectedBatch, setSelectedBatch] = useState(sessionId);
    const [selectedTrack, setSelectedTrack] = useState(track);
    const [selectedMonth, setSelectedMonth] = useState();
    const [selectedChoice, setSelectedChoice] = useState(2);

    const [MoneyCount, setMoneyCount] = useState({
        TMonths: 0,
        TAmount: 0,
        TpaidAmount: 0,
        RAmount: 0,
        TsetteledAmount: 0,
        TBusPassCount: 0,
        TCompletedPassCount: 0,
    });

    const trackNameList = [
        {title: "Harda",dataQuery: `Harda`},
        {title: "Kannod", dataQuery: `Kannod`},
        {title: "Khategaon", dataQuery: `Khategaon`},
        {title: "Nasrullaganj", dataQuery: `Nasrullaganj`},
        {title: "Nemawar", dataQuery: `Nemawar`},
        {title: "Sandalpur", dataQuery: `Sandalpur`},
        {title: "Satwas", dataQuery: `Satwas`},
        {title: "All Tracks", dataQuery: 'All'}
    ];

    localStorage.setItem("batchYear", selectedBatch);
    const navigate = useNavigate();

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                var con = {
                    method: "GET",
                    url: allUrls.getSessionList,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                };

                const sessionList = await axios(con);
                if (sessionList.status === 200) {
                    setSessionsList(sessionList?.data);
                }
            } catch (error) {
                console.log("error to fetch Session List : ", error);
            }
        };
        fetchData();
    }, []);

    React.useEffect(() => {
        if (sessionId && track) {
            var config = {
                method: "GET",
                url: `${AllUrl.getTrackwiseBusDetails}/${selectedBatch}/${selectedTrack}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };
            fetchData(config);
        }
        localStorage.setItem("batchYear", selectedBatch);

        const updateParams = () => {
            if (selectedChoice === 1) {
                isSuperAdmin() && navigate(`/admin_dashboard/pendingbusfeesstudent/${selectedBatch}/${selectedTrack}/${selectedMonth}`);
                isAccountAdmin() && navigate(`/account_admin_dashboard/pendingbusfeesstudent/${selectedBatch}/${selectedTrack}/${selectedMonth}`);
            } else if (selectedChoice === 2) {
                isSuperAdmin() && navigate(`/admin_dashboard/pendingbusfees/${selectedBatch}/${selectedTrack}`);
                isAccountAdmin() && navigate(`/account_admin_dashboard/pendingbusfees/${selectedBatch}/${selectedTrack}`);
            }
        };

        updateParams();
    }, [sessionId, track, selectedBatch, selectedTrack, selectedMonth]);

    const mainColoumns = React.useMemo(
        () => [
            {
                header: "S.No",
                accessor: "Srno",
                Cell: ({ row: { original, index } }) => {
                    return index + 1;
                },
                Filter: "",
                filter: "",
            },
            {
                header: "Track",
                accessor: "trackName",
                Filter: "",
                filter: "",
            },
            {
                header: "Total Pass",
                accessor: "totalBusPass",
                Filter: "",
                filter: "",
            },
            {
                header: "Completed Pass",
                accessor: "compeletePass",
                Filter: "",
                filter: "",
            },
            {
                header: "Total amount",
                accessor: "totalAmount",
                Filter: "",
                filter: "",
            },
            {
                header: "Received amount",
                accessor: "receivedAmount",
                Filter: "",
                filter: "",
            },
            {
                header: "Pending amount",
                accessor: "remainAmount",
                Cell: ({ row: { original } }) => (
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="d-flex justify-content-center align-items-center" style={{ justifyContent: 'center' }}>
                            <span
                                className="recieved-fee-circle"
                                style={{
                                    backgroundColor: `${original.remainAmount <= 0 ? "#56F000" : "#ffc107"
                                        }`,
                                    marginRight: "10px",
                                    marginLeft: "20px",
                                }}
                            ></span>
                            {original.remainAmount}
                        </div>
                    </div>
                ),
                Filter: "",
                filter: "",
            },
            {
                header: "Waive off",
                accessor: "setteldAmount",
                Filter: "",
                filter: "",
            },
            {
                header: "Month",
                accessor: "month",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Whisper
                        placement="top"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={<Tooltip>{`View ${original.month}`}</Tooltip>}
                    >
                        <button
                            className="px-2"
                            style={{ ...DeactivateButton, width: '120px' }}
                            onClick={() => {
                                setSelectedMonth(original.month);
                                setSelectedChoice(1);
                            }}
                        >
                            {original.month}
                        </button>
                    </Whisper>
                ),
            },

        ],
        []
    );

    const [columns, setColoumns] = useState(mainColoumns);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        gotoPage,
        pageCount,
        previousPage,
        canPreviousPage,
        canNextPage,
        setPageSize,
        pageOptions,
        selectedFlatRows,
        state,
        rows,
        prepareRow,
    } = useTable(
        { columns, data: scholarData.table_data },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id: "selection",
                        header: ({ getToggleAllRowsSelectedProps }) => (
                            <TableCheckbox {...getToggleAllRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => (
                            <TableCheckbox {...row.getToggleRowSelectedProps()} />
                        ),
                    },
                    ...columns,
                ];
            });
        }
    );

    const { pageSize, pageIndex } = state;
    var idData = [];
    var exportCsv = [];
    const checkboxData = JSON.stringify(
        {
            selectedFlatRows: selectedFlatRows.forEach((row) => {
                let data = Object.assign({}, row.original);
                delete data.photo;
                idData.push(data.id);
                exportCsv.push(data);
            }),
        },
        null,
        2
    );
    React.useEffect(() => {
        let data = rows;
        let RAmount = 0;
        let TAmount = 0;
        let TpaidAmount = 0;
        let TsetteledAmount = 0;
        let TBusPassCount = 0;
        let TCompletedPassCount = 0;

        data?.forEach((ele) => {
            ele = ele.original;
            RAmount += ele?.remainAmount;
            TAmount += ele?.totalAmount;
            TpaidAmount += ele?.receivedAmount;
            TsetteledAmount += ele?.setteldAmount;
            TBusPassCount += parseInt(ele?.totalBusPass);
            TCompletedPassCount += parseInt(ele?.compeletePass);
        });

        setMoneyCount((val) => {
            return {
                TMonths: data.length,
                RAmount,
                TAmount,
                TpaidAmount,
                TsetteledAmount,
                TBusPassCount,
                TCompletedPassCount
            };
        });

    }, [rows]);

    return scholarData.loading ? (
        <SkeletonColor></SkeletonColor>
    ) : (
        <Fragment>
            <ToastContainer
                position="top-center"
                autoClose={2500}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="container-fluid">
                <div
                    style={{
                        position: "sticky",
                        top: "80px",
                        width: "100%",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        backgroundColor: "#f4f7fc",
                        zIndex: "6",
                    }}
                >
                    <div
                        className="d-flex row Stu-Acc-info "
                        style={{
                            color: "rgb(90, 96, 127)",
                            margin: "Auto",
                            height: "70px",
                        }}
                    >
                        <div className="col d-flex align-items-center justify-content-center info-col m-2">
                            <h5 style={{ fontSize: '17px' }}>
                                {" "}
                                {MoneyCount.TMonths} <br /> <p style={{ fontSize: '14px' }}>Total Months</p>{" "}
                            </h5>
                        </div>

                        <div className="col info-col m-2">
                            <div className='row d-flex align-items-center' style={{ height: '100%' }}>
                                <div className="col" style={{ borderRight: '2px dashed #5A607F' }}>
                                    <h5 style={{ fontSize: '17px' }}>
                                        {MoneyCount.TAmount
                                            ? `${MoneyCount.TAmount.toLocaleString("en-IN")}`
                                            : "-"}{" "}
                                        <br /> <p style={{ fontSize: '14px' }}>{"Total Amount"}</p>{" "}
                                    </h5>
                                </div>
                                <div className="col">
                                    <h5 style={{ fontSize: '17px' }}>
                                        {MoneyCount.TBusPassCount
                                            ? `${parseInt(MoneyCount.TBusPassCount)}`
                                            : "-"}{" "}
                                        <br /> <p style={{ fontSize: '14px' }}>{"Total Pass"}</p>{" "}
                                    </h5>
                                </div>
                            </div>
                        </div>

                        <div className="col info-col m-2">
                            <div className='row d-flex align-items-center' style={{ height: '100%' }}>
                                <div className="col" style={{ borderRight: '2px dashed #5A607F' }}>
                                    <h5 style={{ fontSize: '17px' }}>
                                        {MoneyCount.TpaidAmount
                                            ? `${MoneyCount.TpaidAmount.toLocaleString("en-IN")}`
                                            : "-"}{" "}
                                        <br /> <p style={{ fontSize: '14px' }}>{"Paid Amount"}</p>{" "}
                                    </h5>
                                </div>
                                <div className="col">
                                    <h5 style={{ fontSize: '17px' }}>
                                        {MoneyCount.TCompletedPassCount
                                            ? `${parseInt(MoneyCount.TCompletedPassCount)}`
                                            : "-"}{" "}
                                        <br /> <p style={{ fontSize: '14px' }}>{"Completed Pass"}</p>{" "}
                                    </h5>
                                </div>
                            </div>
                        </div>

                        <div className="col info-col m-2">
                            <div className='row d-flex align-items-center' style={{ height: '100%' }}>
                                <div className="col" style={{ borderRight: '2px dashed #5A607F' }}>
                                    <h5 style={{ fontSize: '17px' }}>
                                        {MoneyCount.RAmount
                                            ? `${MoneyCount.RAmount.toLocaleString("en-IN")}`
                                            : "-"}{" "}
                                        <br /> <p style={{ fontSize: '14px' }}>{"Remain Amount"}</p>{" "}
                                    </h5>
                                </div>
                                <div className="col">
                                    <h5 style={{ fontSize: '17px' }}>
                                        {MoneyCount.TBusPassCount
                                            ? ` ${parseInt(MoneyCount.TBusPassCount - MoneyCount.TCompletedPassCount)}`
                                            : "-"}{" "}
                                        <br /> <p style={{ fontSize: '14px' }}>{"Remain Pass"}</p>{" "}
                                    </h5>
                                </div>
                            </div>
                        </div>

                        <div className="col info-col m-2">
                            <h5 style={{ marginTop: "12px", fontSize: '17px' }}>
                                {MoneyCount.TsetteledAmount.toLocaleString("en-IN")} <br />{" "}
                                <p style={{ fontSize: '14px' }}>{"Waive Off"}</p>{" "}
                            </h5>
                        </div>
                    </div>

                    <div className="d-flex mt-3 mx-0">
                        <div className="">
                            <select
                                className="form-select table_select_row_options"
                                value={pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}
                            >
                                {[10, 25, 50, 100].map((pageSize) => (
                                    <option value={pageSize} key={pageSize}>
                                        Show Entries {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="ml-4">
                            <select
                                className="form-select table_select_row_options"
                                value={selectedBatch}
                                onChange={(e) => setSelectedBatch(e.target.value)}
                            >
                                {sessionsList?.map((item, index) => (
                                    <option value={item?.sessionId} key={index}>
                                        Session: {item?.sessionName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="ml-4">
                            <select
                                className="form-select table_select_row_options"
                                value={selectedTrack}
                                onChange={(e) => setSelectedTrack(e.target.value)}
                            >
                                {trackNameList?.map((item, index) => (
                                    <option value={item?.dataQuery} key={index}>
                                        {item?.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="ms-4">
                            <div className="btn-group  ml-3">
                                <button
                                    className="btn  btn-sm download-btn dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Download
                                </button>
                                <div className="dropdown-menu mt-1" style={{ zIndex: "10" }}>
                                    <div>
                                        <div>
                                            <CSVLink
                                                className="dropdown-item"
                                                style={{ fontWeight: "bold" }}
                                                data={exportCsv}
                                            >
                                                Excel
                                            </CSVLink>
                                        </div>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <table {...getTableProps()} id="customers">
                    <thead
                        style={{
                            position: "sticky",
                            top: "135px",
                            width: "100%",
                            backgroundColor: "#f4f7fc",
                            zIndex: "5",
                        }}
                    >
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render("header")}
                                        <span>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <img
                                                        src={updown_sort}
                                                        style={{ marginLeft: "5px" }}
                                                        alt=""
                                                    />
                                                ) : (
                                                    <img
                                                        src={updown_sort}
                                                        style={{ marginLeft: "5px" }}
                                                        alt=""
                                                    />
                                                )
                                            ) : (
                                                ""
                                            )}
                                            {column.isSorted ? (column.isSortedDesc ? "" : "") : ""}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <NoDataFound rows={rows} />

                <Pagination
                    data={rows}
                    rows={rows}
                    page={page}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    pageSize={pageSize}
                    canPreviousPage={canPreviousPage}
                    previousPage={previousPage}
                    pageOptions={pageOptions}
                    gotoPage={gotoPage}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                />
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        scholarData: state.pendingScholarship,
    };
};

//passing the userData in fetchData function and also dispatch method
const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (data) => dispatch(pendingScholarship(data)),
    };
};

//Connecting the component to our store
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusFeesDetailsTable);
