import axios from "axios";
import Swal from "sweetalert2";

import {
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_FAIL,
} from "../../constants/actions";

export const sendNotification = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(sendNotificationReq());
      axios(data)
        .then(function (response) {
          if (response.status === 200) {
            dispatch(sendNotificationReqSuccess());
            Swal.fire({
              position: "top-center",
              icon: "success",
              title: "Notification Sent Successfully!",
              showConfirmButton: false,
              timer: 2500,
            });
          } else if (response.status === 404) {
            dispatch(sendNotificationReqFail());
            Swal.fire({
              position: "top-center",
              icon: "warning",
              title: "404 status!",
              showConfirmButton: false,
              timer: 2500,
              showClass: {
                backdrop: "swal2-noanimation", // disable backdrop animation
                popup: "", // disable popup animation
                icon: "", // disable icon animation
              },
              hideClass: {
                popup: "", // disable popup fade-out animation
              },
            });
          }
        })
        .catch(function (error) {
          dispatch(sendNotificationReqFail());
          Swal.fire({
            position: "top-center",
            icon: "error",
            title: "Something went wrong!",
            showConfirmButton: false,
            timer: 2500,
            showClass: {
              backdrop: "swal2-noanimation", // disable backdrop animation
              popup: "", // disable popup animation
              icon: "", // disable icon animation
            },
            hideClass: {
              popup: "", // disable popup fade-out animation
            },
          });
        });
    } catch (error) {
      dispatch(sendNotificationReqFail());
      Swal.fire({
        position: "top-center",
        icon: "error",
        title: "Something went wrong!",
        showConfirmButton: false,
        timer: 2500,
        showClass: {
          backdrop: "swal2-noanimation", // disable backdrop animation
          popup: "", // disable popup animation
          icon: "", // disable icon animation
        },
        hideClass: {
          popup: "", // disable popup fade-out animation
        },
      });
    }
  };
};

export const sendNotificationReq = () => {
  return {
    type: SEND_NOTIFICATION_REQUEST,
  };
};

export const sendNotificationReqSuccess = (data) => {
  return {
    type: SEND_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const sendNotificationReqFail = () => {
  return {
    type: SEND_NOTIFICATION_FAIL,
  };
};
