import { useEffect, useRef, useState } from 'react'
import { ExcelDataReader } from '../../utils/ExcelDataReader';
import dropIcon from '../../assets/drag-and-drop.png'
import { ShowError } from './showError/ShowError';
import { ShowPdf } from './showCertificate/ShowPdf';
import './Certificate.css'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import { DownloadPdf } from '../../utils/Download';
import allUrls from '../../../../../redux/constants/url';
import axios from 'axios';
export const Certificate = () => {
    const [dataName, setDataName] = useState("");
    const [showDemo, setShowDemo] = useState(false);
    const [submitClick, setSubmitClick] = useState(false);
    const [showError, setShowError] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [excel, setExcel] = useState([]);
    const [name, setName] = useState('');
    const [group, setGroup] = useState('');
    const [level, setLevel] = useState('');
    const [knowledge, setKnowledge] = useState('');
    const [knowledgeLevelSkills, setKnowledgeLevelSkills] = useState([]);
    const [ButtonClickError, setButtonClickError] = useState('');
    const inputFile = useRef(null);

    // Function to fetch certification details
    const getCertificationDetails = async (group, level) => {
        try {
            // get knowledge level skills from local storage if available.
            const getKnowledgeLevelSkills = localStorage.getItem(`${group}_${level}_knowledgeLevelSkills`)
            if (getKnowledgeLevelSkills) {
                const skills = JSON.parse(getKnowledgeLevelSkills);
                console.log(`Skills already fetched: ${skills.length} items.`, skills);
                setKnowledge("");
                setKnowledgeLevelSkills(skills);
                return;
            }
            // fetch knowledge level skills from the server if not available in local storage. 
            const res = await axios.get(`${allUrls.levelCertificationsSkills}/${group}/${level}`);
            setKnowledgeLevelSkills(res.data);
            localStorage.setItem(`${group}_${level}_knowledgeLevelSkills`, JSON.stringify(res.data));
        } catch (error) {
            console.log('Error fetching certification details:', error);
        }
    }

    // Handle changes in group
    const handleGroupChange = (e) => {
        setGroup(e.target.value);
    };

    // Handle changes in level
    const handleLevelChange = (e) => {
        setLevel(e.target.value);
    };

    useEffect(() => {
        if (group && level) {
            getCertificationDetails(group, level);
        }
    }, [group, level]);

    useEffect(() => { console.log("level is ", level) }, [level]);
    const handleFileChange = async (event) => {
        setSelectedFile(event.target.files[0]);
        let arr = await ExcelDataReader(event.target.files[0]);
        if (typeof arr[0] === 'string') {
            console.log("type is string")
            setExcel(arr);
            handleErrorShow();
        } else {
            arr ? setExcel([...arr]) : setExcel([]);
            handleErrorRemove();
        }
        console.log("now excel is ", arr);
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
        setShowError(false);
        if (inputFile.current) {
            inputFile.current.value = '';
            inputFile.current.type = 'text';
            inputFile.current.type = 'file';
        }
        setExcel([]);
    };

    const handleDrop = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (dataName === "") {
            const file = event.dataTransfer.files[0];
            setSelectedFile(file);
            let arr = await ExcelDataReader(file);

            if (typeof arr[0] === 'string') {
                console.log("type is string ");
                setExcel(arr);
                handleErrorShow();
            } else {
                arr ? setExcel([...arr]) : setExcel([]);
                handleErrorRemove();
            }
            console.log("now excel is ", arr);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleErrorShow = () => setShowError(true);
    const handleErrorRemove = () => setShowError(false);

    useEffect(() => {
        if (excel.length && submitClick && level !== "") {
            excel.forEach((value) => {
                DownloadPdf(level, value.name,null, group, knowledge);
            });
            setSubmitClick(false);
            handleRemoveFile();
            setLevel("");
            setGroup("");
            setKnowledgeLevelSkills([]);
        }
    }, [excel, level, submitClick, group, knowledge]);

    useEffect(() => {
        if (name?.length && submitClick && level !== "") {
            DownloadPdf(level, name, setName, group, knowledge);
            setSubmitClick(false);
            setLevel("");
            setGroup("");
            setKnowledgeLevelSkills([]);
        }
    }, [name, level, submitClick, group, knowledge])
    return (
        < >
            <div
                className="container-form me-3 d-flex flex-column justify-content-center align-items-center bg-transparent "
            >
                <div className='  shadow-sm px-3 rounded-3  certificate-container mt-3 mt-lg-0 bg-white' style={{ minWidth: '500px', minHeight: "90%" }}>
                    <div> <h5 className=' text-center my-3'> Generate Certificate</h5></div>

                    <div className=' mb-2'>
                        <label htmlFor="name" className='addStdLable'>Name</label>
                        <input
                            type="text"
                            value={dataName}
                            disabled={excel.length ? true : false}
                            onChange={(e) =>
                                setDataName(e.target.value)}
                            className=" form-control "
                            name="name"
                            placeholder='Student Name'
                            id="name"
                        />
                    </div>

                    <div className=' mb-2'>
                        <label htmlFor="group" className='addStdLable'>Group</label>
                        <select
                            type="text"
                            value={group}
                            onChange={handleGroupChange}
                            className=" form-select "
                            name="group"
                            id="group"
                        >
                            <option value="">Select Group</option>
                            <option value="iteg">ITEG</option>
                            <option value="meg">MEG</option>
                            <option value="beg">BEG</option>

                        </select>
                    </div>

                    <div className=' mb-2'>
                        <label htmlFor="level" className='addStdLable'>Level</label>
                        <select
                            value={level}
                            onChange={handleLevelChange}
                            className=" form-select "
                            name="level"
                            id="level"
                        >
                            <option value="">Select Level</option>
                            <option value="1">Level 1</option>
                            <option value="2">Level 2</option>
                            <option value="3">Level 3</option>
                        </select>
                    </div>

                    <div className=' mb-2'>
                        <label htmlFor="knowledge" className='addStdLable'>Knowledge</label>
                        <select
                            name="knowledge"
                            value={knowledge}
                            onChange={(e) =>
                                setKnowledge(e.target.value)}
                            className=" form-select "
                            id='knowledge'>
                            <option>Select Knowledge</option>
                            {knowledgeLevelSkills.map((ele, i) => {
                                return (
                                    <option key={i} value={ele}>{ele}</option>
                                )
                            })}
                        </select>
                    </div>

                    <div className="text-center text-secondary">or</div>

                    <div className="file-field-container my-3 d-flex flex-column justify-content-center align-items-center    my-1 my-md-2 w-auto text-center rounded-3  py-1 position-relative  form-control "
                        onDrop={handleDrop}
                        style={{ height: "40%" }}
                        onDragOver={handleDragOver}
                    >
                        <img src={dropIcon} style={{ width: "100px" }} height={"50%"} alt="" className=' w-25 mt-md-2' />

                        <label htmlFor="files" className="cursor-pointer d-flex flex-column  justify-content-end  align-items-center  w-100 h-100">
                            <span>  Upload student name excel list </span>
                            <span className=' fw-bold fs-6 '>or drop it here</span>
                        </label>

                        <input
                            id="files"
                            className='file-field '
                            style={{ visibility: 'hidden', width: '1px', height: '1px' }}
                            name="excelFile"
                            disabled={dataName !== "" ? true : false}
                            ref={inputFile}
                            accept=".xlsx, .xls, .xlsm, .xlsb, .csv, .xlam, .xltx, .xltm"
                            onChange={handleFileChange}
                            type="file"
                        />
                    </div>
                    <div className="my-0 d-flex justify-content-center">
                        {selectedFile ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span>{selectedFile.name}</span>
                                <button
                                    type="button"
                                    onClick={handleRemoveFile}
                                    className="fw-bold cursor-pointer ms-2 px-1 border-0 text-danger rounded-circle"
                                    title="remove file"
                                >
                                    &#x2715;
                                </button>
                            </div>
                        ) : null}
                    </div>
                    <div className=' py-3 d-flex  justify-content-between'>
                        <button className=' btn btn-outline-dark' onClick={() => {
                            if (dataName.length && group !== "" && level !== "" && knowledge !== "") {
                                setShowDemo(true);
                            } else {
                                if(!dataName.length){
                                    setButtonClickError("Please Enter Studennt Name before Preview..!");
                                }else if(group === ""){
                                    setButtonClickError("Please Select Group of Student before Preview..!");
                                }else if(level === ""){
                                    setButtonClickError("Please Select Level of Certification before Preview..!");
                                }else{
                                    setButtonClickError("Please Select Student Knowledge Level before Preview..!");
                                }
                            }
                        }}> Preview</button>
                        <button
                            className=' btn btn-outline-dark active'
                            onClick={() => {
                                if ((dataName.length || excel[0]) && group !== "" && level !== "" && knowledge !== "") {
                                    setName(capitalizeFirstLetter(dataName));
                                    setSubmitClick(true);
                                    setDataName("")
                                } else {
                                    if(!(dataName.length || excel[0])){
                                        setButtonClickError("Please Enter Student Name or Upload Students Name Excel File before Submit..!");
                                    }else if(group === ""){
                                        setButtonClickError("Please Select Group of Student before Submit..!");
                                    }else if(level === ""){
                                        setButtonClickError("Please Select Level of Certification before Submit..!");
                                    }else {
                                        setButtonClickError("Please Select Knowledge Level of Student before Submit..!");
                                    }
                                }
                            }}
                        >
                            Submit
                        </button>

                    </div>

                    {showDemo && level !== "" && dataName?.length ? <ShowPdf show={showDemo} group={group} name={dataName} level={level} setShow={setShowDemo} knowledge={knowledge} /> : null}
                    {ButtonClickError?.length > 0 ? <ShowError type={"Invalid Input Data"} error={ButtonClickError} modalShow={ButtonClickError.length > 0} onClick={() => { setButtonClickError("") }} /> : null}
                    {showError ? <ShowError type={"Invalid File"} error={excel} modalShow={showError} setSelectedFile={setSelectedFile} setModalShow={setShowError} onClick={handleRemoveFile} /> : null}
                </div>
            </div>
        </>
    )
}