/* eslint-disable react-hooks/exhaustive-deps */
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useEffect } from "react";
import "../styles/dashboard.css";
import { Whisper, Tooltip } from "rsuite";
import { useNavigate } from "react-router-dom";
import eyeIcon from "../../assests/image/eyeIcon.svg";
import { isAccountAdmin } from "../../../helpers/AccountAdmin";
import { isSuperAdmin } from "../../../helpers/SuperAdmin";

function Dashboard({ instData }) {
  const currentSession = localStorage.getItem('batchYear');
  const navigate = useNavigate();
  useEffect(async () => {
    const installmentChartDiv2 = document.getElementById("busspasschartdiv");
    if (installmentChartDiv2) {
      installmentChartDiv2.remove();
    }
    const installmentChartDiv = document.createElement("div");
    installmentChartDiv.id = "busspasschartdiv";
    installmentChartDiv.style.width = "100%";
    installmentChartDiv.style.height = "260px";
    document.getElementById("busDiv").appendChild(installmentChartDiv);

    if (instData) {

      let root = am5.Root.new("busspasschartdiv");
      root._licenseApplied();
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: true,
          wheelX: "panY",
          wheelY: "zoomY",
          layout: root.verticalLayout,
        })
      );

      // Add scrollbar
      //www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      https: chart.set(
        "scrollbarY",
        am5.Scrollbar.new(root, {
          orientation: "vertical",
        })
      );

      let data = instData;

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xrenderar = am5xy.AxisRendererX.new(root, {
        minGridDistance: 30,
      });
      xrenderar.grid.template.set("visible", false);


      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "trackName",

          renderer: xrenderar,

          tooltip: am5.Tooltip.new(root, {
            // themeTags: ["axis"],
            animationDuration: 200,
          }),
        })
      );

      xAxis.data.setAll(data);
      let yrenderar = am5xy.AxisRendererY.new(root, {});
      yrenderar.grid.template.set("visible", false);
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: yrenderar,
        })
      );

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

      let series0 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "trackName",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "total",
          categoryXField: "trackName",
          clustered: false,
          tooltip: am5.Tooltip.new(root, {
            labelText: `Total: {valueY} L`,
          }),
        })
      );

      series0.columns.template.setAll({
        width: am5.percent(50),
        tooltipY: 0,
        fill: am5.color("#CDD5E1"),
        stroke: "#CDD5E1",
      });

      series0.data.setAll(data);

      let series1 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "trackName",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "todayTotal",
          categoryXField: "trackName",
          clustered: false,
          tooltip: am5.Tooltip.new(root, {
            labelText: `Total Revenue : {valueY} L`,
          }),
        })
      );

      series1.columns.template.setAll({
        width: am5.percent(30),
        tooltipY: 0,
        fill: am5.color("#147AD6"),
        stroke: "#147AD6",
      });

      series1.data.setAll(data);

      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      series0.appear();
      series1.appear();
    }
  }, [instData]);

  let navigateData2 = instData?.map(item => ({ title: `View ${item?.trackName}`, dataQuery: `${item?.trackName}` }));

  const fetchInstData = async (queryName) => {
    isAccountAdmin() && navigate(`/account_admin_dashboard/pendingbusfees/${currentSession}/${queryName}`);
    isSuperAdmin() && navigate(`/admin_dashboard/pendingbusfees/${currentSession}/${queryName}`);
  };

  return (
    <>
      {instData === null ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%", height: "260px" }}
        >
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          <div id="busDiv"></div>

          <div
            className="d-flex justify-content-between"
            style={{ paddingInline: "60px" }}
          >
            {navigateData2?.map((item) => {
              return (
                <div className="" key={item?.dataQuery}>
                  <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={<Tooltip>{item?.title}</Tooltip>}
                  >
                    <button
                      className="btn ml-1 d-flex align-items-center justify-content-center"
                      type='button'
                      style={{ paddingInline: '6px' }}
                      onClick={() => fetchInstData(item?.dataQuery)}
                    >
                      <img src={eyeIcon} alt='...' style={{ marginBottom: '9.43px' }} />
                    </button>
                  </Whisper>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}

export default Dashboard;
