
import React,{useRef,useState} from 'react';
import {jsPDF} from "jspdf";
import QRCode from 'qrcode';
import * as XLSX from 'xlsx';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import BG_ITEG from '../images/ITEG_BG.jpg'
import BG_BEG from '../images/BEG_BG.jpg'
import BG_MEG from '../images/MEG_BG.jpg';
import BG_STAFF from '../images/STAFF_BG.jpg'
import BG_TEACHER from '../images/TEACHER_BG.jpg'
import BG_SUB_STAFF from '../images/SUB_STAFF.jpg'

import dropIcon from '../assets/drag-and-drop.png'
import {TeacherIDCardExcelDownload} from '../downloadExcelFille/TeacherIdCardExcel';
import {ShowError} from '../certificate/CertificateForm/showError/ShowError';
import {Typography} from '@mui/material';

export const TeachersCard = () => {
    const inputFile = useRef(null);
    const [error,setError] = useState();
    const [selectedFile,setSelectedFile] = useState(null);
    // const [selectedType,setSelectedType] = useState('certificate');

    const handleFileChange = async (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
        if(inputFile.current) {
            inputFile.current.value = '';
            inputFile.current.type = 'text';
            inputFile.current.type = 'file';
        }
    };


    const handleDrop = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const file = event.dataTransfer.files[0];
        setSelectedFile(file);

    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };


    const generateQR = async text => {
        try {
            const opts = {
                errorCorrectionLevel: 'H',
                type: 'image/png',
                quality: 1,
                margin: 0,
                color: {
                    dark: "#000000",
                }
            }
            return await QRCode.toDataURL(JSON.stringify(text),opts);
        } catch(err) {
            console.error(err)
            return null;
        }
    }

    const findKeyIgnoreCase = (obj,keyName) => {
        const lowerKeyName = keyName.toLowerCase();
        for(const key in obj) {
            if(key.toLowerCase() === lowerKeyName) {
                return key;
            }
        }
        return null;
    };

    const generatePDFs = async (excelData) => {
        const zip = new JSZip();
        for(let i = 0; i < excelData.length; i++) {
            const entry = excelData[i];
            const QR = await generateQR(entry.adhaar);
            if(QR) {
                if(entry.name && entry.category && entry.position && entry.email && entry.photoData && entry.mobileNumber && entry.adhaar) {
                    const pdf = await generatePDF(QR,entry);
                    zip.file(`${entry.name}.pdf`,pdf);
                } else {
                    console.log("entry not valid for generate pdf ",entry);
                }
            }
        }
        zip.generateAsync({type: 'blob'}).then((content) => {
            const date = new Date();
            saveAs(content,`teacher_cards-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.zip`);
        });
    };

    const generatePDF = async (QR,data) => {
        const doc = new jsPDF('p','mm',[84,54]);

        doc.setFont(undefined,'bold');
        doc.setFontSize(8);
        // doc.text(data.category, 45, 15);

        doc.setFontSize(8.5);

        switch(data.category?.toUpperCase()) {
            case 'ITEG':
                doc.addImage(BG_ITEG,'png',0,0,54,84);
                doc.setTextColor('5479bb')
                break;
            case 'BEG':
                doc.addImage(BG_BEG,'png',0,0,54,84);
                doc.setTextColor('134981')

                break;
            case 'MEG':

                doc.addImage(BG_MEG,'png',0,0,54,84);
                doc.setTextColor('f05a2b')
                break;
            case 'TEACHER':

                doc.addImage(BG_TEACHER,'png',0,0,54,84);
                doc.setTextColor('f16a25')
                break;
            case 'STAFF':

                doc.addImage(BG_STAFF,'png',0,0,54,84);
                doc.setTextColor('6a00e5')
                break;
            case 'SUB_STAFF':

                doc.addImage(BG_SUB_STAFF,'png',0,0,54,84);
                doc.setTextColor('c18a0e')
                break;

            default:

                doc.setTextColor('EC9500')
                break;
        }

        var width = doc.internal.pageSize.getWidth()
        doc.text(data.name,width / 2,46.5,{align: 'center'})

        doc.setFontSize(7);
        doc.setTextColor('black')

        doc.text(data.position,width / 2,50.5,{align: 'center'})


        doc.setFont(undefined,'normal');

        if(data.category === 'STAFF' || data.category === 'TEACHER') {

            doc.text(data.email,17,59.2);
            doc.text(`+91 ${data.mobileNumber}`,17,63.2);

        } else if(data.category === 'SUB_STAFF') {

            doc.text(data.email,17,58.3);
            doc.text(`+91 ${data.mobileNumber}`,17,62.3);

        } else {

            doc.text(data.email,17,58.4);
            doc.text(`+91 ${data.mobileNumber}`,17,62.4);

        }


        if(data.category === 'SUB_STAFF') {
            doc.addImage(QR,'png',36.5,64.2,13,13);

        } else {

            doc.addImage(QR,'png',37.5,66.1,13,13);

        }

        if(data.category === 'STAFF' || data.category === 'TEACHER') {

            doc.addImage(data.photoData,'jpeg',16.5,16.5,20.8,24.5);

        } else if(data.category === 'SUB_STAFF') {

            doc.addImage(data.photoData,'jpeg',16.5,16.5,20.8,24.5);

        } else {

            doc.addImage(data.photoData,'jpeg',16.5,14.5,20.8,24.5);

        }

        return doc.output('blob');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!selectedFile) {
            alert("Please upload an Excel file");
            return;
        }


        const reader = new FileReader();
        reader.onload = async function(e) {
            const binaryString = e.target.result;
            const workbook = XLSX.read(binaryString,{type: 'binary'});
            const sheetName = workbook.SheetNames[0]; // Assuming the data is in the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const excelData = XLSX.utils.sheet_to_json(worksheet);
            if(!excelData[0][findKeyIgnoreCase(excelData[0],"Name")] || !excelData[0][findKeyIgnoreCase(excelData[0],"email")] || !excelData[0][findKeyIgnoreCase(excelData[0],"Category")] || !excelData[0][findKeyIgnoreCase(excelData[0],"photoData")] || !excelData[0][findKeyIgnoreCase(excelData[0],"mobileNumber")] || !excelData[0][findKeyIgnoreCase(excelData[0],"Adhaar")] || !excelData[0][findKeyIgnoreCase(excelData[0],"Position")]) {
                setError("File must Contain Name of Columns Name , Email , Category  , PhotoData , MobileNumber , Adhaar , Position     Note:- if you have column please check  spelling mistake  and don't give blank space between rows");
                return;
            }
            // Map column names to expected keys
            const mappedData = excelData.map((entry) => {
                return {
                    name: entry[findKeyIgnoreCase(entry,'Name')],
                    email: entry[findKeyIgnoreCase(entry,'Email')],
                    category: entry[findKeyIgnoreCase(entry,'Category')]?.toUpperCase(),
                    // category: entry[findKeyIgnoreCase(entry,'Session')],
                    // branch: entry[findKeyIgnoreCase(entry,'Branch')],
                    photoData: entry[findKeyIgnoreCase(entry,'PhotoData')],
                    mobileNumber: entry[findKeyIgnoreCase(entry,'MobileNumber')],
                    adhaar: entry[findKeyIgnoreCase(entry,'Adhaar')],
                    position: entry[findKeyIgnoreCase(entry,'Position')] ?? 'teacher'
                };
            });

            await generatePDFs(mappedData);
        };
        reader.readAsBinaryString(selectedFile);


    }
    const handleSampleExcel = () => {
        TeacherIDCardExcelDownload();
    };

    return (
        <div
            className="container-form me-3 d-flex flex-column justify-content-center align-items-center bg-transparent"
        >
            <div className='  shadow-sm px-3 rounded-3 certificate-container mt-3 mt-lg-0 bg-white' style={{minWidth: '500px',minHeight: "90%"}}>
                <div> <h5 className=' text-center my-3'> Teacher Id Card</h5></div>

                <div className="file-field-container my-3 d-flex flex-column justify-content-center align-items-center  my-1 my-md-2 w-auto text-center rounded-3  py-1 position-relative  form-control "
                onDrop={handleDrop}
                style={{height: "40%"}}
                onDragOver={handleDragOver}
            >
                <img src={dropIcon} style={{width: "100px"}} height={"50%"} alt="" className=' w-25 mt-md-4' />

                <label htmlFor="files" className="cursor-pointer d-flex flex-column  justify-content-end  align-items-center  w-100 h-100">
                    <span>  Upload student name excel list </span>
                    <span className=' fw-bold fs-6 '>or drop it here</span>
                </label>

                <input
                    id="files"
                    className='file-field '
                    style={{visibility: 'hidden',width: '1px',height: '1px'}}
                    name="excelFile"
                    ref={inputFile}
                    accept=".xlsx, .xls, .xlsm, .xlsb, .csv, .xlam, .xltx, .xltm"
                    onChange={handleFileChange}
                    type="file"
                />
            </div>
            <div className="my-0 d-flex justify-content-center">
                {selectedFile ? (
                    <div style={{display: 'flex',alignItems: 'center'}}>
                        <span>{selectedFile.name}</span>
                        <button
                            type="button"
                            onClick={handleRemoveFile}
                            className="fw-bold cursor-pointer ms-2 px-1 border-0 text-danger rounded-circle"
                            title="remove file"
                        >
                            &#x2715;
                        </button>
                    </div>
                ) : null}
            </div>
            <div className=' d-flex justify-content-between'>
                <button className='btn btn-outline-dark  d-block my-3' onClick={handleSampleExcel}> Sample Excel</button>
                <button className='btn btn-outline-dark active d-block  my-3' onClick={handleSubmit}> Submit</button>
            </div>
            {error?.length ? <ShowError type={"Invalid File Data "} error={error} modalShow={error.length} onClick={() => {handleRemoveFile(); setError("");}} /> : null}
            </div>
        </div>
    );
};
