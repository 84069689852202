// import React,{useState} from 'react';

// import {jsPDF} from "jspdf";
// import QRCode from 'qrcode'
// import NumberFormat from 'react-number-format';
// import BG_ITEG from './images/ITEG_BG.jpg'
// import BG_BEG from './images/BEG_BG.jpg'
// import BG_MEG from './images/MEG_BG.jpg';
// import BG_STAFF from './images/STAFF_BG.jpg'
// import BG_TEACHER from './images/TEACHER_BG.jpg'
// import BG_SUB_STAFF from './images/SUB_STAFF.jpg'
// // import BG_TEST from './images/TEST.jpg'
// import {capitalizeFirstLetter} from './utils/capitalizeFirstLetter.js'


// const StaffCards = () => {

//     const [data,setData] = useState({'category': 'ITEG','position': 'Teacher'});
//     const [photoData,setphoto] = useState()


//     const handleData = async (e) => {
//         const name = e.target.name;
//         let value = e.target.value;

//         // Upper Case the given fields
//         if(name === 'name' || name === 'position') {

//             value = await capitalizeFirstLetter(value);
//         }


//         // console.log(name, value);
//         setData((prevalue) => {
//             return (
//                 {
//                     ...prevalue,
//                     [name]: value
//                 })
//         })
//     }

//     // convert image file to base 64 url
//     const handlePhoto = (e) => {

//         // console.log(e.target.files[0])
//         var reader = new FileReader();
//         reader.readAsDataURL(e.target.files[0]);
//         reader.onload = async function() {
//             setphoto(reader.result)//base64encoded string
//         };

//     }






//     const generateQR = async text => {
//         try {
//             // hide '-' in given string  of aadhar number
//             // exe - 0123-4567-8900  ->  012345678900
//             text = text.split('-').join('');
//             // console.log(text)
//             var opts = {
//                 errorCorrectionLevel: 'H',
//                 type: 'image/png',
//                 quality: 1,
//                 margin: 0,
//                 color: {
//                     dark: "#000000",
//                     // light:"#ffffff00"
//                 }
//             }
//             return (await QRCode.toDataURL(text,opts))
//         } catch(err) {
//             console.error(err)
//         }
//     }



//     async function print(e) {
//         e.preventDefault();
//         // console.log(data);
//         // console.log(photoData)

//         // generate qr code of given aadhar number
//         const QR = await generateQR(data.adhaar)
//         // console.log(QR)

//         const doc = new jsPDF('p','mm',[84,54]);

//         switch(data.category) {
//             case 'ITEG':

//                 doc.addImage(BG_ITEG,'png',0,0,54,84);
//                 break;
//             case 'BEG':

//                 doc.addImage(BG_BEG,'png',0,0,54,84);
//                 break;
//             case 'MEG':

//                 doc.addImage(BG_MEG,'png',0,0,54,84);
//                 break;
//             case 'STAFF':

//                 doc.addImage(BG_STAFF,'png',0,0,54,84);
//                 break;
//             case 'SUB_STAFF':

//                 doc.addImage(BG_SUB_STAFF,'png',0,0,54,84);
//                 break;
//             case 'TEACHER':

//                 doc.addImage(BG_TEACHER,'png',0,0,54,84);
//                 break;

//             default:
//                 break;
//         }

//         // doc.addImage(BG_ITEG, 'png', 0, 0, 54, 84);


//         doc.setFont(undefined,'bold');
//         doc.setFontSize(8);
//         // doc.text(data.category, 45, 15);

//         doc.setFontSize(8.5);

//         switch(data.category) {
//             case 'ITEG':

//                 doc.setTextColor('5479bb')
//                 break;
//             case 'BEG':
//                 doc.setTextColor('134981')

//                 break;
//             case 'MEG':

//                 doc.setTextColor('f05a2b')
//                 break;
//             case 'TEACHER':

//                 doc.setTextColor('f16a25')
//                 break;
//             case 'STAFF':

//                 doc.setTextColor('6a00e5')
//                 break;
//             case 'SUB_STAFF':

//                 doc.setTextColor('c18a0e')
//                 break;

//             default:

//                 doc.setTextColor('EC9500')
//                 break;
//         }

//         var width = doc.internal.pageSize.getWidth()
//         doc.text(data.name,width / 2,46.5,{align: 'center'})

//         doc.setFontSize(7);
//         doc.setTextColor('black')

//         doc.text(data.position,width / 2,50.5,{align: 'center'})


//         doc.setFont(undefined,'normal');

//         if(data.category === 'STAFF' || data.category === 'TEACHER') {

//             doc.text(data.email,17,59.2);
//             doc.text(`+91 ${data.mobileNumber}`,17,63.2);

//         } else if(data.category === 'SUB_STAFF') {

//             doc.text(data.email,17,58.3);
//             doc.text(`+91 ${data.mobileNumber}`,17,62.3);

//         } else {

//             doc.text(data.email,17,58.4);
//             doc.text(`+91 ${data.mobileNumber}`,17,62.4);

//         }


//         if(data.category === 'SUB_STAFF') {


//             doc.addImage(QR,'png',36.5,64.2,13,13);

//         } else {

//             doc.addImage(QR,'png',37.5,66.1,13,13);

//         }




//         if(data.category === 'STAFF' || data.category === 'TEACHER') {

//             doc.addImage(photoData,'jpeg',16.5,16.5,20.8,24.5);

//         } else if(data.category === 'SUB_STAFF') {

//             doc.addImage(photoData,'jpeg',16.5,16.5,20.8,24.5);

//         } else {

//             doc.addImage(photoData,'jpeg',16.5,14.5,20.8,24.5);

//         }

//         doc.save(data.name + ".pdf");
//         // doc.autoPrint();
//         // doc.output('dataurlnewwindow');





//     }


//     return (
//         <div className="mx-auto w-25 mt-5">
//             <form onSubmit={print}>

//                 <div className="mb-3">
//                     <label htmlFor="exampleFormControlInput1" className="addStdLable">Category</label>
//                     {/* <input required onChange={handleData} type="text" className="form-control" name='category' defaultValue="20-2021" id="exampleFormControlInput1" placeholder="category" /> */}
//                     <select className="form-select" name='category' required onChange={handleData} aria-label="Default select example">
//                         {/* <option value="2018-19">2018-19</option> */}
//                         <option value='ITEG' selected  >ITEG</option>
//                         <option Value="BEG" >BEG</option>
//                         <option value="MEG">MEG</option>
//                         <option value="TEACHER">TEACHER</option>
//                         <option value="STAFF">STAFF</option>
//                         <option value="SUB_STAFF">SUB STAFF</option>


//                     </select>
//                 </div>

//                 <div className="mb-3">
//                     <label htmlFor="exampleFormControlInput1" className="addStdLable">Name</label>
//                     <input required onChange={handleData} type="text" className="form-control text-capitalize " name='name' id="exampleFormControlInput1" placeholder="name" />
//                 </div>
//                 <div className="mb-3">
//                     <label htmlFor="exampleFormControlInput1" className="addStdLable">Email</label>
//                     <input required onChange={handleData} type="email" className="form-control " name='email' id="exampleFormControlInput1" placeholder="Email    " />
//                 </div>
//                 <div className="mb-3">
//                     <label htmlFor="exampleFormControlInput1" className="addStdLable">Position</label>
//                     <input required onChange={handleData} type="text"  className={formik.touched.position ? `form-control ${formik.errors.position ? "invalid" : ""}` : 'form-control'} name='position' defaultValue="Teacher" id="exampleFormControlInput1" placeholder="branch" />
//                 </div>
//                 <div className="mb-3">
//                     <label htmlFor="exampleFormControlInput1" className="addStdLable">Mobile</label>
//                     <input format="##########" pattern="[0-9]*" maxLength='10' minLength='10' required onChange={handleData} type="text" className="form-control" name='mobileNumber' id="exampleFormControlInput1" placeholder="mobile" />
//                 </div>
//                 <div className="mb-3">
//                     <label htmlFor="exampleFormControlInput1" className="addStdLable">adhaar</label>
//                     <NumberFormat format="####-####-####" pattern="[0-9\-]+" required onChange={handleData} type="tel" className="form-control" name='adhaar' id="exampleFormControlInput1" placeholder="adhaar no." />
//                 </div>
//                 <div className="mb-3">
//                     <label htmlFor="formFile" className="addStdLable">Photo</label>
//                     <input required className="form-control" name='photo' onChange={handlePhoto} accept='.jpeg , .jpg' type="file" id="formFile" />
//                 </div>
//                 <button type="submit" className="btn btn-dark"> Print Card </button>
//             </form>
//         </div>
//     );
// }

// export default StaffCards;

import React,{useState} from 'react';

import {jsPDF} from "jspdf";
import QRCode from 'qrcode'
import NumberFormat from 'react-number-format';
import BG_ITEG from './images/ITEG_BG.jpg'
import BG_BEG from './images/BEG_BG.jpg'
import BG_MEG from './images/MEG_BG.jpg';
import BG_STAFF from './images/STAFF_BG.jpg'
import BG_TEACHER from './images/TEACHER_BG.jpg'
import BG_SUB_STAFF from './images/SUB_STAFF.jpg'
import * as Yup from 'yup';
// import BG_TEST from './images/TEST.jpg'
import {capitalizeFirstLetter} from './utils/capitalizeFirstLetter.js'
import {useFormik} from 'formik';


const StaffCards = () => {
    const initialValues = {
        teacherName: '',
        mobileNumber: '',
        adhaar: '',
        photo: null,
        email: '',
        category: 'ITEG',
        position: "Teacher"
    };

    const validationSchema = Yup.object().shape({
        teacherName: Yup.string().required('Student name is required'),
        mobileNumber: Yup.string()
            .matches(/^[0-9]{10}$/,'Mobile number must be 10 digits')
            .required('Mobile number is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        adhaar: Yup.string()
            .matches(/^\d{4}-\d{4}-\d{4}$/,'Adhaar must be in format XXXX-XXXX-XXXX')
            .required('Adhaar number is required'),
        photo: Yup.mixed().required('Photo is required'),
        category: Yup.string().required('category is required'),
        position: Yup.string().required('Position is required'),

    });

    const [photoData,setphoto] = useState()

    const handlePhoto = (e) => {

        // console.log(e.target.files[0])
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = async function() {
            setphoto(reader.result)//base64encoded string
        };

    }
    const generateQR = async text => {
        try {
            // hide '-' in given string  of aadhar number 
            // exe - 0123-4567-8900  ->  012345678900
            text = text.split('-').join('');
            // console.log(text)
            var opts = {
                errorCorrectionLevel: 'H',
                type: 'image/png',
                quality: 1,
                margin: 0,
                color: {
                    dark: "#000000",
                    // light:"#ffffff00"
                }
            }
            return (await QRCode.toDataURL(text,opts))
        } catch(err) {
            console.error(err)
        }
    }
    async function print(data) {

        const QR = await generateQR(data.adhaar)

        const doc = new jsPDF('p','mm',[84,54]);

        switch(data.category) {
            case 'ITEG':

                doc.addImage(BG_ITEG,'png',0,0,54,84);
                break;
            case 'BEG':

                doc.addImage(BG_BEG,'png',0,0,54,84);
                break;
            case 'MEG':

                doc.addImage(BG_MEG,'png',0,0,54,84);
                break;
            case 'STAFF':

                doc.addImage(BG_STAFF,'png',0,0,54,84);
                break;
            case 'SUB_STAFF':

                doc.addImage(BG_SUB_STAFF,'png',0,0,54,84);
                break;
            case 'TEACHER':

                doc.addImage(BG_TEACHER,'png',0,0,54,84);
                break;

            default:
                break;
        }

        doc.setFont(undefined,'bold');
        doc.setFontSize(8);

        doc.setFontSize(8.5);

        switch(data.category) {
            case 'ITEG':

                doc.setTextColor('5479bb')
                break;
            case 'BEG':
                doc.setTextColor('134981')

                break;
            case 'MEG':

                doc.setTextColor('f05a2b')
                break;
            case 'TEACHER':

                doc.setTextColor('f16a25')
                break;
            case 'STAFF':

                doc.setTextColor('6a00e5')
                break;
            case 'SUB_STAFF':

                doc.setTextColor('c18a0e')
                break;

            default:

                doc.setTextColor('EC9500')
                break;
        }

        var width = doc.internal.pageSize.getWidth()
        doc.text(data.teacherName,width / 2,46.5,{align: 'center'})

        doc.setFontSize(7);
        doc.setTextColor('black')

        doc.text(data.position,width / 2,50.5,{align: 'center'})


        doc.setFont(undefined,'normal');

        if(data.category === 'STAFF' || data.category === 'TEACHER') {

            doc.text(data.email,17,59.2);
            doc.text(`+91 ${data.mobileNumber}`,17,63.2);

        } else if(data.category === 'SUB_STAFF') {

            doc.text(data.email,17,58.3);
            doc.text(`+91 ${data.mobileNumber}`,17,62.3);

        } else {

            doc.text(data.email,17,58.4);
            doc.text(`+91 ${data.mobileNumber}`,17,62.4);

        }

        if(data.category === 'SUB_STAFF') {


            doc.addImage(QR,'png',36.5,64.2,13,13);

        } else {

            doc.addImage(QR,'png',37.5,66.1,13,13);

        }

        if(data.category === 'STAFF' || data.category === 'TEACHER') {

            doc.addImage(photoData,'jpeg',16.5,16.5,20.8,24.5);

        } else if(data.category === 'SUB_STAFF') {

            doc.addImage(photoData,'jpeg',16.5,16.5,20.8,24.5);

        } else {

            doc.addImage(photoData,'jpeg',16.5,14.5,20.8,24.5);

        }

        doc.save(data.teacherName + ".pdf");

    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            print({...values,teacherName: capitalizeFirstLetter(values.teacherName)});
        },
    });
    return (
        <div className=" w-100 p-0 m-0  d-flex justify-content-center align-items-center" style={{height: "calc(100vh - 150px)"}}>

            <form className='w-75  mx-auto py-5 bg-white rounded-2 shadow-sm' onSubmit={formik.handleSubmit}>
            <div className="row m-0 p-0 mb-3">
                <div className="col-5 offset-1">
                    <label htmlFor="exampleFormControlInput1" className="addStdLable">Teacher Name</label>
                    <input required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.teacherName}
                        type="text"
                        className={formik.touched.teacherName ? `form-control ${formik.errors.teacherName ? "invalid" : ""}` : 'form-control'}
                        name='teacherName'
                        id="exampleFormControlInput1"
                        placeholder="teacher name" />
                    {formik.touched.teacherName && formik.errors.teacherName ? (
                        <div className="text-danger fs-6">{formik.errors.teacherName}</div>
                    ) : null}
                </div>
                <div className="col-5 ">
                    <label htmlFor="exampleFormControlInput1" className="addStdLable">Category</label>
                    {/* <input required onChange={handleData} type="text" className="form-control" name='category' defaultValue="20-2021" id="exampleFormControlInput1" placeholder="category" /> */}
                    <select
                        // className={formik.touched.category ? `form-control ${formik.errors.category ? "invalid" : ""}` : 'form-control'} 
                        className={formik.touched.category ? `form-select ${formik.errors.category ? "invalid" : ""}` : 'form-select'}
                        name='category' required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.category}
                        aria-label="Default select example">
                        {/* <option value="2018-19">2018-19</option> */}
                        <option value='ITEG' selected  >ITEG</option>
                        <option Value="BEG" >BEG</option>
                        <option value="MEG">MEG</option>
                        <option value="TEACHER">TEACHER</option>
                        <option value="STAFF">STAFF</option>
                        <option value="SUB_STAFF">SUB STAFF</option>
                    </select>
                    {formik.touched.category && formik.errors.category ? (
                        <div className="text-danger fs-6">{formik.errors.category}</div>
                    ) : null}
                </div>

            </div>
            <div className="row m-0 p-0 mb-3">
                <div className="col-5 offset-1">
                    <label htmlFor="exampleFormControlInput1" className="addStdLable">Email</label>
                    <input required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        type="email"
                        className={formik.touched.email ? `form-control ${formik.errors.email ? "invalid" : ""}` : 'form-control'}
                        name='email'
                        id="exampleFormControlInput1"
                        placeholder="Email    " />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger fs-6">{formik.errors.email}</div>
                    ) : null}
                </div>
                <div className="col-5 ">
                    <label htmlFor="exampleFormControlInput1" className="addStdLable">Position</label>
                    <input required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.position}
                        type="text"
                        className={formik.touched.position ? `form-control ${formik.errors.position ? "invalid" : ""}` : 'form-control'}
                        name='position'
                        // defaultValue="Teacher"
                        id="exampleFormControlInput1"
                        placeholder="Position" />
                    {formik.touched.position && formik.errors.position ? (
                        <div className="text-danger fs-6">{formik.errors.position}</div>
                    ) : null}
                </div>
            </div>
            <div className="row m-0 p-0 mb-3">
                <div className="col-5 offset-1">
                    <label htmlFor="adhaar" className="addStdLable">
                        Adhaar Number*
                    </label>
                    <NumberFormat
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.adhaar}
                        name="adhaar"
                        className={formik.touched.adhaar ? `form-control ${formik.errors.adhaar ? "invalid" : ""}` : 'form-control'}
                        // className={formik.touched.adhaar && formik.errors.adhaar ? 'form-control is-invalid' : 'form-control'}
                        format="####-####-####"
                        pattern="[0-9\-]+"
                        placeholder="Enter adhaar number"
                    />
                    {formik.touched.adhaar && formik.errors.adhaar ? (
                        <div className="text-danger fs-6">{formik.errors.adhaar}</div>
                    ) : null}
                </div>
                <div className="col-5 ">
                    <label htmlFor="mobileNumber" className="addStdLable">
                        Mobile Number*
                    </label>
                    <NumberFormat
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mobileNumber}
                        name="mobileNumber"
                        className={formik.touched.mobileNumber ? `form-control ${formik.errors.mobileNumber ? "invalid" : ""}` : 'form-control'}

                        // className={formik.touched.mobileNumber && formik.errors.mobileNumber ? 'form-control is-invalid' : 'form-control'}
                        format="##########"
                        pattern="[0-9]*"
                        // maxLength="10"
                        // minLength="10"
                        placeholder="Enter mobile number"
                    />
                    {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                        <div className="text-danger fs-6">{formik.errors.mobileNumber}</div>
                    ) : null}
                </div>
            </div>
            <div className="row m-0 p-0 mb-3">

                <div className="col-10 offset-1 ">
                    <label htmlFor="photo" className="addStdLable">
                        Photo*
                    </label>
                    <input
                        onChange={(e) => {formik.handleChange(e); handlePhoto(e)}}
                        onBlur={formik.handleBlur}
                        value={formik.values.photo}
                        name="photo"
                        accept=".jpeg,.jpg"
                        type="file"
                        className={formik.touched.photo ? `form-control ${formik.errors.photo ? "invalid" : ""}` : 'form-control'}
                    // className={formik.touched.photo && formik.errors.photo ? 'form-control is-invalid' : 'form-control'}
                    />
                    {formik.touched.photo && formik.errors.photo ? (
                        <div className="text-danger fs-6">{formik.errors.photo}</div>
                    ) : null}
                </div>
            </div>
            <div className=' mt-5 d-flex justify-content-center'>
                <button type="submit" className="btn btn-dark">
                    Print ID Card
                </button>
            </div>
            </form>
        </div>
    );
}

export default StaffCards;

