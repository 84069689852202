import {
  CButton,
  CModal,
  CModalBody,
  // CModalHeader,
  // CModalTitle,
  // CModalTitle,
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo from "../assests/image/User.svg";
import crossButton from "../assests/image/crossButton.svg";
import "./styles/createAdmin.css";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../assests/common/LoaderButton";
import AllUrl from "../../redux/constants/url";
import axios from 'axios'
import Swal from "sweetalert2";
import { toast } from "react-toastify";

function AddChargableMonthPopup() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
const [loading, setLoading] = useState(false);
  // // console.log(adminData);

  const [visible, setVisible] = useState(false);
  const monthMap = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  




  const validationSchema = Yup.object({
    month: Yup.string().required("Please fill the field above")
  });
  const formik = useFormik({
    initialValues: {
      month: ""
    },
    validationSchema,

    onSubmit: async (values) => {
      setLoading(true);
      var data = {
        month: monthMap[parseInt(values.month.split('-')[1])],
        year: values.month.split('-')[0]
      }
      // console.log(data);
      var config = {
        method: "get",
        url: AllUrl.addChargeableMonth+`/${data.month}/${data.year}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      };
      try{
        var result = await axios(config);
        // if (result) setLoading(false)

        if (result.status === 200) {
          toast.success("Bus Pass Month Added Succefully",{
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
        }
    } catch (error) {
        toast.warn(error.response.data.error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
       setLoading(false);
      }

    },
  });
  
  return (
    <div>
      <CButton
        style={{
          backgroundColor: "#F7922A",
          color: "white",
          outline: "none",
          border: "none",
          fontWeight: "bold",
          marginLeft: "15px",
        }}
        onClick={() => setVisible(!visible)}
      >
        Create Bus Pass Month <i className="fas fa-plus"></i>
      </CButton>
      <CModal

        alignment="center"
        visible={visible}
        onClose={() => {
          formik.handleReset()
          setVisible(false);
        }}
      >
        <CModalBody>
          <div className="first_div createAdmin">
            <div className="second_div " style={{ marginTop: "0px" }}>
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <img onClick={() => setVisible(!visible)}
                    style={{ height: "22px", width: "22px", marginLeft: '125%', marginTop: "-10px", cursor: "pointer" }} src={crossButton} alt="close" className="logo_img" />
                  <h4 className=" text-aligns-center createAdminhead" style={{ color: '#5A607F', fontWeight: 'bold' }}>
                   Create Bus Pass Chargeable Month
                  </h4>
                  <img src={logo} alt="logo ssism" className="logo_img mb-3" />{" "}
                </div>
                <div>
                <label htmlFor="gmail" className="labels d-inline-block mx-2">
                    Month Year
                  </label>
                  <input
                    value={formik.values.month}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="inputs w-auto ml-2"
                    // aria-label="email"
                    name="month"
                    type="month"
                    id="month"
                    placeholder="Enter month "
                  />
                  {formik.errors.month && formik.touched.month ? (
                    <div className="text-danger fs-6">
                      {formik.errors.month}
                    </div>
                  ) : (
                    ""
                  )}
        

                </div>
                <button
                  disabled={loading}
                  style={{ marginTop: '35px' }}
                  className=" submit_btn w-100  btn-md text-light font-weight-bold"
                  type="submit"
                >
                  {loading ? <LoaderButton /> : "Add Month"}
                </button>
              </form>
            </div>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
}

//Connecting the component to our store
export default AddChargableMonthPopup;
