
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

export const StudentIdCardExcelDownload = () => {
    const studentData = [
        {
            name: 'John Doe',
            fatherName: 'Michael Doe',
            group: 'Iteg',
            session: '2023-24',
            branch: 'Bca',
            mobileNumber: '1234567890',
            adhaar: '1234-5678-9012',
            photoData: 'base64_encoded_image_data'
        },
        {
            name: 'Jane Smith',
            fatherName: 'David Smith',
            group: 'beg',
            session: '2023-24',
            branch: 'Bba',
            mobileNumber: '9876543210',
            adhaar: '9876-5432-1098',
            photoData: 'base64_encoded_image_data'
        },{
            name: 'Janie',
            fatherName: 'Dav',
            group: 'meg',
            session: '2023-24',
            branch: 'Bba',
            mobileNumber: '9876543210',
            adhaar: '9876-5892-1098',
            photoData: 'base64_encoded_image_data'
        }
    ];


    const worksheet = XLSX.utils.json_to_sheet(studentData,{
        header: ['name','fatherName','group','session','branch','mobileNumber','adhaar','photoData'],
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook,worksheet,'Student ID Cards');
    const excelBuffer = XLSX.write(workbook,{
        bookType: 'xlsx',
        type: 'array',
    });
    const fileName = 'student_id_card-sample-excel.xlsx';
    const blob = new Blob([excelBuffer],{type: 'application/octet-stream'});
    FileSaver.saveAs(blob,fileName);

};

