import React from "react";
import {Routes,Route,Navigate} from "react-router-dom";
import {useAnimate} from "react-simple-animate";

import "../components/styles/adminDashboard.css";

import Dashboard from "../components/dashboard/Dashboard";
import Navbar from "../components/Navbar";
// import FeesStructureTable from "../components/FeesStuctureTable";
// import AdminTable from "../components/AdminTable";
import Sidebar from "../components/Sidebar";
import StudentTable from "../components/StudentsTable";
import AddNewStudent from "../components/AddNewStudent";
// import AddStudent from "../components/AddStudent";
import {ToastContainer} from "react-toastify";
import SidebarLinks from "./SidebarLinks";
import HeaderLink from "./HeaderLink";
import StudentTableFirst from './../components/student/StudentTableFirst';
import SelfAppliedStudentTable from "../components/SelfAppliedStudentTable";
import StudentsTableAppliedAndSelfApplied from "../components/StudentsTableAppliedAndSelfApplied";

import UpdateSelfAppliedStudentDetail from "../components/UpdateSelfAppliedStudentDetail";
import Attendance from "../components/Attendance";
import ActivityTable from "../components/ActivityTable";
import StudentActivities from "../components/accounts/StudentActivities";
import {CertificatesAndCardRoutes} from "../components/cards-certificate/CertificatesAndCardRoutes";
import {StudentCardAndCertificateAsZip} from "../components/cards-certificate/certificate_card_zip/StudentCardAndCertificateAsZip";
import {Certificate} from "../components/cards-certificate/certificate/CertificateForm/Certificate";
import BegMegItegIdcard from "../components/cards-certificate/Beg_Meg_Iteg_id_card";
import StaffCards from "../components/cards-certificate/StaffCards";
import {TeachersCard} from "../components/cards-certificate/teacher-card/TeachersCard";
import RegistrationsDashboard from "../components/dashboard/registrations";
import { BulkGenerateCardAndCertificateAsZip } from "../components/cards-certificate/certificate_card_zip/BulkGenerateCardAndCertificateAsZip";


function AdminDashboard() {

  const {play,style,isPlaying} = useAnimate({
    start: {
      width: "281px",
      minWidth: "280px",
    },
    end: {
      width: "95px",
      minWidth: "95px",
    },
  });

  return (
    <div className="main_container_dashboard">
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="side_bar_content" style={style}>
        <Sidebar play={play} isPlaying={isPlaying} Options={SidebarLinks} />
      </div>

      <div className="header_table">
        <div className="header_dashboard">
          <Navbar Options={HeaderLink} />
        </div>
        <div className="table_dashboard">
          <Routes>
            <Route path="" element={<Dashboard />} />
            <Route path="registrations-dashboard" element={<RegistrationsDashboard />} />
            <Route path="studenttable" element={<StudentTable />} />
            <Route path="selfappliedstudent" element={<SelfAppliedStudentTable />} />
            <Route path="selfappliedandapliedstudent/:trackName" element={<StudentsTableAppliedAndSelfApplied />} />
            <Route path="students" element={<StudentTableFirst />} />
            <Route path="attendance/*" element={<Attendance />} />
            <Route path="addnewstudent" element={<AddNewStudent />} />
            <Route path="updateselfappliedstudentdetail" element={<UpdateSelfAppliedStudentDetail />} />
            <Route path="activitytable" element={<ActivityTable />} />
            <Route path="activity" element={<StudentActivities />} />
            <Route path="cards-certificate" element={<CertificatesAndCardRoutes />} >
              <Route exact path="teacher" element={<StaffCards />} >  </Route>
              <Route exact path="" element={<BegMegItegIdcard />} >  </Route>
              <Route exact path="certificate" element={<Certificate />}>  </Route>
              <Route exact path="student-id-card-certificate-zip" element={<BulkGenerateCardAndCertificateAsZip />}  > </Route>
              {/* <Route exact path="student-id-card-certificate-zip" element={<StudentCardAndCertificateAsZip />}  > </Route> */}
              <Route exact path="teacher-card-zip" element={<TeachersCard />}>  </Route>
            </Route>
            <Route path="*" element={<Navigate replace to="/login" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
