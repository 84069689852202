import {
    UPDATE_ACTIVITY_STATUS_FAIL,
    UPDATE_ACTIVITY_STATUS_REQUEST,
    UPDATE_ACTIVITY_STATUS_SUCCESS,
  } from "../../constants/actions";
  
  const initialState = {
    loading: false,
    newAdmin: false,
    error: false,
    notifyData: {}
  };
  
  const updateActivityStatusReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_ACTIVITY_STATUS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case UPDATE_ACTIVITY_STATUS_SUCCESS:
        return {
          loading: false,
          newAdmin: true,
          error: false,
          notifyData: action.payload
        };
      case UPDATE_ACTIVITY_STATUS_FAIL:
        return {
          loading: false,
          newAdmin: false,
          error: true,
        };
      default:
        return state;
    }
  };
  
  export default updateActivityStatusReducer;
  