/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import avatar8 from "../assests/image/david.png";
import "./styles/HeaderDropdown.css";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../redux/actionDispatcher/auth/authDispatcher";
import { connect } from "react-redux";
import { Tooltip, Whisper } from "rsuite";
import SettingsModalFile from "./SettingsModalFile";
import { CButton } from "@coreui/react";
import { isAccountAdmin } from "../../helpers/AccountAdmin";
import { isSuperAdmin } from "../../helpers/SuperAdmin";
import AllUrl from "../../redux/constants/url";
import axios from "axios";
import { isStudentAdmin } from "../../helpers/StudentAdmin";
import { updateActivityStatus } from "../../redux/actionDispatcher/superAdmin/updateActivityStatusDispatcher";

const AppHeaderDropdown = ({ logout, updateActivityStatus,notifyData, activityStatus }) => {
  const token = localStorage.getItem("token");
  const [show, setShow2] = useState(false)
  const navigate = useNavigate();
  const myname = localStorage.getItem("user");
  const userEmail = localStorage.getItem("email");
  const currentLocation = useLocation().pathname;

  const logoutfunction = () => {
    logout();
    localStorage.clear();
    navigate("/login");
  };
  const handleActivityNavigation = async () => {
    isAccountAdmin() && navigate(`/account_admin_dashboard/activitytable`);
    isSuperAdmin() && navigate(`/admin_dashboard/activitytable`);
    isStudentAdmin() && navigate(`/student_admin_dashboard/activitytable`)
    localStorage.setItem('onlyMineReports', true);
  };

  React.useEffect(async () => {
    var config = {
      method: "get",
      url: AllUrl?.getNotificationCount,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    updateActivityStatus(config, navigate);
  }, []);

  // console.log('activityStatus', notifyData)

  return (
    <div className='d-flex align-items-center'>
      {(
        currentLocation === '/admin_dashboard' ||
        currentLocation === '/admin_dashboard/activitytable' ||
        currentLocation === '/account_admin_dashboard' ||
        currentLocation === '/account_admin_dashboard/activitytable' ||
        currentLocation === '/student_admin_dashboard' ||
        currentLocation === '/student_admin_dashboard/activitytable'
      ) ?
        (
          <Whisper placement="bottom" controlId="control-id-hover" trigger="hover" speaker={
        <Tooltip>
          View Notifications
        </Tooltip>
      }>
          <div>
            <CButton
              style={{
                backgroundColor: "#F7922A",
                color: "white",
                outline: "none",
                border: "none",
                fontWeight: "bold",
                marginLeft: "15px",
                height: '36px'
              }}
              onClick={handleActivityNavigation}><i className="fas fa-bell"></i>
              <span className='ms-2'>{notifyData?.notifyData?.assignedReportCount}</span>
            </CButton>
          </div>
          </Whisper>
        ) : ''
      }
    <div>
      <Whisper placement="bottom" controlId="control-id-hover" trigger="hover" speaker={
        <Tooltip>
          View Profile
        </Tooltip>
      }>
        <img
          onClick={() => setShow2(!show)}
          src={avatar8} alt=""
          style={{
            height: "42px",
            width: "42px",
            margin: "10px",
            cursor: "pointer"
          }}
        />
      </Whisper>
      {
        show ?
          <div style={{ position: 'fixed', marginLeft: '-270px', marginTop: '10px' }}>
            <div style={{ flexDirection: 'column', backgroundColor: '#7e84a3', minHeight: '369px', width: '294px', alignItems: 'center', paddingTop: '49px', display: 'flex', flex: 'basis', zIndex: '510' }}>
              <img
                alt=""
                src={avatar8}
                style={{
                  height: "82px",
                  width: "82px",
                }}
              />
              <p style={{
                textAlign: 'center',
                color: 'white',
                fontSize: '11px'
              }}>
                <span style={{
                  color: 'white',
                  fontSize: '13px',
                  fontWeight: 'bold'
                }}>{myname}</span>
                <br /> {userEmail}</p>
              <hr style={{
                color: 'white',
                width: '294px',
                height: '1px',
                opacity: '1'
              }} />
              <div style={{ marginTop: '-23px', cursor: 'pointer' }}>
                <span style={{
                  color: 'white',
                  fontWeight: '4px',
                  marginLeft: '8px'
                }}>
                  {/* Settings */}
                  <SettingsModalFile   setShow2={setShow2} />
                </span>
              </div>
              <hr style={{ color: 'white', width: '294px', height: '1px', opacity: '1' }} />
              <button
                style={{
                  marginTop: '10px',
                  outline: "1px solid white",
                  color: "white",
                  backgroundColor: "#7e84a3",
                  borderRadius: "4px",
                  border: "none",
                  height: "29px",
                  width: "110px",
                  fontSize: "13px"
                }}
                onClick={logoutfunction}
              >
                Log out
              </button>
            </div>
          </div>
          : ""
      }
      </div>
    </div >
  );
};

//Getting the state from the store
const mapStateToProps = (state) => {
  return {
    notifyData: state.activityStatus,
  };
};
//passing the userData in fetchUsers function and also dispatch method
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    updateActivityStatus: (config, navigate) => dispatch(updateActivityStatus(config, navigate)),
  };
};

//Connecting the component to our store
export default connect(mapStateToProps, mapDispatchToProps)(AppHeaderDropdown);
