import React from "react";
import "./paymentPage.css"
import { Link } from "react-router-dom";
import ssismLogo from "./Ssism_Logo.png"
const PaymentPage = () => {
  return (<>
 <div className="desktop-theme mobile-theme darker">
  <div id="container-paymentpage"> 
    <div id="container-desktop" className="container-desktop">
      <div className="container-content">
        <div className="content-box">
           <div className="content-scrollable-box" />  
          <div id="header-detail">
            <div id="header-log">
            <img alt="merchant-logo" src={ssismLogo }/>
            </div>
            <div id="logo-name"> Shri Sant Singaji Educational Society
            </div>
          </div>
          <div data-view-id={1} id="view-main" className="view-slider ">
            <div className="payment-title">
              <a slot="before" href="#" className="btn-back" type="button" /> 
              Payment Details
              <div className="underline-title" />
            </div> 
            <div id="section-form" className="webview-android">
              <form className="form-ui ">
                <div>
                  <div>
                    <div className="payment-field ">
                      <div className="payment-label">Amount
                      </div>
                      <div className="payment-field-content">
                        <div className="wrapper-field">
                          <span className="addon-field addon-field--before">
                            <span slot="addonBefore">
                              <b className="currency-symbol">₹</b>
                            </span>
                          </span>
                          <input className="el-field" type="number" id="field-amount" placeholder="Enter Amount" required />
                          <span className="addon-field addon-field--after"><span slot="addonAfter" style={{color: 'red', fontSize: 12}} /></span>
                        </div>
                        <div className="error-field" />
                        <div className="description-error" />
                      </div>
                    </div>
                  </div>  
                  <div className="payment-field">
                    <div className="payment-label">Name
                    </div>
                    <div className="payment-field-content">
                      <div className="wrapper-field"><span className="addon-field " />
                        <input className="el-field" name="name" placeholder="Enter Your Name" id="field-name" required type="text" />
                        <span className="addon-field " />
                      </div>
                      <div className="error-field" />
                      <div className="description-field" />
                    </div>
                  </div> 
                  <div className="payment-field">
                    <div className="payment-label">Phone
                    </div>
                    <div className="payment-field-content">
                      <div className="wrapper-field"><span className="addon-field " />
                        <input className="el-field" name="phone" placeholder="Enter Your Phone No" type="tel" required id="field-phone" minLength={8} />
                        <span className="addon-field " />
                      </div>
                      <div className="error-field" />
                      <div className="description-field" />
                    </div>
                  </div>
                  <div className="payment-field">
                    <div className="payment-label">Email
                    </div>
                    <div className="payment-field-content">
                      <div className="wrapper-field"><span className="addon-field " />
                        <input className="el-field" name="email" id="field-email" type="text" required placeholder="Enter Your Email" />
                        <span className="addon-field " />
                      </div>
                      <div className="error-field">
                      </div>
                      <div className="description-field">
                      </div>
                    </div>
                  </div>
                  <div className="payment-field">
                    <div className="payment-label">PAN Number
                    </div>
                    <div className="payment-field-content">
                      <div className="wrapper-field"><span className="addon-field " />
                        <input className="el-field" name="pan" id="field-pan" type="text" required placeholder="Enter Your PAN Number" />
                        <span className="addon-field " />
                      </div>
                      <div className="error-field">
                      </div>
                      <div className="description-field">
                      </div>
                    </div>
                  </div>
                  <div className="payment-field">
                    <div className="payment-label">Address
                    </div>
                    <div className="payment-field-content">
                      <div className="wrapper-field"><span className="addon-field " />
                        <input className="el-field" name="address" id="field-address" type="text" required placeholder="Enter Your Address" />
                        <span className="addon-field " />
                      </div>
                      <div className="error-field">
                      </div>
                      <div className="description-field">
                      </div>
                    </div>
                  </div>
                </div>
                <div slot="after">
                  <div id="footer-form" className>
                    <div className="footer-form-payment ">
                      < img id="logo-pay" alt="pay-methods"
                      src="https://cdn.razorpay.com/static/assets/pay_methods_branding.png"
                      width="180"/>
                      <button type="button" id="submit-pay-btn" data-toggle="modal" aria-labelledby="myModalPaymentLabel" data-target="#myModalPayment" className=" btn-custom-form ">Pay
                        <span style={{marginLeft: 4}} /></button>  
                    </div>
                  </div>
                </div>
              </form> 
            </div>
          </div>
          <div id="view-details" className="android-btn-webview">
            <div id="section-details">
              <div id="details-description">
                <div className="details-title-ssism">
                 Shri Sant Singaji Educational Society
                  <div className="underline-title" />
                </div>
                <div id="description-ssism" className="value-details line-text">
                  <div id="quill-description" className="container-ql snow-ql disabled-ql">
                    <div className="editor-ql" data-gramm="false" contentEditable="false">
                      <p><strong>Sant Singaji Institute of Science &amp; Management </strong></p>
                      <p><strong>NH-59A, Sandalpur, Dewas </strong></p>
                      <p><strong>Madhya Pradesh</strong></p>
                      <p><strong>455339 </strong></p>
                      <h2><br /></h2>  
                    </div>
                    <div className="clipboard-ql" contentEditable="true" tabIndex={-1} />
                  </div>
                </div>
              </div>
              <div id="share-details"><label>Share this on:</label> 
                <div className="icons-share"><a><span className="facebook-icon" /></a>
                  <a><span className="twitter-icon" /></a>
                  <a><span className="whatsapp-icon" /></a>
                </div>
              </div>
              <div id="details-support"><label>Contact Us:</label>
                <div className="sub-detail-support"><a href="mailto:fees@ssism.org?subject=Query for Payment Page Id: pl_CqGVpX5GIOXJEV" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z">
                      </path>
                      <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                    <div>fees@ssism.org</div>
                  </a></div>
                <div className="sub-detail-support"><a href="tel:+91 84358 04504"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z">
                      </path>
                    </svg>
                    <div>+91 84358 04504</div>
                  </a></div>
              </div> 
              <div id="terms-details-container">
                <label>Terms &amp; Conditions:</label>
                <div className="terms-details-description-value text-wrap">
                  <p className="terms-details-description-tnc">
                    You agree to share information entered on this page with Singaji Educational
                    Society (owner of this page), adhering to applicable laws.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="container-mobile" className="container-mobile">
      <div className="container-content-mobile">
        <div className="content-box-mobile">
          <div className="content-scrollable-box">
            <div id="header-detail-mobile">
              <div id="header-log">
               <img alt="merchant-logo" src="https://cdn.razorpay.com/logos/9DcsMHqf4sbY3K_large.png"/>  
               < img alt="merchant-logo" src="./Ssism_Logo.png"/>
                {'{'}/* </div> */{'}'}
              <div id="logo-name">Singaji Educational Society
              </div>
            </div>
            <div id="view-details" className="android-btn-webview">
              <div id="section-details">
                <div id="details-description">
                  <div className="details-title-ssism">
                    Sant Singaji Educational Society
                    <div className="underline-title" />
                  </div>
                  <div id="description-ssism" className="value-details line-text">
                    <div id="quill-description" className="container-ql snow-ql disabled-ql">
                      <div className="editor-ql" data-gramm="false" contentEditable="false">
                        <p><strong>Sant Singaji Institute of Science &amp; Management </strong>
                        </p>
                        <p><strong>NH-59A, Sandalpur, Dewas </strong></p>
                        <p><strong>Madhya Pradesh</strong></p>
                        <p><strong>455339 </strong></p>
                        <h2><br /></h2>  
                      </div>
                      <div className="clipboard-ql" contentEditable="true" tabIndex={-1} />
                    </div>
                  </div>
                </div>
                <div id="share-details"><label>Share this on:</label> 
                  <div className="icons-share"><a><span className="facebook-icon" /></a>
                    <a><span className="twitter-icon" /></a>
                    <a><span className="whatsapp-icon" /></a>
                  </div>
                </div>
                <div id="details-support"><label>Contact Us:</label>
                  <div className="sub-detail-support"><a href="mailto:fees@ssism.org?subject=Query for Payment Page Id: pl_CqGVpX5GIOXJEV" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z">
                        </path>
                        <path d="M0 0h24v24H0z" fill="none" />
                      </svg>
                      <div>fees@ssism.org</div>
                    </a></div>
                  <div className="sub-detail-support"><a href="tel:+91 84358 04504"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z">
                        </path>
                      </svg>
                      <div>+91 84358 04504</div>
                    </a></div>
                </div> 
                <div id="terms-details-container">
                  <label>Terms &amp; Conditions:</label>
                  <div className="terms-details-description-value text-wrap">
                    <p className="terms-details-description-tnc">
                      You agree to share information entered on this page with Singaji Educational
                      Society (owner of this page) and Razorpay, adhering to applicable laws.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-btn">
            <a className="btn btn-custom-form" id="mobile-proceed-btn-next">NEXT</a>
          </div>
        </div>
        <div data-view-id="view-2" id="view-main" className="view-slider slide-up">
          <div className="payment-title">
            <a slot="before" href="#" id="btn-backto-screen" className="btn-back" type="button">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
              </svg>
            </a> 
            Payment Details
            <div className="underline-title" />
          </div> 
          <div id="section-form" className="webview-android">
            <form className="form-ui ">
              <div>
                <div>
                  <div className="payment-field ">
                    <div className="payment-label">Amount
                    </div>
                    <div className="payment-field-content">
                      <div className="wrapper-field">
                        <span className="addon-field addon-field--before">
                          <span slot="addonBefore">
                            <b className="currency-symbol">₹</b></span></span>
                        <input className="el-field" type="number" placeholder="Enter Amount" id="field-amount" required />
                        <span className="addon-field addon-field--after"><span slot="addonAfter" style={{color: 'red', fontSize: 12}} /></span>
                      </div>
                      <div className="error-field" />
                      <div className="description-error" />
                    </div>
                  </div>
                </div>  
                <div className="payment-field">
                  <div className="payment-label">Name
                  </div>
                  <div className="payment-field-content">
                    <div className="wrapper-field"><span className="addon-field " />
                      <input className="el-field" name="name" id="field-name" required type="text" />
                      <span className="addon-field " />
                    </div>
                    <div className="error-field" />
                    <div className="description-field" />
                  </div>
                </div> 
                <div className="payment-field">
                  <div className="payment-label">Phone
                  </div>
                  <div className="payment-field-content">
                    <div className="wrapper-field">
                      <span className="addon-field " />
                      <input className="el-field" name="phone" type="tel" required id="field-phone" minLength={8} />
                      <span className="addon-field " />
                    </div>
                    <div className="error-field" />
                    <div className="description-field" />
                  </div>
                </div>
                <div className="payment-field">
                  <div className="payment-label">Email
                  </div>
                  <div className="payment-field-content">
                    <div className="wrapper-field"><span className="addon-field " />
                      <input className="el-field" name="email" type="text" required id="field-email" />
                      <span className="addon-field " />
                    </div>
                    <div className="error-field">
                    </div>
                    <div className="description-field">
                    </div>
                  </div>
                </div> 
                <div className="payment-field">
                  <div className="payment-label">PAN Number
                  </div>
                  <div className="payment-field-content">
                    <div className="wrapper-field"><span className="addon-field " />
                      <input className="el-field" name="pan" id="field-pan" required type="text" />
                      <span className="addon-field " />
                    </div>
                    <div className="error-field" />
                    <div className="description-field" />
                  </div>
                </div> 
                <div className="payment-field">
                  <div className="payment-label">Address
                  </div>
                  <div className="payment-field-content">
                    <div className="wrapper-field"><span className="addon-field " />
                      <input className="el-field" name="address" id="field-address" required type="text" />
                      <span className="addon-field " />
                    </div>
                    <div className="error-field" />
                    <div className="description-field" />
                  </div>
                </div> 
              </div>
         
              <div slot="after">
                <div id="footer-form" className>
                  <div className="footer-form-payment">
                    <img id="logo-pay" alt="pay-methods"
                    src="https://cdn.razorpay.com/static/assets/pay_methods_branding.png"
                    width="180"/>
               
                    <button type="button" id="submit-pay-btn" data-toggle="modal" aria-labelledby="myModalPaymentLabel" data-target="#myModalPayment" className=" btn-custom-form ">Pay
                      <span style={{marginLeft: 4}} /></button> 
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="modal fade" id="myModalPayment" tabIndex={-5} role="dialog" aria-labelledby="myModalPaymentLabel" aria-hidden="true" style={{padding: 0}}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          Alert <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div className="modal-body">
          <iframe src="https://ssism.org" style={{width: 500, minHeight: 100, maxWidth: '100%', maxHeight: '100%'}} frameBorder={0} allowTransparency="true" />  
          <h6>Please contact MR. Prashant Sharma:- 9893371756</h6>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <button style={{display: 'none'}} type="button" id="noPaymentModelBtn" data-toggle="modal" data-target="#myModalPayment" className=" btn-custom-form ">Pay</button>

</div>

<footer className="pt-4">
  <div className="footer-wrapper section-padding">
    <div className="container">
      <div className="row">
        <div className="wow zoomIn col-xs-12 col-sm-6 col-md-3 animated" style={{visibility: 'visible', animationName: 'zoomIn'}}>
          <p className="footer-heading">About Us</p>
          <ul className="footermenu">
            <li><a href="/aboutUs">Who We Are</a></li>
           </ul>
        </div>
      
        <div className="wow zoomIn col-xs-12 col-sm-6 col-md-3 animated" style={{visibility: 'visible', animationName: 'zoomIn'}}>
          <p className="footer-heading">Other Link</p>
          <ul className="footermenu">
            <li><Link to="/termsConditions">Terms And Conditions</Link></li>
            <li><Link to="/privacy">Privacy policy</Link></li>
            <li><Link to="/refundsCancellations">Refunds/Cancellations</Link></li>
          </ul>
        </div>
        <div className="wow zoomIn col-xs-12 col-sm-6 col-md-3 animated" style={{visibility: 'visible', animationName: 'zoomIn'}}>
          <p className="footer-heading">find us</p>
          <ul className="footercontact">
            <li><i className="flaticon-phone16" /><span>phone:</span><a href="tel:91 9893371756"> +91 9893371756</a></li>
            <li><i className="flaticon-email21" /><span>e-mail:</span><a href="mailto:contact@ssism.org"> contact@ssism.org</a></li>
            <li><i className="flaticon-world91" /><span>web:</span><a href="http://ssism.org/"> www.ssism.org</a></li>
          </ul>
          <i className="flaticon-home78" />
        </div>
        <div className="wow zoomIn col-xs-12 col-sm-6 col-md-12 animated" style={{visibility: 'visible', animationName: 'zoomIn'}}>
          <ul className="footercontact">
            <li><i className="flaticon-mainpage" /><span>address:</span>   NH 59-A,Gram-Sandalpur, Tehsil Khategaon, District Dewas, MP, India, Pin Code – 455336 </li>
          </ul>
          <i className="flaticon-home78" />
        </div>
      </div> {/* /.row */}
    </div> {/* /.container */}
  </div>
  <div className="footer-bottom">
    <div className="container">
      <div className="row">
        <div className="wow zoomIn col-xs-12 animated" style={{visibility: 'visible', animationName: 'zoomIn'}}>
          <p>© 2018 All rights reserved. <span>SSISM</span>  by <a href="https://ssism.org/index.html">Singaji Software Solutions</a></p>
          <div className="backtop  pull-right">
            <i className="fa fa-angle-up back-to-top" style={{display: 'none'}} />
          </div>{/* /.backtop */}
        </div>{/* /.col-xs-12 */}
      </div>{/* /.row */}
    </div>{/* /.container */}
  </div>{/* /.creditwrapper */}
</footer>

</>

);
};

export default PaymentPage;