/* eslint-disable react/prop-types */
import {useEffect, useLayoutEffect, useState} from "react"
import {Modal} from "react-bootstrap";
import {PDFDocument,rgb} from "pdf-lib";
import fontkit from '@pdf-lib/fontkit'
import fontFamily from '../../../assets/Roboto-Medium.ttf'
import {capitalizeFirstLetter} from "../../../utils/capitalizeFirstLetter";
import AllUrl from "../../../../../../redux/constants/url";

// eslint-disable-next-line no-unused-vars, react/prop-types
export const ShowPdf = ({level,name,show,setShow,group,knowledge}) => {
    useLayoutEffect(() => {
        const data = async () => {
            try {
                let file;
                console.log("level is ",level)
                if(level === "1" || level.toLowerCase() === "a" || level.toLowerCase() === "1a" || level.toLowerCase() === "1b" || level.toLowerCase() === "1c") {
                    file = AllUrl.certificate_url;
                } else if(level === "2" || level.toLowerCase() === "b" || level.toLowerCase() === "2a" || level.toLowerCase() === "2b" || level.toLowerCase() === "2c") {
                    file = AllUrl.certificate_url;
                } else if(level === "3" || level.toLowerCase() === "c" || level.toLowerCase() === "3a" || level.toLowerCase() === "3b" || level.toLowerCase() === "3c") {
                    file = AllUrl.certificate_url;
                }
                const fontBytes = await fetch(fontFamily).then(res => res.arrayBuffer())
                const pdfByte = await fetch(file).then(res => res.arrayBuffer());

                const pdfDoc = await PDFDocument.load(pdfByte);
                pdfDoc.registerFontkit(fontkit);
                const customFont = await pdfDoc.embedFont(fontBytes);

                const pages = pdfDoc.getPages();
                const firstPage = pages[0];

                const textWidth = customFont.widthOfTextAtSize(name,30);

                const middleCharacterIndex = Math.floor(name.length / 2);
                const middleCharacterWidth = customFont.widthOfTextAtSize(name[middleCharacterIndex],30);
                const x = (firstPage.getSize().width - textWidth) / 2 + middleCharacterWidth / 2;
                const y = firstPage.getSize().height / 2; // y-coordinate of the text position
                console.log("X center is ",(firstPage.getSize().width / 2) + 94);
                console.log("Y position is ",y - 50);

                firstPage.drawText(capitalizeFirstLetter(name),{
                    x: x + 100,
                    y: y - 50,
                    size: 30,
                    color: rgb(0,0,0),
                    font: customFont
                });
                firstPage.drawText(`"Level ${parseInt(level)} Certification"`,{
                    x: 415,
                    y: 170,
                    size: 22,
                    color: rgb(0, 0.5, 0.5),
                    font: customFont
                });
                firstPage.drawText(knowledge,{
                    x: 545,
                    y: 143,
                    size: 18,
                    color: rgb(0.9, 0.5, 0),
                    font: customFont
                });
                const pdfBytes = await pdfDoc.save();
                const blob = new Blob([pdfBytes],{type: "application/pdf"});
                const url = URL.createObjectURL(blob);
                console.log(url);
                document.getElementById('pdf-frame').src = url + "#toolbar=0";
            } catch(error) {
                console.error('Error loading PDF:',error);
            }
        }
        data();
    });
    return (
        <div>
            {group?.toLowerCase() === 'iteg' ? <Modal
                show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setShow(false)}
            >
                <Modal.Header closeButton />
                <Modal.Body className=" h-75 w-100" >
                    < iframe key={0} title="certificate" id="pdf-frame" width={"100%"} style={{minHeight: "400px"}} > </iframe> 
                </Modal.Body>

            </Modal> : <Modal
                show={show}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setShow(false)}
            >
                <Modal.Header closeButton />
                <Modal.Body className="" >
                    <> Sorry , but there is No preview available for BEG and MEG </>
                </Modal.Body>

            </Modal>}
        </div>
    )
}
