import {
  CButton,
  CModal,
  CModalBody,
  // CModalHeader,
  // CModalTitle,
  // CModalTitle,
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import Success_Icon_yellow from "../assests/image/Success_Icon_yellow.svg"
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo from "../assests/image/User.svg";
import crossButton from "../assests/image/crossButton.svg";
import "./styles/createAdmin.css";
import { createNewAdmin } from "../../redux/actionDispatcher/superAdmin/createNewAdminDispatcher";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../assests/common/LoaderButton";
import AllUrl from "../../redux/constants/url";
import axios from 'axios'
import Swal from 'sweetalert2'

function CreateAdminPopup({ adminData, createNewAdmin }) {
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);
  const validationSchema = Yup.object({
    OTP: Yup.number()
      .required("Please fill the field above")
  });

  const formik = useFormik({
    initialValues: {
     OTP: "",
    },
    validationSchema,

    onSubmit: (values) => {
      var data = JSON.stringify({
       otp: values.OTP,
      });
      Swal.fire({
          title: `Are you sure?`,

          html:
              '<hr>' +
              'Are you sure?' +
              '<br>' +
              `You want to Move all students to next year`,
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: `Yes Move`,
          // confirmButtonText:'Deactive',
          reverseButtons: true,
          confirmButtonColor: "red",
          cancelButtonColor: "#E12D2D",
          showLoaderOnDeny: true,


      }).then(async (result) => {
          if (result.isConfirmed) {
              setIsLoading(true);
            
      var config = {
        method: 'post',
        url: `${AllUrl.promoteStudents}`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
          try{

            await axios(config)
            setIsLoading(false);
            Swal.fire({
              title: 'Success',

              imageUrl: Success_Icon_yellow,
              imageAlt: 'image',
              imageWidth:  ' 75px' ,
              imageHeight: ' 75px' ,
              html:
                  '<hr/>' +
                  'Students promoted successfully',
              showConfirmButton: false,
              // showCloseButton:true,
              timer: 2500,
     
          })
          
                     setTimeout(() => {
                     navigate(-1);
                 }, 3000);
          }catch(e){
            alert(e.response.data)
          }

      
          }else{
              navigate(-1);
          }
      })
    },
  });

  return (
    <div>

      <CModal

        alignment="center"
        visible={visible}
        onClose={() => {
          formik.handleReset()
          setVisible(false);
        }}
      >
        <CModalBody>
          <div className="first_div createAdmin">
            <div className="second_div " style={{ marginTop: "0px" }}>
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <img onClick={() => navigate(-1)}
                    style={{ height: "22px", width: "22px", marginLeft: '125%', marginTop: "-10px", cursor: "pointer" }} src={crossButton} alt="close" className="logo_img" />
                  <h4 className=" text-aligns-center createAdminhead" style={{ color: '#5A607F', fontWeight: 'bold' }}>
                    Move All Students To Next Year
                  </h4>
                  <img src={logo} alt="logo ssism" className="logo_img mb-3" />{" "}
                </div>
                <div>
                  <label htmlFor="gmail" className="labels d-block text-center mb-1" >
                   Security Pin
                  </label>
                  <input
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="inputs d-block w-100"
                    // aria-label="email"
                    name="OTP"
                    type="number"
                    id="OTP"
                    placeholder="Enter security pin to move all students"
                  />
                  {formik.errors.OTP && formik.touched.OTP ? (
                    <div className="text-danger fs-6 text-center">
                      {formik.errors.OTP}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <button
                  disabled={isLoading}
                  style={{ marginTop: '20px' }}
                  className=" submit_btn w-100  btn-md text-light font-weight-bold"
                  type="submit"
                >
                  {isLoading? <LoaderButton /> : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
}



//Connecting the component to our store
export default CreateAdminPopup;
