import React from 'react'

function Navbar({ Options }) {
  return (
    <div >
      <Options />
    </div>
  )
}

export default Navbar
