import {
  // CButton,
  CModal,
  CModalBody,
  // CModalHeader,
  // CModalTitle,
  // CModalTitle,
} from "@coreui/react";
import React, { useState } from "react";
import UpgradeIcon from '@mui/icons-material/Upgrade';
// import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
// import logo from "../assests/image/User.svg";
// import crossButton from "../assests/image/crossButton.svg";
// import "./styles/createAdmin.css";
// import { createNewAdmin } from "../../redux/actionDispatcher/superAdmin/createNewAdminDispatcher";
// import { useNavigate } from "react-router-dom";
// import LoaderButton from "../assests/common/LoaderButton";
// import AllUrl from "../../redux/constants/url";
import axios from "axios";
// import logo from "../../assests/image/User.svg";
import crossButton from "../../assests/image/crossButton.svg";
import AllUrl from "../../../redux/constants/url";
import LoaderButton from "../../assests/common/LoaderButton";
import Swal from "sweetalert2";
import { Tooltip, Whisper } from "rsuite";
import { useNavigate } from "react-router-dom";

function FeesRecieptDeletePopup({ data ,accountAction}) {
  const token = localStorage.getItem("token");
  const stdId = data.stdId;
  const AccountsReceiptNo = data.accountsReceiptNo;
  const navigate = useNavigate()
  // // console.log(adminData);

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  // // console.log(data)

  const validationSchema = Yup.object({
    installmentNo: Yup.string()
      .required("Please fill the field above").test('Select Installment', 'Please Select Installment',value => value !=="")
});
    const formik = useFormik({
    initialValues: {
        installmentNo: data.installmentNo,
        receivedType: data.receivedType,
    },
    validationSchema,

    onSubmit: async values => {
      setLoading(true);
      // console.log(data.stdId);
      var resultData = JSON.stringify({
        stdId: stdId,
        accountsReceiptNo: AccountsReceiptNo,
        installmentNo: formik.values.installmentNo,
        receivedType:formik.values.receivedType
      });

      // console.log(resultData);
      var config = {
        method: "post",
        url: AllUrl.updatereciept,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: resultData,
      };
      var resultOfAxios =  await axios(config);
      setLoading(false);
      if (!resultOfAxios || resultOfAxios.status === 200 ) {
        // // console.log(resultOfAxios.status)
        let backData = JSON.stringify({
            "stdId": data.stdId,
          "choice": 1
        });
        // console.log("______________________________________");
        // console.log("backdata", backData);
        // console.log("______________________________________");
        let getBackData = {
            method: 'post',
            url: AllUrl.allInfoOfActiveStudent,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: backData
        };
        accountAction(getBackData, navigate, true, setLoading);
        Swal.fire("Receipt Updated", " ", "success");
        setVisible(!visible);
      }
    

      // createNewAdmin(config, navigate);
    },
  });

  return (
    <div>
      <Whisper
        placement="top"
        controlId="control-id-hover"
        trigger="hover"
        speaker={<Tooltip>Update Installment.</Tooltip>}>
        <UpgradeIcon
          className="mr-3"
          style={{
            color: "#656A87",
            fontSize: "27px",
            cursor: "pointer",
            marginTop: "12px",
          }}
          onClick={() => setVisible(!visible)}></UpgradeIcon>
      </Whisper>

      <CModal
        alignment="center"
        visible={visible}
        onClose={() => {
          formik.handleReset();
          setVisible(false);
        }}>
        <CModalBody>
          <div className="first_div createAdmin">
            <div className="second_div " style={{ marginTop: "0px" }}>
              <div>
                <img
                  onClick={() => setVisible(!visible)}
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "110%",
                    marginTop: "-10px",
                    cursor: "pointer",
                  }}
                  src={crossButton}
                  alt="close"
                  className="logo_img"
                />
                <h4
                  className=" text-aligns-center createAdminhead"
                  style={{ color: "#5A607F", fontWeight: "bold" }}>
                  Update
                </h4>
                {/* <img src={logo} alt="logo ssism" className="logo_img" />{" "} */}
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="col">
                  <label className="addStdLable" htmlFor="">
                    Installment No.
                  </label>
                  <select
                    name="installmentNo"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.installmentNo}
                    className="form-select">
                    {/* <option value="">Select Installment</option> */}
                    <option value="1">First Installment</option>
                    <option value="2">Second Installment</option>
                    <option value="3">Third Installment</option>
                    <option value="POSTMETRIC">Postmatric Scholarship</option>
                    <option value="GKB">GKB Scholarship</option>
                    <option value="REG.FEE">Registration Fees</option>
                    <option value="WAIVEOFF">Waive Off </option>
                  </select>
                  {formik.errors.installmentNo &&
                  formik.touched.installmentNo ? (
                    <div className="text-danger fs-6">
                      {formik.errors.installmentNo}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col">
                  <label className="addStdLable" htmlFor="">
                    Pay By.
                  </label>
                  <select
                    name="receivedType"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.receivedType}
                    className="form-select">
                    {/* <option value="">Select Pay By</option> */}
                    <option value="Cash">Cash</option>
                    <option value="Online">Online</option>
                    
                  </select>
                  {formik.errors.receivedType &&
                  formik.touched.receivedType ? (
                    <div className="text-danger fs-6">
                      {formik.errors.receivedType}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <button
                  // disabled={adminData.loading}
                  style={{ marginTop: "35px" }}
                  className=" submit_btn w-100  btn-md text-light font-weight-bold"
                  type="submit">
                  {loading ? <LoaderButton /> : "Request"}
                </button>
              </form>
            </div>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
}

//Connecting the component to our store
export default FeesRecieptDeletePopup;
