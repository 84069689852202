import React from "react";
import "../components/styles/Navbar.css";
import AppHeaderDropdown from "../components/HeaderDropdown";
// import CreateAdminPopup from "../components/CreateAdminPopup";
// import FeesStructure from "../components/FeesStructure";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../assests/common/BackButton";

const HeaderLink = () => {
    const currentLocation = useLocation().pathname;
    if (localStorage.getItem('userEdit')) {
        var editData = JSON.parse(localStorage.getItem('userEdit'))
    }
    var location = getLocation();

    const navigate = useNavigate();

    function getLocation() {
        if (currentLocation === "/student_admin_dashboard") {
            return "Dashboard";
        }
        else if (currentLocation === "/student_admin_dashboard/students") {
            return "Students";
        }
        else if (currentLocation === "/student_admin_dashboard/updateselfappliedstudentdetail") {
            return <><BackButton/> Update Student</>;
        }
        else if (currentLocation.includes("/student_admin_dashboard/attendance")) {
            return <><BackButton/>Attendance</>;
        }
        else if (currentLocation === "/student_admin_dashboard/studenttable") {
            return "Applied Student";
        }
        else if (currentLocation === "/student_admin_dashboard/addnewstudent") {
            return "Add Student";
        } else if (currentLocation === "/student_admin_dashboard/selfappliedstudent") {
            return "Self Applied Student";
        }
        else if (currentLocation === "/student_admin_dashboard/activitytable") {
            return "Activities";
        }
        else if (currentLocation.includes('/student_admin_dashboard/activity')) {
            if (editData) {
                return (
                    <div className='d-flex flex-column'>

                        <span className="m-0 p-0"><BackButton />{editData.accountInfo.firstName + "  " + editData.accountInfo.lastName} <span className="recieved-fee-circle" style={{ backgroundColor: `${editData.accountInfo.isActive === 'true' ? '#56F000' : '#f99300'}` }}></span></span>
                        <span className="" style={{ fontSize: '15px', marginLeft: "35px" }}>{`${editData.accountInfo.branch}-${editData.accountInfo.year} (${editData.accountInfo.joinBatch}-${parseInt(editData.accountInfo.joinBatch) + 3})`}</span>
                    </div>
                )
            }
            return "Add Student";
        }else if (currentLocation === "/student_admin_dashboard/registrations-dashboard") {
            return "Admission Dashboard";
        }else if (currentLocation.includes('selfappliedandapliedstudent')) {
            console.log(currentLocation.split('/').pop())
            return <><BackButton/> {currentLocation.split('/').pop()} Registrations</>
        } else if(currentLocation === '/student_admin_dashboard/cards-certificate' || currentLocation === '/student_admin_dashboard/cards-certificate/teacher' || currentLocation === '/student_admin_dashboard/cards-certificate/certificate' || currentLocation === '/student_admin_dashboard/cards-certificate/student-id-card-certificate-zip' || currentLocation === '/student_admin_dashboard/cards-certificate/teacher-card-zip') {
            return <><BackButton /> Cards and Certificate</>
        }
        else {
            return "Dashboard";
        }
    }

    return (
        <div className="container_navbar">
            <div className="navbar_container_start_side">
                <h3 className=" fw-bolder ml-4" style={{ color: "#5A607F" }}> {location === "Add Student" ? <BackButton /> : ""}  {location}</h3>
            </div>
            <div className="navbar_container_end_side">
                {location === "Applied Student" && (
                    <div className="changing_navbar_containt_conditional">
                        <button
                            style={{
                                borderWidth: "1px solid #ffc107 ",
                                backgroundColor: "orange",
                                color: "white",
                                border: "none",
                                marginRight: "10px",
                                padding: "5px 15px",
                                fontWeight: "bold",
                                borderRadius: "4px",
                            }}
                            onClick={() => {
                                navigate("addnewstudent");
                            }}
                        >
                            Add Student <i className="fas fa-plus pl-3"></i>
                        </button>
                    </div>
                )}
                <AppHeaderDropdown />
            </div>
        </div>
    );
}

export default HeaderLink;
