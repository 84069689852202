import {
    SEND_NOTIFICATION_SUCCESS,
    SEND_NOTIFICATION_REQUEST,
    SEND_NOTIFICATION_FAIL,
} from "../../constants/actions"

const initialState = {
    loading: false,
    newNotification: false,
    error: false,
}

const sendNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_NOTIFICATION_REQUEST:
            return {
               ...state,
                loading: true,
            }
        case SEND_NOTIFICATION_SUCCESS:
            return {
                loading: false,
                newNotification: true,
                error: false,
            }
        case SEND_NOTIFICATION_FAIL:
            return {
                loading: false,
                newNotification: false,
                error: true,
            }
        default:
            return state
    }
}

export default sendNotificationReducer