import * as XLSX from 'xlsx';

const findKeyIgnoreCase = (obj,keyName) => {
    const lowerKeyName = keyName.toLowerCase();
    for(const key in obj) {
        if(key.toLowerCase() === lowerKeyName) {
            return key;
        }
    }
    return null;
};
export const ExcelDataReader = async (file) => {
    let excelData = [];

    if(file) {
        try {
            const data = await file.arrayBuffer(file);
            const excelFile = XLSX.read(data);
            const excelSheet = excelFile.Sheets[excelFile.SheetNames[0]];
            let jsonData = XLSX.utils.sheet_to_json(excelSheet);
            // const data = await file.arrayBuffer();
            // const workbook = XLSX.read(data,{type: 'array'});
            // const sheetName = workbook.SheetNames[0];
            // const worksheet = workbook.Sheets[sheetName];
            // const jsonData = XLSX.utils.sheet_to_json(worksheet,{header: 1});

            if(!jsonData[0][findKeyIgnoreCase(jsonData[0],'name')] && !jsonData[0][findKeyIgnoreCase(jsonData[0],'group')] && !jsonData[0][findKeyIgnoreCase(jsonData[0],'level')] && !jsonData[0][findKeyIgnoreCase(jsonData[0],'knowledge')]) {
                return "File must Contain Columns for Name , Group , Level.  Note:- if you have column please check  spelling mistake  and don't give blank space between rows";
            } else if(!jsonData[0][findKeyIgnoreCase(jsonData[0],'name')]) {
                return "File must Contain Column for Name.  Note:- if you have column please check  spelling mistake  and don't give blank space between rows";
            } else if(!jsonData[0][findKeyIgnoreCase(jsonData[0],'level')]) {
                return "File must Contain Column for Level.  Note:- if you have column please check  spelling mistake  and don't give blank space between rows";
            } else if(!jsonData[0][findKeyIgnoreCase(jsonData[0],'group')]) {
                return "File must Contain Column for Group.  Note:- if you have column please check  spelling mistake  and don't give blank space between rows";
            } else if(!jsonData[0][findKeyIgnoreCase(jsonData[0],'knowledge')]) {
                return "File must Contain Column for Knowledge.  Note:- if you have column please check  spelling mistake  and don't give blank space between rows";
            }
            if(jsonData.length) {
                jsonData.forEach((value) => {
                    console.log("value is",value)
                    const keyName = findKeyIgnoreCase(value,'name');
                    const level = findKeyIgnoreCase(value,'level');
                    const group = findKeyIgnoreCase(value,'group');
                    const knowledge = findKeyIgnoreCase(value,'knowledge');
                    console.log("keyName is ",keyName)
                    if(keyName && level && group && knowledge) {
                        if(keyName.trim() !== "") {
                            excelData.push({
                                "name": value[keyName],
                                "group": value[group],
                                "level": value[level],
                                "knowledge": value[knowledge]
                            });
                        }
                    }
                })
            } else {
                throw new Error("File must contain data.");
            }
        } catch(err) {
            console.error(err);
            excelData = new String(err.message);
        }
    }

    return excelData;
};
