import React ,{useState}from "react";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import checkBoxImg from '../../assests/image/AccountIcons/thirdInstallmentCheckImg.svg';
import LoaderButton from '../../assests/common/LoaderButton';
import { Accordion } from "@mui/material";
import allUrls from '../../../redux/constants/url'
import { useFormik } from 'formik'
import { toast } from 'react-toastify';
import axios from 'axios'
import * as Yup from "yup";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FeesDetalis = ({StudentProfileData}) => {
    // console.log(StudentProfileData)

    const [expanded, setExpanded] = React.useState(true);
    const [loaderLoading, setLoaderLoading] = useState(false)
    const initialValues = {


        accountStatus: StudentProfileData ? StudentProfileData.isActive : '',
        remark: StudentProfileData ? StudentProfileData.remark : '',
        GKB_Amount: StudentProfileData ? StudentProfileData.GKBAmount : '',
        postmetricAmount: StudentProfileData ? StudentProfileData.postmetricAmount : '',
        Busfee: StudentProfileData ? StudentProfileData.busFee : '',
        reg_Fees: StudentProfileData ? StudentProfileData.regFees : '',
        Tutionfee: StudentProfileData ? StudentProfileData.totalFee : '',
        year: StudentProfileData ? StudentProfileData.year : '',
        Firstinstallment: StudentProfileData ? StudentProfileData.firstInstallment : '',
        FirstinstallmentDate: StudentProfileData ? StudentProfileData.firstInstallmentDate : '',
        Secondinstallment: StudentProfileData ? StudentProfileData.secondInstallment : '',
        SecondinstallmentDate: StudentProfileData ? StudentProfileData.secondInstallmentDate : '',
        Thirdinstallment: StudentProfileData ? StudentProfileData.thirdInstallment : '',
        ThirdinstallmentDate: StudentProfileData ? StudentProfileData.thirdInstallmentDate : '',
        sponsorshipType: StudentProfileData ? StudentProfileData.sponsorshipType : '',
        feesScheme: StudentProfileData ? StudentProfileData.feesScheme : '',
        registrationNumber: StudentProfileData ? StudentProfileData.registrationNo : '',


    }
    const validationSchema = Yup.object({


        accountStatus: Yup.string().required("Required!"),
        remark: Yup.string().required("Required!"),
        GKB_Amount: Yup.string().required("Required!").test('Is positive', 'must be positive', val => val >= 0),
        postmetricAmount: Yup.string().required("Required!").test('Is positive', 'must be positive', val => val >= 0),
        Busfee: Yup.string().required("Required!").test('Is positive', 'must be positive', val => val >= 0),
        reg_Fees: Yup.string().required("Required!").test('Is positive', 'must be positive', val => val >= 0),
        Tutionfee: Yup.string().required("Required!").test('Is positive', 'must be positive', val => val >= 0),
        year: Yup.string().required("Required!"),
        registrationNumber: Yup.string().required("Required!"),
        Firstinstallment: Yup.string().required("Required!").test('Is positive', 'must be positive', val => val >= 0),
        FirstinstallmentDate: Yup.string().required("Required!"),
        Secondinstallment: Yup.string().required("Required!").test('Is positive', 'must be positive', val => val >= 0),
        SecondinstallmentDate: Yup.string().required("Required!"),
        Thirdinstallment: Yup.string().required("Required!").test('Is positive', 'must be positive', val => val >= 0),
        ThirdinstallmentDate: Yup.string().required("Required!"),
        feesScheme: Yup.string().required("Required!"),
        sponsorshipType: Yup.string().required("Required!"),





    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            // console.log(values);


            const UpdateAccountInfoData = {
                "stdId": StudentProfileData.stdId,
                "currentYear": StudentProfileData.currentYear,
                "isActive": formik.values.accountStatus,
                "feesScheme": formik.values.feesScheme,
                "totalFee": formik.values.Tutionfee,
                "sponsorshipType": formik.values.sponsorshipType,
                'year': formik.values.year,
                "regFees": formik.values.reg_Fees,
                "busFee": formik.values.Busfee,
                "GKBAmount": formik.values.GKB_Amount,
                "postmetricAmount": formik.values.postmetricAmount,
                "scholarshipAmount": parseInt(formik.values.postmetricAmount) + parseInt(formik.values.GKB_Amount),
                "firstInstallment": formik.values.Firstinstallment,
                "firstInstallmentDate": formik.values.FirstinstallmentDate,
                "secondInstallment": formik.values.Secondinstallment,
                "secondInstallmentDate": formik.values.SecondinstallmentDate,
                "thirdInstallment": formik.values.Thirdinstallment,
                "thirdInstallmentDate": formik.values.ThirdinstallmentDate,
                "remark": formik.values.remark,
                "registrationNo": formik.values.registrationNumber,
                "id": StudentProfileData.id
            }

            if ((UpdateAccountInfoData.firstInstallment + UpdateAccountInfoData.secondInstallment + UpdateAccountInfoData.thirdInstallment + UpdateAccountInfoData.GKBAmount + UpdateAccountInfoData.postmetricAmount) !== UpdateAccountInfoData.totalFee) {
                toast.error('Installments + scholarship Should Be Equal To Total Fees', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });



            } else {


                const token = localStorage.getItem("token");
                var config = {
                    method: 'post',
                    url: allUrls.updateAccountInformation,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    data: UpdateAccountInfoData
                };
                // // console.log(config, UpdatePersonalInfoData)
                setLoaderLoading(true)

                const response = await axios(config)
                if (response.status === 200) {
                    setLoaderLoading(false)
                    toast.success('Account Information Successfully Updated', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    let backData = JSON.stringify({
                        "stdId": StudentProfileData.stdId,
                        "choice": parseInt(localStorage.getItem('choice')),
                    });
                    let getBackData = {
                        method: 'post',
                        url: allUrls.allInfoOfActiveStudent,
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        data: backData
                    };
                    axios(getBackData)
                        .then(function (response) {

                            // console.log(response);
                            if (response.status === 200) {
                                localStorage.setItem('userEdit', JSON.stringify(response.data));
                                window.location.reload();
                            }
                        })
                }
                else if (response.status === 400) {
                    setLoaderLoading(false)
                    toast.warn('Invalid Email', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                else if (response.status === 404) {
                    setLoaderLoading(false)
                    toast.warn('User Not Found', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                else if (response.status === 406) {
                    setLoaderLoading(false)
                    toast.warn('User Not Found', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                else if (response.status === 500) {
                    setLoaderLoading(false)
                    toast.warn('Internal server error', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                else {
                    setLoaderLoading(false)
                }
                // // console.log(response.status);

            }
        }
    })

    return (

        <>
                       <Accordion className="my-2 me-3 ms-2" expanded={expanded} >
               <AccordionSummary
                         expandIcon={<ExpandMoreIcon />}
                        onClick={() => setExpanded(!expanded)}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{
                            backgroundColor: '#E6E9F4 ', borderBottom: '2px solid orange', maxHeight: "50px", minHeight: "50px"
                        }}
                    >
                      <Typography style={{ color: "#414c97", margin: "0px" }}><b> Fees Details {StudentProfileData.year === 'I' ? '1st Year' : StudentProfileData.year === 'II' ? '2nd Year' : '3rd Year'} {'( '+ StudentProfileData.sessionName+' )'}<b style={{color:"red"}}> {parseInt(StudentProfileData.revisionNumber) > 0 ?  ' Attempt ' +(parseInt(StudentProfileData.revisionNumber)):""}  {StudentProfileData.isLatest === "true" ? " *": ''}</b> </b></Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ backgroundColor: '#F4F7FC', padding: '15px' }}>
                        <Typography component={'div'} >
                            {/* Personal Details */}


                            <div className="row mt-3">
                                <div className="col">
                                    <label className='addStdLable' htmlFor="">Account status</label>
                                    <select name="accountStatus" disabled={true} value={formik.values.accountStatus} onChange={formik.handleChange} className={formik.touched.accountStatus ? `form-select ${formik.errors.accountStatus ? "invalid" : ""}` : 'form-select'} >
                                        <option value="true">Active</option>
                                        <option value="false">Deactive</option>
                                    </select>
                                    {formik.errors.accountStatus && formik.touched.accountStatus ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.accountStatus}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col">
                                    <label className='addStdLable' htmlFor="">Fees Scheme</label>
                                    <select name="feesScheme" disabled={true} value={formik.values.feesScheme} onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} className={formik.touched.feesScheme ? `form-select ${formik.errors.feesScheme ? "invalid" : ""}` : 'form-select'} id="inputGroupSelect02" placeholder="select">
                                        <option value='none'>None</option>
                                        <option value='fullFees'>Full Fees</option>
                                        <option value='oneShot'>One Shot</option>
                                        <option value='OnlyScholarShip'>OnlyScholarShip</option>
                                    </select>
                                    {formik.errors.feesScheme && formik.touched.feesScheme ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.feesScheme}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <div className="col">
                                    <label className='addStdLable' htmlFor="">Sponsorship Type</label>
                                    <select name="sponsorshipType"
                                        value={formik.values.feesScheme !== "none" ? formik.values.sponsorshipType = "none" : formik.values.sponsorshipType}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} className={formik.touched.sponsorshipType ? `form-select ${formik.errors.sponsorshipType ? "invalid" : ""}` : 'form-select'} id="inputGroupSelect02" placeholder="select" disabled={formik.values.feesScheme === "none" ? false : true} >
                                        <option value='none'>None</option>
                                        <option value='SNS'>SNS</option>
                                        <option value='SVS'>SVS</option>
                                        <option value='Management_quota'>Management Quota</option>
                                    </select>
                                    {formik.errors.sponsorshipType && formik.touched.sponsorshipType ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.sponsorshipType}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col">
                                    <label className='addStdLable' htmlFor="">Registration Number</label>
                                    <input name="registrationNumber" onChange={formik.handleChange} disabled={true} value={formik.values.registrationNumber} type="text" className='form-control' placeholder='Registration number ' />
                                    {formik.errors.registrationNumber && formik.touched.registrationNumber ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.registrationNumber}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>

                            </div>


                            <div className="row mt-3">
                                <div className="col">
                                    <label className='addStdLable' htmlFor="">Registration Amount</label>
                                    <input name="reg_Fees" onChange={formik.handleChange} disabled={true} value={formik.values.reg_Fees} type="text" className='form-control' placeholder='Registration Amount' />
                                    {formik.errors.reg_Fees && formik.touched.reg_Fees ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.reg_Fees}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col">
                                    <label className='addStdLable' htmlFor="">Total Fees<span style={{ color: "red" }}>*</span></label>
                                    <input name="Tutionfee" onChange={formik.handleChange} disabled={true} value={formik.values.Tutionfee} type="number" className='form-control' placeholder='Course Fees' />
                                    {formik.errors.Tutionfee && formik.touched.Tutionfee ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.Tutionfee}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col">
                                    <label className='addStdLable' htmlFor="">postmetric Amount</label>
                                    <input name="postmetricAmount" onChange={formik.handleChange} disabled={true} value={formik.values.postmetricAmount} type="number" className='form-control' placeholder='Postmetric Amount' />
                                    {formik.errors.postmetricAmount && formik.touched.postmetricAmount ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.postmetricAmount}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col">
                                    <label className='addStdLable' htmlFor="">GKB Amount</label>
                                    <input name="GKB_Amount" onChange={formik.handleChange} disabled={StudentProfileData.gender === 'male'}  value={formik.values.GKB_Amount} type="number" className='form-control' placeholder='GKB Amount' />
                                    {formik.errors.GKB_Amount && formik.touched.GKB_Amount ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.GKB_Amount}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>

                            </div>

                            <div className="row mt-3">
                                <div className="col">
                                    <label className='addStdLable' htmlFor="">First Installment</label>
                                    <input name="Firstinstallment" onChange={formik.handleChange} disabled={true} value={formik.values.Firstinstallment} type="number" className='form-control' placeholder='First Installment' />
                                    {formik.errors.Firstinstallment && formik.touched.Firstinstallment ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.Firstinstallment}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <div className="col">
                                    <label className='addStdLable' htmlFor="">Second Installment</label>
                                    <input name="Secondinstallment" onChange={formik.handleChange} disabled={true} value={formik.values.Secondinstallment} type="number" className='form-control' placeholder='Second Installment' />
                                    {formik.errors.Secondinstallment && formik.touched.Secondinstallment ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.Secondinstallment}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className=' col'><label className='addStdLable' htmlFor="">Third Installment</label>
                                    <div className="d-flex">
                                        <input name="Thirdinstallment" onChange={formik.handleChange} disabled={true} value={formik.values.Thirdinstallment} type="number" className='form-control' placeholder='Third Installment' />

                                        {StudentProfileData.scholarshipAmount > 0 ? <img style={{ marginLeft: "-25px" }} src={checkBoxImg} alt="." /> : ''}
                                    </div>
                                    {formik.errors.Thirdinstallment && formik.touched.Thirdinstallment ? (
                                        <div className=" text-danger fs-6">
                                            {formik.errors.Thirdinstallment}
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                </div>
                                <div className="col">
                                    <label className='addStdLable' htmlFor="">Bus Fees</label>
                                    <input name="Busfee" onChange={formik.handleChange} disabled={true} value={formik.values.Busfee} type="number" className='form-control' placeholder='Bus Fees' />
                                    {formik.errors.Busfee && formik.touched.Busfee ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.Busfee}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>

                            </div>

                            <div className="row mt-3">
                                <div className="col">
                                    <label className='addStdLable' htmlFor="">First Installment date</label>
                                    <input name="FirstinstallmentDate" onChange={formik.handleChange} disabled={true} value={formik.values.FirstinstallmentDate} type="date" className='form-control' placeholder='First Installment date' />
                                    {formik.errors.FirstinstallmentDate && formik.touched.FirstinstallmentDate ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.FirstinstallmentDate}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col">
                                    <label className='addStdLable' htmlFor="">Second Installment date</label>
                                    <input name="SecondinstallmentDate" onChange={formik.handleChange} disabled={true} value={formik.values.SecondinstallmentDate} type="date" className='form-control' placeholder='Second Installment date' />
                                    {formik.errors.SecondinstallmentDate && formik.touched.SecondinstallmentDate ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.SecondinstallmentDate}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col">
                                    <label className='addStdLable' htmlFor="">Third Installment date</label>
                                    <input name="ThirdinstallmentDate" onChange={formik.handleChange} disabled={true} value={formik.values.ThirdinstallmentDate} type="date" className='form-control' placeholder='Third Installment date' />
                                    {formik.errors.ThirdinstallmentDate && formik.touched.ThirdinstallmentDate ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.ThirdinstallmentDate}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col">
                                    <label className='addStdLable' htmlFor="">Remark</label>
                                    <input name="remark" onChange={formik.handleChange} disabled={true} value={formik.values.remark} type="text" className='form-control' placeholder='Remark' />
                                    {formik.errors.remark && formik.touched.remark ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.remark}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                {/* <div className="col">
                                    <label className='addStdLable' htmlFor="">Year</label>

                                    <select name="year" disabled={true} value={formik.values.year} onBlur={formik.handleBlur}
                                        disabled
                                        onChange={formik.handleChange} className={formik.touched.year ? `form-select ${formik.errors.year ? "invalid" : ""}` : 'form-select'} id="inputGroupSelect02" placeholder="select">

                                        <option value='I'>I</option>
                                        <option value='II'>II</option>
                                        <option value='III'>III</option>

                                    </select>
                                    {formik.errors.year && formik.touched.year ? (
                                        <div className="text-danger fs-6">
                                            {formik.errors.year}
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                </div> */}
                            </div>

               

                        </Typography>
                    </AccordionDetails>
                    </Accordion>
        </>
    );
};

export default FeesDetalis;