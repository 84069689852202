/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import {
  useFilters,
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useAsyncDebounce,
} from "react-table";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import axios from "axios";
import { connect } from "react-redux";
import Loader from "../assests/common/Loader";
import { Fragment, useMemo } from "react";
import delete_icon from '../assests/image/delete_Icon.svg';

import {
  CDropdown,
  CDropdownMenu,
  CDropdownToggle,
  CPopover,
} from "@coreui/react";

import "./styles/Table.css";
import { fetchStudentTable } from "../../redux/actionDispatcher/superAdmin/studentTableDatadispatcher";
import SkeletonColor from "../../helpers/Skeletrone";
import { TableCheckbox } from "./tableComponents/TableCheckbox";
import AllUrl from "../../redux/constants/url";
import updown_sort from "../assests/image/updown_sort.svg";
import { DeactivateButton } from "../assests/common/Color";
import Pagination from "../assests/common/Pagination";
import { useNavigate } from "react-router-dom";
import { Tooltip, Whisper } from "rsuite";
import NoDataFound from "../assests/common/NoDataFound";
import filtericon from "../../components/assests/image/AccountIcons/filter.svg";
import rightArrow from "../../components/assests/image/right_arrow_icon.svg";
import { Modal } from "react-bootstrap";
import { useRef } from "react";

import EditorJS from "@editorjs/editorjs";
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Table from 'editorjs-table';
import Checklist from '@editorjs/checklist';
import Marker from '@editorjs/marker';

import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect } from "react";
import { updateActivityStatus } from "../../redux/actionDispatcher/superAdmin/updateActivityStatusDispatcher";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const MultipleFilter = (rows, accessor, filterValue) => {
  const arr = [];
  rows.forEach((val) => {
    if (filterValue.includes(val.original[accessor])) arr.push(val);
  });
  return arr;
};

function setFilteredParams(filterArr, val) {
  if (filterArr.includes(val)) {
    filterArr = filterArr.filter((n) => {
      return n !== val;
    });
  } else filterArr.push(val);

  if (filterArr.length === 0) filterArr = undefined;
  return filterArr;
}

function SelectColumnFilter({
  column: { filterValue = [], setFilter, preFilteredRows, id },
}) {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  let offsetObj = [0, 0];

  if (id === "stream") offsetObj = [75, 18];
  else if (id === "year") offsetObj = [60, 18];
  else if (id === "createdBy") offsetObj = [40, 18];
  else if (id === "assignToName") offsetObj = [212, 18];
  else if (id === "activityType") offsetObj = [75, 18];
  else if (id === "isPrivate") offsetObj = [42, 18];
  else if (id === "priority") offsetObj = [55, 18];

  let name = id;

  switch (id) {
    case 'stream':
      name = 'Stream';
      break;
    case 'year':
      name = 'Year';
      break;

    case 'createdBy':
      name = 'Created By';
      break;

    case 'assignToName':
      name = 'Assigned To';
      break;
    case 'activityType':
      name = 'Activity Type';
      break;
    case 'isPrivate':
      name = 'My Private';
      break;
    case 'priority':
      name = 'Priority';
      break;
    default:
      break;
  }
  return (
    <Fragment>
      <div className="d-flex justify-content-end">
        <CPopover
          offset={offsetObj}
          content={
            <div className="">
              {options.map((option, i) => {

                let option_label = option;

                if (id === "isPrivate") {
                  if (option === "true") option_label = "True";
                  else option_label = "False";
                } else if (id === "year") {
                  if (option === "I") option_label = "I Year";
                  else if (option === "II") option_label = "II Year";
                  else if (option === "III") option_label = "III Year";
                } else if (id === "activityType") {
                  if (option === "account") option_label = "Account";
                  else if (option === "academic") option_label = "Academic";
                  else if (option === "attendance") option_label = "Attendance";
                  else if (option === "general") option_label = "General";
                } else if (id === 'assignToName') {
                  if (option === null || option === undefined) {
                    option_label = "Un assigned";
                  }
                } else if (id === "priority") {
                  if (option === "low") option_label = "Low";
                  else if (option === "medium") option_label = "Medium";
                  else if (option === "high") option_label = "High";
                }

                return (
                  <Fragment key={i}>
                    <div
                      id={`${id}`}
                      style={{ height: "30px", cursor: "pointer" }}
                      className="filter_btn_hover p-1 pt-2 my-1 d-flex align-items-center "
                    >
                      <label
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="font-medium text-gray-700 d-flex align-items-center cursor-pointer"
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          checked={filterValue.includes(option)}
                          type="checkbox"
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-1"
                          id={option}
                          name={option}
                          value={option}
                          style={{ cursor: "pointer" }}
                          onChange={(e) => {
                            setFilter(
                              setFilteredParams(filterValue, e.target.value)
                            );
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        ></input>

                        {option_label}
                      </label>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          }
          placement="right"
        >
          <div className="btn-group filter_btn_hover">
            <button
              onClick={(e) => {
              }}
              className="btn filter_btn"
            >
              {name}
            </button>
            <img
              src={rightArrow}
              alt=">"
              width="6px"
              style={{
                marginTop: "4px",
                marginRight: "10px",
              }}
            />
          </div>
        </CPopover>
      </div>
    </Fragment>
  );
}

function GlobalFilter({ filter, setFilter, preGlobalFilteredRows }) {
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 1);

  return (
    <>
      <input
        style={{
          width: "270px",
          height: "41px",
          outline: "none",
          border: "1px solid #7979792b",
          padding: "5px",
          borderRadius: "4px",
          paddingLeft: "12px",
        }}
        type="search"
        value={filter || ""}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        placeholder="Search..."
      />
      <i
        style={{
          marginLeft: "-31px",
          alignSelf: "center",
          marginBottom: "7px",
          color: "rgb(90, 96, 127,0.7)",
        }}
        className="fas fa-search"
      ></i>
    </>
  );
}

function ActivityTable({ fetchStudentTable, table_data, updateActivityStatus }) {
  const token = localStorage.getItem("token");
  const choosedReportId = localStorage.getItem("chosedREport");
  const [onlyMineReports, setOnlyMineReports] = useState(localStorage.getItem('onlyMineReports') === 'true' ? 3 : 1);

  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [reportData1, setReportData] = useState()
  const [loading, setLoading] = useState(false);
  const editorInstance3 = useRef();
  const data = localStorage.getItem("userEdit");
  const StudentAccountData = JSON.parse(data)
  const [allAdminInfo, setAllAdminInfo] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [loading2, setLoading2] = useState(false);
  var StudentName = StudentAccountData?.name

  React.useEffect(async () => {
    setLoading2(true);
    var config = {
      method: "get",
      url: AllUrl?.infoAllAdmin,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    var result = await axios(config);

    if (result?.status === 200) {
      setAllAdminInfo(result.data);
      setLoading2(false);
    }

  }, []);

  useEffect(async () => {
    if ([2,3].includes(onlyMineReports)) {
      const userId = localStorage.getItem('userId') || '_';
      const email = localStorage.getItem('email') || '';
      setCurrentData(table_data.table_data.filter((d) =>  onlyMineReports === 2 ? ( d.assignTo === userId || d.createdBy === email) : (d.assignTo === userId  && (onlyMineReports === 3 ? ( d.status === 'open' || d.status === 'reopen') : true))).map((d) => {
        const user = allAdminInfo.find(data => data.email === d.createdBy)
        let data = JSON.parse(JSON.stringify(d));
        if (user && user.name) {
          data.createdBy = user.name;
        }
        return data;

      }));
    } else {
      setCurrentData(table_data.table_data.map((d) => {
        const user = allAdminInfo.find(data => data.email === d.createdBy)
        if (user && user.name) {
          d.createdBy = user.name;
        }
        return d;

      }));
    }
  }, [onlyMineReports, table_data.table_data])

  const getSingleReportData = async (reportId) => {
    setLoading(true);
    var configSingleReport = {
      method: "get",
      url: `${AllUrl?.getSingleReportById}/${reportId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    var result = await axios(configSingleReport);
    if (result.status === 200) {
      setReportData(result.data);
      setLoading(false);
      setUpdateModalShow(true);
    }
    else {
      setReportData([]);
    }
  }

  const UpdateActivityModal = (props) => {

    const { allAdminInfo, reportData } = props;
    var [descriptionData, setDescriptionData] = useState(reportData !== undefined && reportData !== null ? JSON.parse(reportData?.description) : null);
    const [selectedFiles2, setSelectedFiles2] = useState([]);
    const [expanded3, setExpanded3] = React.useState({
      panel3: false
    });

    const handleChange3 = (id) => () => {
      setExpanded3((pre) => {
        return {
          ...pre,
          [id]: !expanded3[id]
        }
      });
    };
    const initialValues = {
      stdId: reportData?.stdId,
      activityType: reportData?.activityType,
      disscussTo: reportData?.disscussTo,
      description: descriptionData,
      priority: reportData?.priority,
      isPrivate: reportData?.isPrivate !== undefined && reportData.isPrivate !== null ? JSON.parse(reportData?.isPrivate) : '',
      status: reportData?.status,
      activitySubject: reportData?.activitySubject,
    };

    const validationSchema = Yup.object({
      activityType: Yup.string().required('Required'),
      priority: Yup.string().required('Required'),
      status: Yup.string().required('Required'),
      activitySubject: Yup.string().required('Required'),
    });

    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: async () => {
        let base64StringsArray2 = selectedFiles2?.map((item) => item.base64String);
        const submitForm = async () => {
          setLoading(true);
          var newUpdateData = {
            "id": (reportData?.id),
            "stdId": (reportData?.stdId),
            "activityType": (formik?.values?.activityType),
            "disscussTo": (formik.values.disscussTo === "-1" ? "" : formik.values.disscussTo),
            "description": descriptionData,
            "priority": (formik.values.priority),
            "isPrivate": formik.values.activityType === 'general' ? "false" : JSON.stringify(formik.values?.isPrivate),
            "status": (formik.values.status),
            "activitySubject": (formik.values.activitySubject),
            "attachments": base64StringsArray2,
          };

          const activityConfig = {
            method: 'post',
            url: AllUrl.updateActivity,
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            data: newUpdateData
          };

          try {
            var result = await axios(activityConfig);
            if (result.status === 200) {
              setUpdateModalShow(false);
              var config = {
                method: "GET",
                url: AllUrl.getAllActivities,
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              };
              fetchStudentTable(config, false);

              var notifyConfig = {
                method: "get",
                url: AllUrl?.getNotificationCount,
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              };

              updateActivityStatus(notifyConfig, navigate);
              setLoading(false);
              clearForm();
            }
          } catch (error) {
            toast.warn(error.response.data.error, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
          }
        }
        Swal.fire({
          title: `Confirmation`,

          html: `Student - ${StudentName}` +
            '<hr>' +
            'Are you sure?' +
            '<br>' +
            `You want to Update Activity.`,
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: 'No',
          confirmButtonText: `Yes `,
          reverseButtons: true,
          showCloseButton: true,
          confirmButtonColor: "#F8A72C",
          showLoaderOnDeny: true,

          showClass: {
            backdrop: 'swal2-noanimation', // disable backdrop animation
            popup: '',                     // disable popup animation
            icon: ''                       // disable icon animation
          },
          hideClass: {
            popup: '',                     // disable popup fade-out animation
          }

        }).then(async (result) => {
          if (result.isConfirmed) {
            submitForm();
          }
        })
      }
    });
    const clearForm = () => {
      formik.resetForm();
    }


    const initEditor2 = () => {
      if (descriptionData && document.getElementById('editorjs3')?.children.length < 1) {
      const editor = new EditorJS({
        holder: 'editorjs3',
        onReady: () => {
            if (!editorInstance3.current)
          editorInstance3.current = editor;
        },
        autofocus: false,
        onChange: async () => {
          editor.saver?.save().then((result) => {
            if (result) {
              setDescriptionData(result);
            }
          }).catch((err) => {
            // console.log('editor error', err);
          })
        },
        tools: {
          header: Header,
          list: List,
          table: Table,
          checklist: Checklist,
          Marker: {
            class: Marker,
            shortcut: 'CTRL+SHIFT+M',
          }
        },
        data: descriptionData
      });
      }
    }

    useEffect(() => {
      if (editorInstance3.current === null && reportData !== undefined && reportData !== null) {
        initEditor2();
      }

      return () => {
        editorInstance3?.current?.destroy();
        editorInstance3.current = null;
      }

    }, [reportData]);
    const handleDisabledPrivate = (val) => {
      if (val?.activityType === "general") {
        return true;
      } else {
        return false;
      }
    }

    const convertFilesToBase642 = async (e) => {
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();

        reader.onload = (event) => {
          const base64String = event.target.result;
          setSelectedFiles2((prevSelectedFiles) => [
            ...prevSelectedFiles,
            { file, base64String },
          ]);
        };
        reader.readAsDataURL(file);
      }
    };

    const fileTypeIcons = {
      pdf: 'fas fa-file-pdf',
      docx: 'fas fa-file-word',
      mp4: 'fas fa-file-video',
      jpg: 'fas fa-image',
      jpeg: 'fas fa-image',
      png: 'fas fa-image',
      svg: 'fas fa-image',
      mp3: 'fas fa-file-audio',
      csv: 'fas fa-file-excel',
      xlsx: 'fas fa-file-excel',
    };

    const getFileType = (fileName) => {
      const extension = fileName.split('.').pop().toLowerCase();
      if (fileTypeIcons.hasOwnProperty(extension)) {
        return fileTypeIcons[extension];
      }
      return 'fa-solid fa-file';
    };


    const truncateFileName = (fileName, maxLength) => {
      const extension = fileName.split('.').pop().toLowerCase();
      if (fileName.length <= maxLength) {
        return fileName;
      }
      const truncatedName = fileName.substring(0, maxLength - extension.length - 4) + '...' + extension;
      return truncatedName;
    };

    const handleDeleteFile2 = (index) => {
      const updatedFiles2 = [...selectedFiles2];
      updatedFiles2.splice(index, 1);
      setSelectedFiles2(updatedFiles2);
    };

    return (
      <Modal
        onHide={props.onHideUpdate}
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
        contentClassName={'custom_modal'}
      >
        <Modal.Header closeButton style={{ borderBottom: 'none' }}>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col">
                <label className='addStdLable' htmlFor="">Activity Type</label>
                <select name='activityType' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values?.activityType} className='form-select' >
                  <option value='general'>General</option>
                  <option value='academic'>Academic</option>
                  <option value='account'>Account</option>
                  <option value='attendance'>Attendance</option>
                </select>
                {formik.errors?.activityType && formik.touched?.activityType ? (
                  <div className="text-danger fs-6">
                    {formik.errors?.activityType}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="col">
                <label className='addStdLable' htmlFor="">Discused With</label>
                <select
                  name='disscussTo'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.disscussTo}
                  className='form-select'
                >
                  <option value="-1">Select Assigned To</option>
                  {allAdminInfo?.map((item) => {
                    if (item.isActive === 1) {
                      return (
                        <option key={item.userId} value={item.userId}>
                          {item.name}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
                {formik.errors.disscussTo && formik.touched.disscussTo ? (
                  <div className="text-danger fs-6">
                    {formik.errors.disscussTo}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className='row'>
              <div className="col">
                <label className='addStdLable' htmlFor="">Priority</label>
                <select name='priority' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.priority} className='form-select' >
                  <option value='low'>Low</option>
                  <option value='medium'>Medium</option>
                  <option value='high'>High</option>
                </select>
                {formik.errors.priority && formik.touched.priority ? (
                  <div className="text-danger fs-6">
                    {formik.errors.priority}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="col">
                <label className='addStdLable' htmlFor="">Status</label>
                <select name='status' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik?.values?.status} className='form-select' >
                  {
                    reportData?.status === 'open' ? (
                      <>
                        <option value='open'>Open</option>
                        <option value='close'>Close</option>
                      </>
                    ) : reportData?.status === 'close' ? (
                      <>
                        <option value='close'>Close</option>
                        <option value='reopen'>Re-open</option>
                      </>
                    ) : reportData?.status === 'reopen' ? (
                      <>
                        <option value='reopen'>Re-open</option>
                        <option value='close'>Close</option>
                      </>
                    ) : ''
                  }
                </select>
                {formik.errors.status && formik.touched.status ? (
                  <div className="text-danger fs-6">
                    {formik.errors.status}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className='row'>
              <div className="col">
                <label className='addStdLable' htmlFor="">Activity Subject</label>
                <input name='activitySubject' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.activitySubject} type="text" className='form-control' placeholder='Activity Subject' />
                {formik.errors.activitySubject && formik.touched.activitySubject ? (
                  <div className="text-danger fs-6">
                    {formik.errors.activitySubject}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className='col'>
                <label className='addStdLable' htmlFor="">{" "}</label>
                <div className='col d-flex '>
                  <input type='checkbox'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="isPrivate"
                    value={formik.values.isPrivate}
                    checked={
                      formik?.values?.isPrivate === true ? true : false
                    }
                    className='m-2'
                    disabled={
                      handleDisabledPrivate(formik.values)
                    }
                  />{' '}Is Private

                </div>
                {formik.errors.isPrivate && formik.touched.isPrivate ? (
                  <div className="text-danger">
                    {formik.errors.isPrivate}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row d-flex mt-2">
              <div>
                <Accordion className="my-2 me-3 ms-2 mb-5" expanded={expanded3.panel3 === true} onChange={handleChange3('panel3')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      backgroundColor: '#E6E9F4 ', borderBottom: '2px solid orange', maxHeight: "50px", minHeight: "50px"
                    }}
                  >
                    <Typography style={{ color: "#414c97", margin: "0px" }}>
                      <div>


                        <button type='button' className='btn btn-warning text-light fw-bold addnew_btn' onClick={() => { document.getElementById("profilePhoto2").click() }}>Choose Files</button>
                        <input
                          type="file"
                          id="profilePhoto2"
                          accept="*/*"
                          multiple
                          style={{ display: 'none' }}
                          onChange={convertFilesToBase642}
                        />
                      </div>
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails style={{ backgroundColor: '#F4F7FC', padding: '15px' }}>
                    <Typography component={'div'} >
                      <div className='attach_box' id="style-1">
                        <div className="row m-1 ">
                          {selectedFiles2?.length > 0 || reportData?.attachmentUrl?.length > 0 ? (
                            <>
                              {reportData?.attachmentUrl?.length > 0 ?
                                <ul className="mb-0">
                                  {reportData.attachmentUrl?.map((item, index) => (
                                    <>
                                      <li key={index} className='file_li my-2'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                          <a href={item} className='dock_link' rel="noreferrer" target='_blank'>
                                            <div className='d-flex align-items-center'>
                                              <div className='file_icon me-4'><i className="fas fa-file"></i></div>
                                              <div>{`Attachment_${index + 1}`}</div>
                                            </div>
                                          </a>

                                          <div>
                                            <button style={{ background: 'transparent' }} type='button' onClick={() => {
                                              Swal.fire({
                                                title: `Confirmation`,

                                                html: `File ->  Attachment_${index + 1}` +
                                                  '<hr>' +
                                                  'Are you sure?' +
                                                  '<br>' +
                                                  `You want to Delete Attachment.`,
                                                showCancelButton: true,
                                                showConfirmButton: true,
                                                cancelButtonText: 'No',
                                                confirmButtonText: `Yes `,
                                                reverseButtons: true,
                                                showCloseButton: true,
                                                confirmButtonColor: "#F8A72C",

                                                showLoaderOnDeny: true,

                                                showClass: {
                                                  backdrop: 'swal2-noanimation', // disable backdrop animation
                                                  popup: '',                     // disable popup animation
                                                  icon: ''                       // disable icon animation
                                                },
                                                hideClass: {
                                                  popup: '',                     // disable popup fade-out animation
                                                }

                                              }).then(async (result) => {
                                                if (result.isConfirmed) {
                                                  const deleteAttachmentApi = async () => {
                                                    setLoading(true);
                                                    var deleteAttachmentData = {
                                                      "id": (reportData?.id),
                                                      "attachmentUrl": (item),
                                                    };

                                                    const attachmentConfig = {
                                                      method: 'post',
                                                      url: AllUrl.deleteOneAttachment,
                                                      headers: {
                                                        'Authorization': `Bearer ${token}`,
                                                        'Content-Type': 'application/json'
                                                      },
                                                      data: deleteAttachmentData
                                                    };

                                                    try {
                                                      var result = await axios(attachmentConfig);
                                                      if (result.status === 200) {
                                                        setLoading(false);
                                                        getSingleReportData(choosedReportId);
                                                        setLoading(false);
                                                      }
                                                    } catch (error) {
                                                      toast.warn(error.response.data.error, {
                                                        position: "top-center",
                                                        autoClose: 3000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                      });
                                                      setLoading(false);
                                                    }
                                                  }
                                                  deleteAttachmentApi();
                                                }
                                              })
                                            }}><img src={delete_icon} alt="" /></button>
                                          </div>
                                        </div>
                                      </li>

                                    </>
                                  ))}
                                </ul> : null
                              }

                              <ul className='mt-0'>
                                {selectedFiles2?.map((item, index) => (
                                  <li key={index} className='file_li my-2'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                      <div className='d-flex align-items-center'>
                                        <div className='file_icon me-4'><i className={getFileType(item.file.name)}></i></div>
                                        <div>{truncateFileName(item.file.name, 25)}</div>
                                      </div>
                                      <div><button style={{ background: 'transparent' }} type='button' onClick={() => {
                                        Swal.fire({
                                          title: `Confirmation`,

                                          html: `File -> ${truncateFileName(item.file.name, 25)} ` +
                                            '<hr>' +
                                            'Are you sure?' +
                                            '<br>' +
                                            `You want to Delete Attachment.`,
                                          showCancelButton: true,
                                          showConfirmButton: true,
                                          cancelButtonText: 'No',
                                          confirmButtonText: `Yes `,
                                          reverseButtons: true,
                                          showCloseButton: true,
                                          confirmButtonColor: "#F8A72C",
                                          showLoaderOnDeny: true,

                                          showClass: {
                                            backdrop: 'swal2-noanimation', // disable backdrop animation
                                            popup: '',                     // disable popup animation
                                            icon: ''                       // disable icon animation
                                          },
                                          hideClass: {
                                            popup: '',                     // disable popup fade-out animation
                                          }

                                        }).then(async (result) => {
                                          if (result.isConfirmed) {
                                            handleDeleteFile2(index);
                                          }
                                        })
                                      }}><img src={delete_icon} alt="" /></button></div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </>
                          ) : (
                            <div className="d-flex justify-content-center">
                              <h2 style={{ color: '#5A607F' }}>No Reports Found</h2>
                            </div>
                          )}
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <div className="row">
              <label className='addStdLable' htmlFor="">Activity Description</label>
              <div className='editor_box'>
                <div id='editorjs3' style={{ paddingBottom: "0px" }}></div>
              </div>
            </div>

            <div className='d-flex justify-content-center mt-5 mb-3'>
              <button className="btn btn-md btn-outline-warning fw-bold  " style={{ width: "200px", backgroundColor: 'white', color: 'orange' }} type="submit" disabled={loading} >Update Activity</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }

  const navigate = useNavigate();

  React.useEffect(() => {
    var config = {
      method: "GET",
      url: AllUrl.getAllActivities,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    fetchStudentTable(config, false);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        header: "S.No",
        accessor: "Srno",
        Cell: ({ row: { original, index } }) => {
          return index + 1;
        },
        Filter: "",
        filter: "",
      },
      {
        header: "Name",
        accessor: "name",
        Cell: ({ row: { original, index } }) => {
          return `${original.name}`;
        },
        Filter: "",
        filter: "",
      },
      {
        header: "Stream",
        accessor: "stream",
        Filter: SelectColumnFilter,
        filter: MultipleFilter,
      },
      {
        header: "Year",
        accessor: "year",
        Filter: SelectColumnFilter,
        filter: MultipleFilter,
      },
      {
        header: "Created By",
        accessor: "createdBy",
        Filter: SelectColumnFilter,
        filter: MultipleFilter,
      },
      {
        header: "Assigned To",
        accessor: "assignToName",
        Cell: ({ row: { original } }) => {
          return original.assignToName || '-'
        },
        Filter: SelectColumnFilter,
        filter: MultipleFilter,
      },
      {
        header: "Date",
        accessor: "createdDate",
        Filter: "",
        filter: "",
      },
      {
        header: () => <span style={{ display: 'none', width: '0px' }}></span>,
        accessor: "activityType",
        Filter: SelectColumnFilter,
        filter: MultipleFilter,
        Cell: ({ row: { original } }) => (
          <>
          </>
        ),
        width: 0
      },
      {
        header: () => <span style={{ display: 'none', width: '0px' }}></span>,
        accessor: "isPrivate",
        Filter: SelectColumnFilter,
        filter: MultipleFilter,
        Cell: ({ row: { original } }) => (
          <>
          </>
        ),
        width: 0
      },
      {
        header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original } }) => (
          <div className='circle-main align-item-center ml-0'>
            <div className="d-flex align-items-center justify-content-start pl-0">
              <span className='recieved-fee-circle' style={{ backgroundColor: original.status === 'open' ? "#56F000" : original.status === 'close' ? 'gray' : original.status === 'reopen' ? 'orange' : '', marginRight: "10px", marginLeft: "17px" }}></span>
              {original.status}
            </div>
          </div>
        ),
        Filter: "",
        filter: "",
      },
      {
        header: "Priority",
        accessor: "priority",
        Filter: SelectColumnFilter,
        filter: MultipleFilter,
      },
      {
        header: "Subject",
        accessor: "activitySubject",
        Cell: ({ row: { original } }) => {
          return (original.activitySubject === '' || original.activitySubject === null) ? (
            <>
              -
            </>
          ) : <b>{original.activitySubject}</b>
        },
        Filter: "",
        filter: "",
      },
      {
        header: "Edit",
        accessor: "icon",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Edit Student Info</Tooltip>}
          >
            <button
              className="table_btn_size"
              style={DeactivateButton}
              onClick={() => {
                getSingleReportData(original?.id)
                localStorage.setItem("chosedREport", original?.id);
                localStorage.setItem('userEdit', JSON.stringify(original))
              }}
            >
              View
            </button>
          </Whisper>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    nextPage,
    previousPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    canNextPage,
    setPageSize,
    pageOptions,
    state,
    setGlobalFilter,
    preGlobalFilteredRows,
    selectedFlatRows,
    prepareRow,
  } = useTable(
    { columns, data: currentData },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            header: ({ getToggleAllRowsSelectedProps }) => (
              <TableCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <TableCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const { globalFilter, pageSize, pageIndex } = state;

  var idData = [];
  var exportCsv = [];

  const checkboxData = JSON.stringify(
    {
      selectedFlatRows: selectedFlatRows.forEach((row) => {
        let data = Object.assign({}, row.original);
        delete data.photo;
        idData.push(data.id);
        exportCsv.push(data);
      }),
    },
    null,
    2
  );

  return (table_data.loading || loading2) ? (
    <SkeletonColor></SkeletonColor>
  ) : (
    <Fragment>
      {(table_data.secondLoading || loading) && <Loader />}
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {table_data.loading && <Loader />}
      {(allAdminInfo?.length > 0) ?
        <div className="container-fluid">
          <div
            style={{
              position: "sticky",
              top: "80px",
              backgroundColor: "#f4f7fc",
              zIndex: "6",
              paddingBottom: "20px",
            }}
          >
            <div className="row  mx-0 mt-3">
              <div className="d-flex">
                <div style={{ marginLeft: "-12px" }}>
                  <select
                    className="form-select table_select_row_options"
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                  >
                    {[10, 25, 50, 100].map((pageSize) => (
                      <option value={pageSize} key={pageSize}>
                        Show Entries {pageSize}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='col d-flex align-items-center'>
                  <div className="form-check ml-2">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" onChange={() => setOnlyMineReports(1)} checked={onlyMineReports === 1} id="flexRadioDefault1" />
                    <label className="form-check-label" for="flexRadioDefault1">
                      All reports
                    </label>
                  </div>
                  <div className="form-check ml-2">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" onChange={() => setOnlyMineReports(2)} checked={onlyMineReports === 2} id="flexRadioDefault2" />
                    <label className="form-check-label" for="flexRadioDefault2">
                      My reports
                    </label>
                  </div>
                  <div className="form-check ml-2">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" onChange={() => setOnlyMineReports(3)} checked={onlyMineReports === 3} id="flexRadioDefault3" />
                    <label className="form-check-label" for="flexRadioDefault3">
                      My Open Reports
                    </label>
                  </div>
                </div>
                <div className="d-flex ml-auto me-3">
                  <div
                    className="d-flex mr-2"
                    style={{
                      height: "40px",
                      width: "42px",
                      backgroundColor: "#fff",
                      borderRadius: "3px",
                      border: "1px solid #EDEDED",
                    }}
                  >
                    <CDropdown variant="nav-item" style={{ color: "white" }}>
                      <CDropdownToggle
                        placement="bottom-end"
                        className="py-0"
                        caret={false}
                      >
                        <img
                          src={filtericon}
                          alt=""
                          style={{
                            height: "22px",
                            width: "35px",
                            marginTop: "-35px",
                            marginLeft: "-13px",
                          }}
                        />
                      </CDropdownToggle>

                      <CDropdownMenu
                        component={"div"}
                        className="pt-0 filter-dropdown-menu"
                        placement="bottom-end"
                      >
                        <div className="p-lg-2 pb-0">
                          {headerGroups.map((headerGroup) => (
                            <div
                              style={{ display: "flex flex-column" }}
                              {...headerGroup.getHeaderGroupProps()}
                            >
                              {headerGroup.headers.map((column, i) => (
                                <div
                                  key={i}
                                  style={{
                                    display: "block",
                                    justifyContent: "center",
                                  }}
                                >
                                  {column.canFilter
                                    ? column.render("Filter")
                                    : null}
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </CDropdownMenu>
                    </CDropdown>
                  </div>
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                </div>
              </div>
            </div>
          </div>
          <table {...getTableProps()} id="customers">
            <thead
              style={{
                position: "sticky",
                top: "135px",
                width: "100%",
                backgroundColor: "#f4f7fc",
                zIndex: "5",
              }}
            >
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img
                              src={updown_sort}
                              style={{ marginLeft: "5px" }}
                              alt=""
                            />
                          ) : (
                            <img
                              src={updown_sort}
                              style={{ marginLeft: "5px" }}
                              alt=""
                            />
                          )
                        ) : (
                          ""
                        )}
                        {column.isSorted ? (column.isSortedDesc ? "" : "") : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <NoDataFound rows={rows} />

          <Pagination
            data={rows}
            rows={rows}
            page={page}
            pageIndex={pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
          />
        </div> : ''

      }
      <UpdateActivityModal show={updateModalShow} allAdminInfo={allAdminInfo} reportData={reportData1} onHideUpdate={() => setUpdateModalShow(false)} />
    </Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    table_data: state.studentTableData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStudentTable: (data, isLoading) =>
      dispatch(fetchStudentTable(data, isLoading)),
    updateActivityStatus: (config, navigate) => dispatch(updateActivityStatus(config, navigate)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTable);
