import {  useState } from 'react';
import DateRangePicker from "rsuite/DateRangePicker";
import AttedanceClassCards from './AttedanceClassCard'
import AttedanceClassStudents from './AttedanceClassStudents'
import HolidaysTable from './HolidaysTable'
import EmployeesAttandanceTable from './EmployeesAttandanceTable'
import dateIcon from '../assests/image/AccountIcons/DateIcon.svg';
import { styled, Box } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';

import "./styles/Attendance.css"
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AddAttendance from './AddAttendance';
const Attendance = () => {
   const navigate = useNavigate();
   const [open, setOpen] = useState(false);
   const Backdrop = styled('div')`
   z-index: -1;
   position: fixed;
   right: 0;
   bottom: 0;
   top: 0;
   left: 0;
   
   background-color: rgba(0, 0, 0, 0.5);
   -webkit-tap-highlight-color: transparent;
 
 `;
 const StyledModal = styled(ModalUnstyled)`
   position: fixed;
   z-index: 1300;
   right: 0;
   bottom: 0;
   top: 0;
   left: 0;
   display: flex;
   align-items: center;
   justify-content: center;
 `;
 const style = {
     width: 800,
     bgcolor: 'white',
 
     // p: 2,
     // px: 4,
     pb: 3,
 };
    const dt = new Date();
   
    // alert(dt)
    dt.setDate(1);
    // alert(dt)
    const [date, setDate] = useState({ a: dt, b: new Date() });

    const [isLoading, setIsLoading] = useState(false);
   

    const CIcon = () => {

        return (<>
            <img style={{ marginLeft: "-180px" }} src={dateIcon} alt='date' />
        </>);
    };
    const currentLocation = useLocation().pathname;
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    return (
        <div>


            <div className="d-flex flex-column justify-content-start ml-2">
                <label className='fw-bolder' style={{marginBottom:"1px",color:"#5A607F"}}> Date Range</label>
                <div className='d-flex align-items-center g-2'>
                    <DateRangePicker
                        caretAs={CIcon}
                        value={[date.a, date.b]}
                        onClean={(e) => {

                            e.preventDefault();

                        }}
                        onChange={(value) => {

                            if (!value) {

                                return;
                            }
                            var a = value[0]
                            var b = value[1]
                            // console.log(value)
                            setDate({
                                a, b
                            })
                        }}

                        appearance="default" className='stu-acc-table' placeholder="TO" style={{ width: 230 }} />
                    {isLoading && <div className="ml-1 spinner-border text-warning" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>}
                    {/* <button className='btn btn-primary' onClick={()=>alert('ok')}>back</button> */}
                    {currentLocation.toString().includes("attendance/holidays")?
                <button className='ml-3  btn  btn-sm download-btn' onClick={handleOpen}>Add Holiday</button>:
                <>
                
                <button className='ml-3  btn  btn-sm download-btn' onClick={()=>navigate("holidays")}>Manage Holidays</button>
                {currentLocation.toString().includes("attendance/employees") ? "":
                <button className='ml-3  btn  btn-sm download-btn' onClick={()=>navigate("employees")}>Employees Attendance</button>
                 }
                </>
            }
                </div>
            </div>
            <StyledModal
                    aria-labelledby="unstyled-modal-title"
                    aria-describedby="unstyled-modal-description"
                    open={open}
                    onClose={handleClose}
                    BackdropComponent={Backdrop}
                >
                    <Box sx={style}>
                       <AddAttendance handleClose={handleClose}/>
                    </Box>
                </StyledModal>
            <Routes>
            <Route path="" element={<AttedanceClassCards  setIsLoading={setIsLoading} date={date}/>} />
            <Route path=":branch/:year" element={<AttedanceClassStudents  setIsLoading={setIsLoading} date={date}/>} />
            <Route path="holidays" element={<HolidaysTable  setIsLoading={setIsLoading} date={date}/>} />
            <Route path="employees" element={<EmployeesAttandanceTable  setIsLoading={setIsLoading} date={date}/>} />
            </Routes>
          








        </div>
    );

}


export default Attendance;