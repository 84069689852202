import axios from "axios";
import Swal from "sweetalert2";
// import { toast } from 'react-toastify'
// import getData from "../../services/agent";

import {
    UPDATE_ACTIVITY_STATUS_FAIL,
    UPDATE_ACTIVITY_STATUS_REQUEST,
    UPDATE_ACTIVITY_STATUS_SUCCESS,
} from "../../constants/actions";

export const updateActivityStatus = (data, navigate) => {
    return async (dispatch) => {
        // Console the data getting from the form of create admin
        // // console.log("The data is ", data);
        try {
            dispatch(activityStatusReq());
            axios(data)
                .then(function (response) {
                    if (response.status === 200) {
                        dispatch(activityStatusReqSuccess(response.data));
                        // navigate('admintable');
                        // Swal.fire({
                        //     position: 'top-center',
                        //     icon: 'success',
                        //     title: 'Notification Received Successfully!',
                        //     showConfirmButton: false,
                        //     timer: 2500
                        // })
                    } else if (response.status === 208) {
                        dispatch(activityStatusReqFail());
                        Swal.fire({
                            position: 'top-center',
                            icon: 'info',
                            title: 'Fail To Fetch Notifications',
                            showConfirmButton: false,
                            timer: 2500
                        })
                    } else if (response.status === 404) {
                        dispatch(activityStatusReqFail());
                        Swal.fire({
                            position: 'top-center',
                            icon: 'warning',
                            title: '404 status!',
                            showConfirmButton: false,
                            timer: 2500,
                            showClass: {
                                backdrop: 'swal2-noanimation', // disable backdrop animation
                                popup: '',                     // disable popup animation
                                icon: ''                       // disable icon animation
                            },
                            hideClass: {
                                popup: '',                     // disable popup fade-out animation
                            }
                        })

                    }
                })
                .catch(function (error) {
                    // // console.log(error);
                    dispatch(activityStatusReqFail());
                    Swal.fire({
                        position: 'top-center',
                        icon: 'error',
                        title: 'problem occured!',
                        showConfirmButton: false,
                        timer: 2500,
                        showClass: {
                            backdrop: 'swal2-noanimation', // disable backdrop animation
                            popup: '',                     // disable popup animation
                            icon: ''                       // disable icon animation
                        },
                        hideClass: {
                            popup: '',                     // disable popup fade-out animation
                        }
                    })

                });
        } catch (error) {
            dispatch(activityStatusReqFail());
            Swal.fire({
                position: 'top-center',
                icon: 'error',
                title: 'problem occured!',
                showConfirmButton: false,
                timer: 2500,
                showClass: {
                    backdrop: 'swal2-noanimation', // disable backdrop animation
                    popup: '',                     // disable popup animation
                    icon: ''                       // disable icon animation
                },
                hideClass: {
                    popup: '',                     // disable popup fade-out animation
                }
            })
            // // console.log(error);
        }
    };
};

export const activityStatusReq = () => {
    return {
        type: UPDATE_ACTIVITY_STATUS_REQUEST,
    };
};

export const activityStatusReqSuccess = (data) => {
    return {
        type: UPDATE_ACTIVITY_STATUS_SUCCESS,
        payload: data
    };
};

export const activityStatusReqFail = () => {
    return {
        type: UPDATE_ACTIVITY_STATUS_FAIL,
    };
};
