/* eslint-disable react-hooks/exhaustive-deps */
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useEffect } from "react";
import "../styles/dashboard.css";

function Dashboard({ lineData }) {
  useEffect(async () => {
    if (lineData) {
      let root = am5.Root.new("chartdiv");
      am5.addLicense('AM5CQD[3S')
      root._licenseApplied()
      
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);
      root.dateFormatter.setAll({
        dateFormat: "yyyy",
        dateFields: ["valueX"],
      });

      let data = lineData;

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          focusable: true,
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true,
        })
      );

      let easing = am5.ease.linear;

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xrenderar = am5xy.AxisRendererX.new(root, {});
      xrenderar.grid.template.set('visible', false);
      xrenderar.grid.template.set('stroke')
      let xAxis = chart.xAxes.push(

        am5xy.DateAxis.new(root, {

          maxDeviation: 0.1,
          groupData: true,
          baseInterval: {
            timeUnit: "day",
            count: 1,
          },
          renderer: xrenderar,
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      let yrenderar = am5xy.AxisRendererY.new(root, {});
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {

          maxDeviation: 0.2,
          renderer: yrenderar,
        })
      );

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

      let series = chart.series.push(
        am5xy.SmoothedXYLineSeries.new(root, {
          minBulletDistance: 10,
          stroke: am5.color("#147AD6"),
          connect: true,
          tension: 0.6,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "{valueY}",
          }),
        })
      );

      series.fills.template.setAll({
        fillOpacity: 1,

        visible: true,
      });
      series.fills.template.set("fillGradient", am5.LinearGradient.new(root, {

        stops: [{
          opacity: 1
        }, {
          opacity: 0.2
        }]
      }));

      series.strokes.template.setAll({
        strokeWidth: 1,

      });

      // Set up data processor to parse string dates
      // https://www.amcharts.com/docs/v5/concepts/data/#Pre_processing_data
      series.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "yyyy-MM-dd",
        dateFields: ["date"],

      });

      series.data.setAll(data);

      series.bullets.push(function () {
        let circle = am5.Circle.new(root, {
          radius: 4,
          fill: root.interfaceColors.get("background"),
          stroke: series.get("fill"),
          strokeWidth: 2,

        });

        return am5.Bullet.new(root, {
          sprite: circle,

        });
      });

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      let cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          xAxis: xAxis,
          behavior: "none",

        })
      );
      cursor.lineY.set("visible", false);

      // add scrollbar
      chart.set(
        "scrollbarX",

        am5.Scrollbar.new(root, {
          orientation: "horizontal",

        })
      );

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
    }
  }, [lineData]);

  return (
    <>
      {(lineData === null) ? <div className="d-flex justify-content-center align-items-center" style={{ width: "100%", height: "299.58px" }} >
        <div className="lds-ripple"><div></div><div></div></div>

      </div> :
        <div id="chartdiv" style={{ width: "100%", height: "299.58px" }}></div>
      }
    </>
  );
}

export default Dashboard;
