import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CButton,
  CModal,
  CModalBody,
} from "@coreui/react";
import crossButton from "../assests/image/crossButton.svg";
import LoaderButton from "../assests/common/LoaderButton";
import dropIcon from "../assests/image/drag-and-drop.png";
import success_icon from "../assests/image/success_icon.png";
import error_Icon_Green from "../assests/image/error_icon.svg";
import AllUrl from "../../redux/constants/url";
import "./styles/sendWhatsAppMessage.css";
import * as XLSX from "xlsx";

function SendWhatsAppMessagePopop() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false); // New state for download button
  const inputFile = useRef(null);
  const [excelFile, setExcelFile] = useState(null);
  const [names, setNames] = useState(["Loading...", "custom"]);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [description, setDescription] = useState("");
  const sampleExcel = AllUrl.whatsapp_sample;

  const validationSchema = Yup.object().shape({
    messageType: Yup.string().required("Please select message type."),
    excelFile: Yup.mixed().required("Please upload the excel file."),
  });

  useEffect(() => {
    const fetchNames = async () => {
      try {
        const response = await axios.get(AllUrl.getMessagesTemplates, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json',
          },
        });
        console.log('Message sent successfully:', response.data);
        const templateNames = response.data.data.map((item) => ({
          id: item.id,
          name: item.name
        })
          );
        setNames(templateNames);
      } catch (error) {
        console.error("Error fetching the data:", error);
        setNames(["Error fetching data"]);
      }
    };

    fetchNames();
  }, [localStorage.getItem("token")]);

  const handleTemplateChange = async (event) => {
    const selectedTemplate = event.target.value;
    formik.setFieldValue("messageType", selectedTemplate); // Setting the selected template name as the value for messageType field
    const selectedTemplateId = names.find((template) => template.name === selectedTemplate);
    formik.setFieldValue("templateId", selectedTemplateId.id);

    try {
     
      const response = await axios.get(AllUrl.getMessagesTemplates+"?template_id="+selectedTemplateId.id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Response Data:', response.data);
      console.log('Response Data:', selectedTemplateId.id);
      if (response.data) {
        setDescription(response.data.components[0].text);
        console.log("============="+response.data.components[0].text);
        formik.setFieldValue("MessageDescription", response.data.components[0].text);
      } else {
        console.error("Template details not found.");
      }
    } catch (error) {
      console.error("Error fetching template details:", error);
    }

  };
  const handleFileChange = (event) => {
    setExcelFile(event.target.files[0]);
    parseExcelFile(event.target.files[0]);
  };
  const handleRemoveFile = () => {
    setExcelFile(null);
    setExcelData([]);
    formik.setFieldValue("excelFile", null);
    if (inputFile.current) {
      inputFile.current.value = "";
    }
  };

  const handleDownloadSample = () => {
    try {
      const link = document.createElement("a");
      link.href = sampleExcel;
      link.setAttribute("download", "sample-excel-sheet.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      console.log("Sample Excel file downloaded successfully.");
    } catch (error) {
      console.error("Error downloading sample Excel file:", error);
    }
  };

  const handleDropdownClick = () => {
    setShowOptions(true);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    setExcelFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCloseModal = () => {
    formik.handleReset();
    setExcelFile(null);
    setExcelData([]);
    setVisible(false);
  };
  const parseExcelFile = async (file) => {
    try {
      const reader = new FileReader();
      const data = await new Promise((resolve, reject) => {
        reader.onload = (e) => resolve(e.target.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(json);
    } catch (error) {
      console.error('Error parsing Excel file:', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      messageType: "",
      MessageDescription: "",
      excelFile: null,
      templateId: ""
    },
    validationSchema,

    onSubmit: async (values) => {
      setLoading(true);
      try {
        const { messageType, MessageDescription } = values;
        const payload = {
          templateName: messageType,
          templateBody: MessageDescription.replace(/\n/g, ''),
          excelJson: excelData,
        };

        const response = await axios.post(AllUrl.sendMessage, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json',
          },
        });
        console.log('Message sent successfully:', response.data);
        if (response.status === 200) {
          setSuccessModalVisible(true);
          setVisible(false);
        }
        else {
          setErrorModalVisible(true);
        }

      } catch (error) {
        console.error('Error sending message:', error);
        setErrorModalVisible(true);
        setVisible(false);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div>
      <CButton
        style={{
          backgroundColor: "#F7922A",
          color: "white",
          outline: "none",
          border: "none",
          fontWeight: "bold",
          marginLeft: "15px",
        }}
        onClick={() => setVisible(!visible)}
      >
        Send WhatsApp Message <i className="fas fa-plus"></i>
      </CButton>
      <CModal
        alignment="center"
        padding="1rem"
        visible={visible}
        onClose={() => {
          handleCloseModal();
          setVisible(false);
        }}
      >
        <CModalBody style={{ width: "136%" }}>
          <div className="first_div createAdmin">
            <div className="second_div" style={{ marginTop: "0px" }}>
              <form style={{ width: "151%", marginLeft: "-99px" }}>
                <div>
                  <img
                    onClick={() => {
                      handleCloseModal();

                      setVisible(!visible);
                    }}
                    style={{
                      height: "20px",
                      marginLeft: "86%",
                      marginTop: "-1px",
                      cursor: "pointer",
                    }}
                    src={crossButton}
                    alt="close"
                    className="logo_img"
                  />
                  <h4
                    className="text-aligns-center createAdminhead"
                    style={{ color: "#5A607F", fontWeight: "bold" }}
                  >
                    Send WhatsApp Message
                  </h4>
                </div>
                <div>
                  <label htmlFor="messageType" className="labels" style={{ marginTop: "13px" }}>
                    Select Message Type
                  </label>
                  <select
                    name="messageType"
                    className="form-select text-secondary border-secondary"
                    value={formik.values.messageType}
                    onChange={handleTemplateChange}
                    onBlur={formik.handleBlur}
                    id="messageType"
                    type="text"
                    style={{ fontSize: "large" }}
                    onClick={handleDropdownClick}
                  >
                    <option disabled selected hidden value="">
                      Select Message Type
                    </option>
                    {showOptions &&
                      names.map((template, id) => (
                        <option
                          key={id}
                          value={template.name}
                          className="form-select"
                          style={{ color: "#5A607F" }}
                        >
                          {template.name.charAt(0).toUpperCase() + template.name.slice(1).toLowerCase()}
                        </option>
                      ))}
                  </select>
                  {formik.errors.messageType && formik.touched.messageType ? (
                    <div className="text-danger fs-6">{formik.errors.messageType}</div>
                  ) : null}


                  <label htmlFor="MessageDescription" className="labels">
                    Message Description
                  </label>
                  <textarea
                    className={
                      formik.touched.MessageDescription
                        ? `form-control ${formik.errors.MessageDescription ? "invalid" : ""}`
                        : "form-control"
                    }
                    id="MessageDescription"
                    placeholder="Description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.MessageDescription}
                    name="MessageDescription"
                    rows="4"
                    style={{ fontSize: "medium" }}
                    disabled
                  ></textarea>

                  <label htmlFor="excelFile" className="labels">
                    Upload Informations
                  </label>
                  <div
                    className="file-field-container my-3 d-flex flex-column justify-content-center align-items-center my-1 my-md-2 w-auto text-center rounded-3 py-1 position-relative form-control"
                    onDrop={handleDrop}
                    style={{ height: "156px", marginTop: "0.1rem", padding: "0" }}
                    onDragOver={handleDragOver}
                  >
                    <img
                      src={dropIcon}
                      style={{ width: "100%" }}
                      height={"53%"}
                      alt=""
                      className=" w-25 mt-md-4"
                    />
                    <label
                      htmlFor="files"
                      className="cursor-pointer d-flex flex-column justify-content-end align-items-center w-100 h-100"
                    >
                      <span>Upload Excel Sheet</span>
                      <span className="fw-bold fs-6">or drop it here</span>
                    </label>
                    <input
                      id="files"
                      className="file-field"
                      style={{ visibility: "hidden", width: "1px", height: "1px" }}
                      name="excelFile"
                      ref={inputFile}
                      accept=".xlsx, .xls, .xlsm, .xlsb, .csv, .xlam, .xltx, .xltm"
                      onChange={(event) => {
                        handleFileChange(event);
                        formik.setFieldValue("excelFile", event.currentTarget.files[0]);
                      }}
                      onBlur={formik.handleBlur}
                      type="file"
                    />
                  </div>
                  {formik.errors.excelFile && formik.touched.excelFile ? (
                    <div className="text-danger fs-6" style={{ marginTop: "-7px", marginBottom: "-3px" }}>
                      {formik.errors.excelFile}
                    </div>
                  ) : null}
                  <div className="my-0 d-flex justify-content-center">
                    {excelFile ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{excelFile.name}</span>
                        <button
                          type="button"
                          onClick={handleRemoveFile}
                          className="fw-bold cursor-pointer ms-2 px-1 border-0 text-danger rounded-circle"
                          title="remove file"
                        >
                          &#x2715;
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <button
                    disabled={downloadLoading}
                    style={{ marginTop: "-10px", marginRight: "5px" }}
                    className="btn btn-outline-dark w-100 btn-md d-block my-3"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDownloadSample();
                    }}
                  >
                    {downloadLoading ? <LoaderButton /> : "Download Sample"}
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    marginTop: "15px",
                  }}
                >
                  <div style={{ display: "flex", width: "100%", marginTop: "-6px", gap: "10px" }}>
                    <button
                      disabled={loading}
                      style={{ height: "39px", flex: "1.1" }}
                      className="submit_btn btn-md text-light font-weight-bold"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();

                        if (!formik.values.excelFile || !formik.values.messageType) {
                          formik.setTouched({
                            ...formik.touched,
                            excelFile: true,
                            messageType: true,
                          });
                        } else {
                          setLoading(true);
                          formik.handleSubmit();
                        }
                      }}
                    >
                      {loading ? <LoaderButton /> : "Send Messages"}
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    fontWeight: "bold",
                    color: "#5A607F",
                    border: "1px solid rgb(255 210 210)",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <strong className="fw-bold fs-6" style={{ margin: 0, fontSize: "14px" }}>
                    ध्यान दें
                  </strong>
                  <p style={{ margin: 0, fontSize: "12px" }}>
                    {`यदि आप एक ऐसे टेम्पलेट का चयन करते हैं जिसमें कुछ पैरामीटर्स होते हैं जैसे {{1}}, {{2}}.... तो आपको एक्सेल शीट में इन पैरामीटर्स के लिए वैल्यूज़ डालनी होंगी।`}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </CModalBody>
      </CModal>
      <CModal
        alignment="center"
        className="custom-success-modal"
        padding="1rem"
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
      >
        <CModalBody className="success-error-model">
          <div className="text-center">
            <img
              src={success_icon}
              style={{ maxWidth: "100%" }}
              height={"53%"}
              alt=""
              className=" w-25 mt-md-4"
            />
            <h5 style={{ color: "#00FF00", fontSize: "1.6rem" }}>Success</h5>
            <p style={{ fontSize: "1.3rem" }}>Messages sent successfully!</p>
            <button
              className="custom-success-button"
              onClick={() => setSuccessModalVisible(false)}
            >
              OK
            </button>
          </div>
        </CModalBody>
      </CModal>
      <CModal
        alignment="center"
        className="custom-error-modal"
        padding="1rem"
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
      >
        <CModalBody className="success-error-model">
          <div className="text-center">
            <img
              src={error_Icon_Green}
              style={{ maxWidth: "60px" }}
              height={"53%"}
              alt=""
              className=" w-25 mt-md-4"
            />
            <h5 style={{ color: "#dc3545", fontSize: "1.6rem" }}>Error</h5>
            <p style={{ fontSize: "1.3rem" }}>Problem occured when sending messages.</p>
            <button
              className="custom-error-button"

              onClick={() => setErrorModalVisible(false)}
            >
              Close
            </button>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
}

export default SendWhatsAppMessagePopop;
