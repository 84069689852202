import { saveAs } from 'file-saver';
import { PDFDocument, rgb } from 'pdf-lib';
import { capitalizeFirstLetter } from './capitalizeFirstLetter';
import fontkit from '@pdf-lib/fontkit'
import fontFamily from '../assets/Roboto-Medium.ttf'
import AllUrl from '../../../../redux/constants/url';
import { showToast } from './toast';

export const DownloadPdf = (level, name, setName, group, knowledge) => {

    const generatePDF = async (name) => {
        try {
            console.log(
                `Getting Certificate data: Name: ${name}, Group: ${group}, Level: ${level}, Knowledge: ${knowledge}`
            );

            // Validate inputs
            if (!group || !level || !name || !knowledge) {
                showToast(
                    "Certification details are incomplete! Please update thier profile to proceed.",
                    "warn"
                );
                return;
            }

            // Fetch the appropriate certificate file
            const certificateFile = await selectCertificateFile(
                group.toLowerCase(),
                level.toLowerCase()
            );
            if (!certificateFile) return;

            // Fetch and embed custom font
            const fontBytes = await fetch(fontFamily).then((res) => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(certificateFile);
            pdfDoc.registerFontkit(fontkit);
            const customFont = await pdfDoc.embedFont(fontBytes);

            const firstPage = pdfDoc.getPages()[0];

            // Calculate text position
            const { x, y } = calculateTextPosition(firstPage, name, customFont);

            // Add text to PDF
            firstPage.drawText(capitalizeFirstLetter(name), {
                x: x + 100,
                y: y - 50,
                size: 30,
                color: rgb(0, 0, 0),
                font: customFont,
            });
            firstPage.drawText(`"Level ${parseInt(level)} Certification"`, {
                x: 415,
                y: 170,
                size: 22,
                color: rgb(0, 0.5, 0.5),
                font: customFont,
            });
            firstPage.drawText(knowledge, {
                x: 545,
                y: 143,
                size: 18,
                color: rgb(0.9, 0.5, 0),
                font: customFont,
            });

            // Save and download the modified PDF
            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: "application/pdf" });
            saveAs(blob, `${name}-Level-${parseInt(level)}-${group.toUpperCase()}.pdf`);
        } catch (error) {
            console.error("Error generating PDF:", error);
        }
    };

    // Function to select the correct certificate file based on group and level
    const selectCertificateFile = async (group, level) => {
        let certificateFile = null;

        if (group === "iteg") {
            if (["1", "1a", "1b", "1c", "a"].includes(level)) {
                certificateFile = await fetchLevelCertificate(AllUrl.certificate_url);
            } else if (["2", "2a", "2b", "2c", "b"].includes(level)) {
                certificateFile = await fetchLevelCertificate(AllUrl.certificate_url);
            } else if (["3", "3a", "3b", "3c", "c"].includes(level)) {
                certificateFile = await fetchLevelCertificate(AllUrl.certificate_url);
            }
        } else {
            showToast(`No Certificates available for group: ${group}`, "warn");
            return;
        }

        return certificateFile;
    };

    // Function to fetch certificate file
    const fetchLevelCertificate = async (url) => {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.arrayBuffer();
    };

    // Function to calculate text position on the PDF
    const calculateTextPosition = (firstPage, name, customFont) => {
        const textWidth = customFont.widthOfTextAtSize(name, 30);
        const middleCharacterIndex = Math.floor(name.length / 2);
        const middleCharacterWidth = customFont.widthOfTextAtSize(
            name[middleCharacterIndex],
            30
        );
        const x =
            (firstPage.getSize().width - textWidth) / 2 + middleCharacterWidth / 2;
        const y = firstPage.getSize().height / 2;

        return { x, y };
    };

    // Trigger the PDF generation when the form is submitted
    const submitHandler = async () => {
        const val = capitalizeFirstLetter(name.trim());
        console.log("value of val capitalize is", val)
        if (val !== "") {
            generatePDF(val);
            showToast("Certificate downloaded successfully!", "success");
        } else {
            console.log("not a valid data name ");
        }
    };
    submitHandler();
}
