
import {jsPDF} from "jspdf";
import QRCode from 'qrcode';

import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import ITEG_BG from '../images/ITEG_S2.jpg';
import BEG_BG from '../images/BEG_S.jpg'
import MEG_BG from '../images/MEG_S.jpg'
import {capitalizeFirstLetter} from '../utils/capitalizeFirstLetter';

export const DownloadStudentIdCardAsZip = (cardsData) => {

    const generateQR = async text => {
        try {
            const opts = {
                errorCorrectionLevel: 'H',
                type: 'image/png',
                quality: 1,
                margin: 0,
                color: {
                    dark: "#000000",
                }
            }
            return await QRCode.toDataURL(JSON.stringify(text),opts);
        } catch(err) {
            console.error(err)
            return null;
        }
    }

    // const findKeyIgnoreCase = (obj,keyName) => {
    //     const lowerKeyName = keyName.toLowerCase();
    //     for(const key in obj) {
    //         if(key.toLowerCase() === lowerKeyName) {
    //             return key;
    //         }
    //     }
    //     return null;
    // };

    const generatePDFs = async () => {
        const zip = new JSZip();
        for(let i = 0; i < cardsData.length; i++) {
            const entry = cardsData[i];
            const QR = await generateQR(entry.adhaar);
            if(QR) {
                const pdf = await generatePDF(QR,entry);
                zip.file(`${entry.name}-${entry.group}.pdf`,pdf);
            }
        }
        zip.generateAsync({type: 'blob'}).then((content) => {
            const date = new Date();
            saveAs(content,`student_cards-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.zip`);
        });
    };

    const generatePDF = async (QR,data) => {
        const doc = new jsPDF('l','mm',[84,54]);
        if(data.group.toLowerCase() === 'iteg') {
            doc.addImage(ITEG_BG,'jpeg',0,0,84,54);
            doc.addImage(QR,'png',64,28.5,16.5,16.5);

        } else if(data.group.toLowerCase() === 'beg') {
            doc.addImage(BEG_BG,'jpeg',0,0,84,54);
            doc.addImage(QR,'png',63.5,29.2,16.5,16.5);
        } else if(data.group.toLowerCase() === 'meg') {
            doc.addImage(MEG_BG,'jpeg',0,0,84,54);
            doc.addImage(QR,'png',63.5,29.2,16.5,16.5);

        }
        doc.setFont(undefined,'bold');
        doc.setFontSize(8);
        doc.text(data.session,45,15);
        doc.setFontSize(7);
        doc.text(capitalizeFirstLetter(data.name),45,19.5);
        doc.text(capitalizeFirstLetter(data.fatherName),45,24);
        doc.text(data.branch.toUpperCase(),45,28.5);
        doc.text(data.mobileNumber.toString(),45,32.5);
        doc.addImage(data.photoData,'jpeg',4,14.5,17.5,21.55);
        return doc.output('blob');
    };

    return generatePDFs();
}
