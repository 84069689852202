import React from "react";
import allUrls from "../../../redux/constants/url";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import axios from "axios";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { accountAction } from "../../../redux/actionDispatcher/superAdmin/studentAccountTableDataDispatcher";
import { useNavigate } from "react-router-dom";
import { isSuperAdmin } from '../../../helpers/SuperAdmin';
import { isAccountAdmin } from '../../../helpers/AccountAdmin';
import { isStudentAdmin } from '../../../helpers/StudentAdmin';
import { LensTwoTone } from "@material-ui/icons";
// import { useNavigate } from 'react-router-dom';

function ActionStudentsProfile({ handleClose, actionType, disable }) {
    const navigate = useNavigate();
  const data = localStorage.getItem("userEdit");
  // console.log(actionType);
  const StudentProfileData = JSON.parse(data);
    const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    option: "",
  };

  const validationSchema = Yup.object({
    option: Yup.string().required("Required"),
  });
  const backToProfilePage = () => {
    if (isStudentAdmin()) {
        navigate('/student_admin_dashboard/');
    }
    else if (isAccountAdmin()) {
        navigate('/account_admin_dashboard/');
    }
    else if (isSuperAdmin()) {
        navigate('/admin_dashboard/');
    }

}

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      // console.log(values);
      const UpdatePersonalInfoData = {
        stdId: StudentProfileData.accountInfo.stdId,
        option: values.option,
        type: actionType,
      };
      // console.log(UpdatePersonalInfoData);
      const token = localStorage.getItem("token");
      var config = {
        method: "get",
        url: `${allUrls.actionOnStudents}/${StudentProfileData.accountInfo.stdId}/${values.option}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: UpdatePersonalInfoData,
      };
      // // console.log(config, UpdatePersonalInfoData)
      Swal.fire({
        title: `Are you sure?`,

        html:
            '<hr>' +
            'Are you sure?' +
            '<br>' +
            `You want to`,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: `Submit`,
        // confirmButtonText:'Deactive',
        reverseButtons: true,
        confirmButtonColor: "red",
        cancelButtonColor: "#E12D2D",
        showLoaderOnDeny: true,
        customClass: {
            popup: 'swal2-popup',
          },


    }).then(async (result) => {
        if (result.isConfirmed) {
            setIsLoading(true);
            let response
            try {
              
           
            response = await axios(config);

            
            // console.log(response);
            if (response?.status === 200) {
              toast.success("Successfully Updated", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              backToProfilePage();
            }  
          } catch (error) {
              toast.warn(error.response.data, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            
            }
    
        }else{
            handleClose()
        }
    })

    },
  });

  const options = [
    { value: 5, label: "Deactive Student" }
  ];
  const options2 = [
    { value: 1, label: "Active Student" },
    { value: 2, label: "Active Student with fail (Year will change by -1)" },
    { value: -1, label: "Active Student with fail (Year will change by -1) And receipts will adjust to activeting year" },
    { value: 3, label: "Active Student with pass (Year will change by +1)" },
    { value: 4, label: "Active Student in same year (New Account / session change)" },
    { value: 0, label: "Shift To Alumini" },
  ];
  const options3 = [
    { value: 6, label: "Shift To Deactive" }
  ];
  // console.log(formik.values);
  return (
    <>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div style={{ borderRadius: "5px" }}>
            <div
              className="d-flex fw-bold text-light p-2"
              style={{
                justifyContent: "center",
                backgroundColor: "orange",
                width: "100%",
                margin: 0,
              }}>
              Actions
            </div>
            <div className="d-flex p-1">
              <div className="row mx-3">
                <div className="form-group">
                  <h3>Select an option:</h3>
                  {actionType === 1 && options.map((ele, index) => {
                    return (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="option"
                          name="option"
                          value={ele.value}
                          checked={`${formik.values.option}` === `${ele.value}`}
                          onChange={formik.handleChange}></input>
                        <label className="form-check-label" htmlFor={ele.value}>
                          {ele.label}
                        </label>
                      </div>
                    );
                  })}
                  {actionType === 2 && options2.map((ele, index) => {
                    return (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="option"
                          name="option"
                          value={ele.value}
                          checked={`${formik.values.option}` === `${ele.value}`}
                          onChange={formik.handleChange}></input>
                        <label className="form-check-label" htmlFor={ele.value}>
                          {ele.label}
                        </label>
                      </div>
                    );
                  })}
                  {actionType === 3 && options3.map((ele, index) => {
                    return (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="option"
                          name="option"
                          value={ele.value}
                          checked={`${formik.values.option}` === `${ele.value}`}
                          onChange={formik.handleChange}></input>
                        <label className="form-check-label" htmlFor={ele.value}>
                          {ele.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
                {formik.errors.option && (
                  <div className="text-danger">{formik.errors.option}</div>
                )}
                <div>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
const mapDispatchToProps = dispatch => {
  return {
    accountAction: (config, navigate, is_reciptBtn, setLoading) =>
      dispatch(accountAction(config, navigate, is_reciptBtn, setLoading)),
  };
};

export default connect(null, mapDispatchToProps)(ActionStudentsProfile);
