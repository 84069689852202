/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import allUrls from "../../../redux/constants/url";
import axios from "axios";
// import { Tooltip, Whisper } from "rsuite";
import "../styles/dashboard.css";
import RegistrationsBarChart from "./RegistrationsBarChart";
// import personIcon from "../../assests/image/dashboard/personIcon.svg";
// import rupee from "../../assests/image/dashboard/rupee.svg";
// import green from "../../assests/image/dashboard/green.svg";
// import red from "../../assests/image/dashboard/red.svg";
// import yello from "../../assests/image/dashboard/yello.svg";
// import dateIcon from "../../assests/image/AccountIcons/DateIcon.svg";
import eyeIcon from "../../assests/image/eyeIcon.svg";
import { isSuperAdmin } from "../../../helpers/SuperAdmin";
import { isAccountAdmin } from "../../../helpers/AccountAdmin";
import { isStudentAdmin } from "../../../helpers/StudentAdmin";
import storage from "../../../helpers/Crypto";

function RegistrationsDashboard() {
    const navigate = useNavigate();
    let permissions = JSON.parse(storage.getItem("permissions"))
    const [data, setData] = useState({});
    const [toggle, setToggle] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                var con = {
                    method: "GET",
                    url: allUrls.registrationsDashboard,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                };
                const dashboardRevenue = await axios(con);
                // console.log("Revenue data is ",dashboardRevenue)
                if (dashboardRevenue.status === 200) {
                    // let d = dashboardRevenue.data;
                    // d.branches = [...d.branches,...d.branches];
                    if(permissions.tracks.includes("All")) {
                        setData(dashboardRevenue.data);
                    } else {
                        let data = {...dashboardRevenue.data};
                        data.tracks = dashboardRevenue.data.tracks.filter(value => {
                            return permissions.tracks.includes(value.trackName);
                        });
                        setData(data);
                    }
                }
            } catch (error) {
                console.log("error to fetch dashboard revenue : ", error);
            }
        };

        fetchData();
    }, []);

    console.log("data", data)

    return (
        <div className="main-dashboard container-fluid">
            <div className="row g-2">
                {(isSuperAdmin() || isAccountAdmin() || isStudentAdmin()) &&
                    <>
                        <div className="col-12 mt-4">
                            <div className=" dashboardCard ">
                                <div className="d-flex justify-content-center">

                                    <div className="d-flex" style={{ overflowX: "scroll", width: "1500px" }}>
                                        {
                                            data.branches && data.branches.map((item, index) => {
                                                return (
                                                    <div className=" dash_bar_div" style={{ minWidth: "180px" }} >
                                                        <div className="dash_bar2 d-flex align-items-center">
                                                            <div>
                                                                <div className="container ">
                                                                    <div className="row">
                                                                        <div className="col-12 dash_title2 d-flex justify-content-center my-1">
                                                                            <div className="text-truncate" style={{ fontSize: "15px" }}>
                                                                                {item.branch}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div className="d-flex align-items-center justify-content-center" style={{ padding: "1px", borderRadius: "5px", backgroundColor: "#2A60D91A" }}>
                                                                                <div style={{ color: "#2A60D9", fontSize: "13px", fontWeight: "bold", margin: "1px" }}>{parseInt(item.unpaid_count) + parseInt(item.paid_count)} </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div className="d-flex align-items-center justify-content-center" style={{ padding: "1px", borderRadius: "5px", backgroundColor: "#BC22201A" }}>
                                                                                <div style={{ color: "#BC2220", fontSize: "13px", fontWeight: "bold", margin: "1px" }}> {parseInt(item.unpaid_count)} </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div className="d-flex align-items-center justify-content-center" style={{ padding: "1px", borderRadius: "5px", backgroundColor: "#02AF2E1A" }}>
                                                                                <div style={{ color: "#02AF2E", fontSize: "13px", fontWeight: "bold", margin: "1px" }}>{parseInt(item.paid_count)} </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">

                                                                        <div className="col-4">
                                                                            <div >Total </div>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div >Unpaid </div>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div >Paid </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-2">
                            <input type="checkbox" checked={toggle} onChange={(e) => setToggle(e.target.checked)} /> Show Graph
                        </div>

                        {data.tracks && data.tracks.map((track, index) => {
                            if (toggle) {

                                return <>
                                    <div className="col-6  rounded">
                                        <div className=" dashboardCard dashboardCardGraph ">
                                            <label htmlFor="" className='card_label_text'>{track.trackName}</label>
                                            <RegistrationsBarChart instData={track.branches} index={index} />
                                        </div>
                                    </div>
                                </>
                            }
                            else {
                                return <>
                                    <div className="col-12 mt-4">
                                        <div className=" dashboardCard ">
                                            <div className="d-flex justify-content-center">

                                                <div className="d-flex" style={{ overflowX: "scroll", width: "1500px" }}>
                                                    {
                                                        track.branches && track.branches.map((item, index) => {
                                                            return (
                                                                <div className=" dash_bar_div" style={{ minWidth: "180px" }} >
                                                                    <div className="dash_bar2 d-flex align-items-center">
                                                                        <div>
                                                                            <div className="container ">
                                                                                <div className="row">
                                                                                    <div className={`${item.branch === "Total" ? "col-6" : "col-12"} dash_title2 d-flex justify-content-center my-1`}>
                                                                                        <div className="text-truncate" style={{ fontSize: "15px" }}>
                                                                                            {item.branch === "Total" ? track.trackName : item.branch}
                                                                                        </div>
                                                                                    </div>
                                                                                    {item.branch === "Total" ?
                                                                                        <div className="col-6 text-center "> <img id="show-icon" src={eyeIcon} alt="eye icon"
                                                                                            onClick={() => {
                                                                                                if(isStudentAdmin()) {
                                                                                                    navigate(`/student_admin_dashboard/selfappliedandapliedstudent/${track.trackName}`)
                                                                                                } if(isSuperAdmin()) {
                                                                                                    navigate(`/admin_dashboard/selfappliedandapliedstudent/${track.trackName}`)
                                                                                                } if(isAccountAdmin()) {
                                                                                                    navigate(`/account_admin_dashboard/selfappliedandapliedstudent/${track.trackName}`)
                                                                                                }
                                                                                            }} /></div> : null}
                                                                                    <div className="col-4">
                                                                                        <div className="d-flex align-items-center justify-content-center" style={{ padding: "1px", borderRadius: "5px", backgroundColor: "#2A60D91A" }}>
                                                                                            <div style={{ color: "#2A60D9", fontSize: "13px", fontWeight: "bold", margin: "1px" }}>{parseInt(item.unpaid_count) + parseInt(item.paid_count)} </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <div className="d-flex align-items-center justify-content-center" style={{ padding: "1px", borderRadius: "5px", backgroundColor: "#BC22201A" }}>
                                                                                            <div style={{ color: "#BC2220", fontSize: "13px", fontWeight: "bold", margin: "1px" }}> {parseInt(item.unpaid_count)} </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <div className="d-flex align-items-center justify-content-center" style={{ padding: "1px", borderRadius: "5px", backgroundColor: "#02AF2E1A" }}>
                                                                                            <div style={{ color: "#02AF2E", fontSize: "13px", fontWeight: "bold", margin: "1px" }}>{parseInt(item.paid_count)} </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">

                                                                                    <div className="col-4">
                                                                                        <div >Total </div>
                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <div >Unpaid </div>
                                                                                    </div>
                                                                                    <div className="col-4">
                                                                                        <div >Paid </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }


                        })}





                    </>
                }


            </div>
        </div>
    );
}

export default RegistrationsDashboard;
