import { useEffect } from "react";
import { Link } from "react-router-dom";
import img1 from "../assests/site-images/1.jpg";
import img2 from "../assests/site-images/2.jpg";
import img3 from "../assests/site-images/3.jpg";
import img4 from "../assests/site-images/4.jpg";
import img5 from "../assests/site-images/5.jpg";
import img6 from "../assests/site-images/6.jpg";

const SsismIndex = () => {
    useEffect(() => {

        // Scroll event handler to add or remove the 'navbar-fixed-top' class
        const handleScroll = () => {
            const navbar = document.querySelector(".navbar-default");
            if (window.scrollY > 670) {
                navbar.classList.add("navbar-fixed-top");
            } else {
                navbar.classList.remove("navbar-fixed-top");
            }
        };

        // Attach the scroll event listener
        window.addEventListener("scroll", handleScroll);

        // List of script sources
        const scripts = [
            "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2&appId=947009608659338&autoLogAppEvents=1",
            "https://ssism.org/js/jquery-1.11.1.js",
            "https://ssism.org/js/modernizr-2.6.2.min.js",
            "https://ssism.org/js/bootstrap.min.js",
            "https://ssism.org/js/rs-plugin/js/jquery.themepunch.plugins.min.js",
            "https://ssism.org/js/rs-plugin/js/jquery.themepunch.revolution.js",
            "https://ssism.org/js/jquery.shuffle.min.js",
            "https://ssism.org/js/mmenu/js/jquery.mmenu.min.js",
            "https://ssism.org/js/owl/js/owl.carousel.min.js",
            "https://ssism.org/js/wow.min.js",
            "https://cdnjs.cloudflare.com/ajax/libs/waypoints/2.0.3/waypoints.min.js",
            "https://ssism.org/js/jquery.counterup.min.js",
            "https://ssism.org/js/jquery.easing.min.js",
            "https://ssism.org/js/scrolling-nav.js",
            "https://ssism.org/js/smoothscroll.min.js",
            "https://ssism.org/js/script.js",
            "https://ssism.org/js/email.js",
        ];

        // Function to dynamically load a script
        const loadScript = (src, async = true, defer = false, crossorigin = null) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement("script");
                script.src = src;
                script.async = async;
                script.defer = defer;
                if (crossorigin) script.crossOrigin = crossorigin;
                script.onload = () => resolve();
                script.onerror = () => reject();
                document.body.appendChild(script);
            });
        };

        // Load each script sequentially
        scripts.reduce(
            (promise, src) => promise.then(() => loadScript(src)),
            Promise.resolve()
        ).catch(error => console.error("Failed to load a script:", error));

        // Cleanup: Remove scripts on unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
            scripts.forEach(src => {
                const script = document.querySelector(`script[src="${src}"]`);
                if (script) document.body.removeChild(script);
            });
        };
    }, []);
    return (
        <div>
            {/* <style dangerouslySetInnerHTML={{__html: "\n                .image-container {\n                    position: relative;\n    }\n                .donate-btn {\n                    position: absolute;\n                top: 90%;\n                left: 90%;\n                transform: translate(-50%, -50%);\n    }\n            " }} /> */}
            <style dangerouslySetInnerHTML={{ __html: "\n.image-container {\n  position: relative;\n}\n\n.donate-btn {\n  position: absolute;\n  top: 90%; \n  left: 90%;\n  transform: translate(-50%, -50%);\n  color: white !important;\n  padding: 10px 20px;\n  font-size: 1.2rem;\n}\n\n@media (max-width: 576px) {\n  /* Adjust button size and position on mobile screens */\n  .donate-btn {\n    padding: 1px 0px;\n top: 85%;\n    left: 80%;\n    transform: translate(-50%, -50%);\n    font-size: 1rem;\n  }\n}\n" }} />

            <html class="no-js">
                <head>
                    <meta charSet="utf-8" />
                    <title>SSISM</title>
                    <meta name="description" content />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <link rel="shortcut icon" type="image/png" href="https://ssism.org/jsimg/webimg/favicon.png" />
                    <link rel="stylesheet" type="text/css" href="https://ssism.org/css/vendor/settings.css" media="screen" />
                    <link rel="stylesheet" href="https://ssism.org/css/vendor/extralayer.css" />
                    <link rel="stylesheet" href="https://ssism.org/css/vendor/flaticon.css" />
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
                    <link rel="stylesheet" href="https://ssism.org/css/vendor/owl.carousel.css" />
                    <link rel="stylesheet" href="https://ssism.org/css/vendor/owl.theme.default.css" />
                    <link rel="stylesheet" href="https://ssism.org/css/vendor/owl.theme.css" />
                    <link type="text/css" rel="stylesheet" href="https://ssism.org/css/vendor/jquery.mmenu.css" />
                    <link rel="stylesheet" href="https://ssism.org/css/vendor/bootstrap.css" />
                    <link href="https://fonts.googleapis.com/css?family=Roboto+Condensed:300italic,400italic,700italic,400,300,700" rel="stylesheet" type="text/css" />
                    <link href="https://fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,400,300,600,700" rel="stylesheet" type="text/css" />
                    <link rel="stylesheet" href="https://ssism.org/css/vendor/animate.css" />
                    <link rel="stylesheet" href="https://ssism.org/css/vendor/hover.css" />
                    <link rel="stylesheet" href="https://ssism.org/css/vendor/style.css" />
                    <link rel="stylesheet" href="https://ssism.org/css/vendor/responsive.css" />
                    <style dangerouslySetInnerHTML={{ __html: "\n@media  only screen and (max-width: 480px) {\n  .logoclass \n  {\n    margin-top: -15px;\n  }\n}\n@media  only screen and (min-width: 481px) {\n  .logoclass \n  {\n    margin-top: -22px;\n  }\n}\n" }} />
                    <style dangerouslySetInnerHTML={{ __html: "\n    .images{display: flex;flex-wrap: wrap;   border: 2px solid #ccc;}\n    .slot{width: 50%;}\n    img{display: block;width: 100%;height: auto;}\n  " }} />
                </head>

                <body>
                    <div id="fb-root"></div>

                    {/* <div class="loader"></div> */}
                    <header className="header-part">
                        <div id="home" className="wrapper">
                            {/* Fixed navbar */}
                            <div className="navi navbar-default" role="navigation">
                                <div className="container">
                                    <div className="navbar-header page-scroll">
                                        <a href="#menu">
                                            <button type="button" data-effect="st-effect-1" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                                                <span className="sr-only">Toggle navigation</span>
                                                <span className="icon-bar" />
                                                <span className="icon-bar" />
                                                <span className="icon-bar" />
                                            </button>
                                        </a>
                                        <a className="navbar-brand" href="https://central.ssism.org">
                                            <img src="https://ssism.org/img/webimg/logo.png" alt="SSISM" className="logoclass" />
                                        </a>
                                    </div>
                                    <div id="navbar" className="navbar-collapse collapse pull-right hidden-xs nav navbar-nav navbar-right ">
                                        <ul className="d-flex">
                                            {/* <li className="dropdown">
                            <a className="page-scroll drop dropdown" role="button" aria-expanded="false" href="#">
                                <b>About Us</b>
                            </a>
                            <ul className="dropdown-menu" role="menu">
                                <li><a href="https://ssism.org/pages/whoweare.html"><b>About Us</b></a></li>
                                <li><a href="https://ssism.org/pages/advisorsboard.html"><b>Our Advisors &amp; Board</b></a></li>
                                <li><a href="https://ssism.org/pages/partnerssupporters.html"><b>Partners &amp; Supporters</b></a></li>
                            </ul>
                        </li>
                        <li>
                            <a className="page-scroll" href="https://ssism.org/pages/trainingsplacements.html">
                                <b>Placements &amp; Trainings</b>
                            </a>
                        </li>
                        <li className="dropdown">
                            <a className="page-scroll drop dropdown" role="button" aria-expanded="false" href="#">
                                <b>What We do</b>
                            </a>
                            <ul className="dropdown-menu" role="menu">
                                <li><a href="https://ssism.org/pages/visionmission.html"><b>Vision &amp; Mission</b></a></li>
                                <li><a href="https://ssism.org/pages/methodology.html"><b>Methodology</b></a></li>
                                <li><a href="https://ssism.org/pages/academics.html"><b>Academics</b></a></li>
                                <li><a href="https://ssism.org/pages/storiesofempowerment.html"><b>Stories of Empowerment</b></a></li>
                                <li><a href="https://ssism.org/pages/singajipremierleague.html"><b>Singaji Premier League</b></a></li>
                                <li><a href="https://ssism.org/pages/computereducation.html"><b>Computer Education</b></a></li>
                            </ul>
                        </li>
                        <li className="dropdown">
                            <a className="page-scroll drop dropdown" role="button" aria-expanded="false" href="#">
                                <b>News &amp; Media</b>
                            </a>
                            <ul className="dropdown-menu" role="menu">
                                <li><a href="https://ssism.org/pages/publishedarticles.html"><b>Published Articles</b></a></li>
                                <li><a href="https://ssism.org/pages/videos.html"><b>Videos</b></a></li>
                                <li><a href="https://ssism.org/img/webimg/certificates/Singaji_Educational_Society_2019.pdf" target="_blank"><b>News Letter (2018-19)</b></a></li>
                                <li><a href="https://ssism.org/pages/infographic.html" target="_blank"><b>Info-graphic</b></a></li>
                            </ul>
                        </li>
                        <li className="dropdown">
                            <a className="page-scroll drop dropdown" role="button" aria-expanded="false" href="#">
                                <b>Get Involved</b>
                            </a>
                            <ul className="dropdown-menu" role="menu">
                                <li><a href="https://ssism.org/pages/careers.html"><b>Careers</b></a></li>
                                <li><a href="https://ssism.org/pages/volunteerpositions.html"><b>Volunteer</b></a></li>
                            </ul>
                        </li>
                        <li>
                            <a className="page-scroll btn.btn-default" href="https://ssism.org/pages/sns-svs.html">
                                <b>SNS-SVS</b>
                            </a>
                        </li>
                        <li>
                            <a className="page-scroll btn.btn-default" href="https://ssism.org/pages/iteg.html">
                                <b>ITEG</b>
                            </a>
                        </li>
                        <li>
                            <a className="page-scroll btn.btn-default" href="https://ssism.org/pages/meg.html">
                                <b>MEG</b>
                            </a>
                        </li>
                        <li>
                            <a className="page-scroll btn.btn-default" href="https://ssism.org/pages/beg.html">
                                <b>BEG</b>
                            </a>
                        </li> */}
                                            {/* https://forms.gle/tTmvjzFx6pvAhJhXA */}
                                            {/* <li >
<a class="page-scroll btn btn-warning" href="https://give.do/donate/LC65dc838ae4c16" style=" padding-top: 2px; margin-top: -3px; ">
<b>Contribute</b>
</a>
</li>      */}
                                            {/* <li className="mx-1">
                                                <a className="page-scroll btn btn-warning" href="https://ssism.org" target="_blank" style={{ paddingTop: 2, marginTop: '-3px' }}>
                                                    <b>SSISM</b>
                                                </a>
                                            </li> */}
                                            <li className="mx-1">
                                                <a className="page-scroll btn btn-warning" href="https://central.ssism.org/payment" target="_blank" style={{ paddingTop: 2, marginTop: '-3px' }}>
                                                    <b>Donate</b>
                                                </a>
                                            </li>
                                            <li className="mx-1">
                                                <a className="page-scroll btn btn-warning" href="https://central.ssism.org/login" target="" style={{ paddingTop: 2, marginTop: '-3px' }}>
                                                    <b>Login</b>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>{/*/.nav-collapse */}
                                </div>
                            </div>{/* End of Nav */}
                        </div>
                    </header>
                    <div className="d-flex flex-column" >

                        <div className="container-fluid">
                            <div className="row">
                                {[img1, img2, img3, img4, img5, img6].map((image, index) => (
                                    <div className="col-12 mb-3" key={index}>
                                        <img
                                            src={image}
                                            alt={`Image ${index + 1}`}
                                            className="img-fluid w-100 rounded"
                                        />

                                        {index === 0 && <button class="btn btn-primary donate-btn"> <Link style={{ color: 'white' }} to="/payment" target="_blank">Donate</Link> </button>}
                                        {index === 3 && <button class="btn btn-primary donate-btn"> <Link style={{ color: 'white' }} to="/payment" target="_blank">Donate</Link> </button>}
                                    </div>
                                ))}
                            </div>
                        </div>



                        <footer className="pt-4">
                            <div className="footer-wrapper section-padding">
                                <div className="container">
                                    <div className="row">
                                        <div className="wow zoomIn col-xs-12 col-sm-6 col-md-3 animated" style={{ visibility: 'visible', animationName: 'zoomIn' }}>
                                            <p className="footer-heading">About Us</p>
                                            <ul className="footermenu">
                                                <li><a href="/aboutUs">Who We Are</a></li>
                                            </ul>
                                        </div>

                                        <div className="wow zoomIn col-xs-12 col-sm-6 col-md-3 animated" style={{ visibility: 'visible', animationName: 'zoomIn' }}>
                                            <p className="footer-heading">Other Link</p>
                                            <ul className="footermenu">
                                                <li><Link to="/termsConditions">Terms And Conditions</Link></li>
                                                <li><Link to="/privacy">Privacy policy</Link></li>
                                                <li><Link to="/refundsCancellations">Refunds/Cancellations</Link></li>
                                            </ul>
                                        </div>
                                        <div className="wow zoomIn col-xs-12 col-sm-6 col-md-3 animated" style={{ visibility: 'visible', animationName: 'zoomIn' }}>
                                            <p className="footer-heading">find us</p>
                                            <ul className="footercontact">
                                                <li><i className="flaticon-phone16" /><span>phone:</span><a href="tel:91 9893371756"> +91 9893371756</a></li>
                                                <li><i className="flaticon-email21" /><span>e-mail:</span><a href="mailto:contact@ssism.org"> contact@ssism.org</a></li>
                                                <li><i className="flaticon-world91" /><span>web:</span><a href="http://ssism.org/"> www.ssism.org</a></li>
                                            </ul>
                                            <i className="flaticon-home78" />
                                        </div>
                                        <div className="wow zoomIn col-xs-12 col-sm-6 col-md-12 animated" style={{ visibility: 'visible', animationName: 'zoomIn' }}>
                                            <ul className="footercontact">
                                                <li><i className="flaticon-mainpage" /><span>address:</span>   NH 59-A,Gram-Sandalpur, Tehsil Khategaon, District Dewas, MP, India, Pin Code – 455336 </li>
                                            </ul>
                                            <i className="flaticon-home78" />
                                        </div>
                                    </div> {/* /.row */}
                                </div> {/* /.container */}
                            </div>
                            <div className="footer-bottom">
                                <div className="container">
                                    <div className="row">
                                        <div className="wow zoomIn col-xs-12 animated" style={{ visibility: 'visible', animationName: 'zoomIn' }}>
                                            <p>© 2024 All rights reserved. <span>SSISM</span>  by <a href="https://singaji.in">Singaji Software Solutions</a></p>
                                            <div className="backtop  pull-right">
                                                <i className="fa fa-angle-up back-to-top" style={{ display: 'none' }} />
                                            </div>{/* /.backtop */}
                                        </div>{/* /.col-xs-12 */}
                                    </div>{/* /.row */}
                                </div>{/* /.container */}
                            </div>{/* /.creditwrapper */}
                        </footer>

                        {/* /Footer */}
                        {/* MMENU */}

                    </div>{/* /.main */}





                </body>
            </html>
        </div>
    )
}

export default SsismIndex;