import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useEffect, useState } from "react";
import "../styles/dashboard.css";
// import { Line } from 'react-chartjs-2';

function Dashboard({ divName, attData, group, color }) {
  const [div, setDiv] = useState(divName);
  // const [fistRender, setFirstRender] = useState(false);
  
  
  useEffect( () => {
    // setDiv(`${divName}${Math.random()*10}`)


    // if (attData && !fistRender) {
    //   setFirstRender(true)
    // console.log(attData);
    // console.log(div);
    let   root = am5.Root.new(div);
    root._licenseApplied();
   
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    let chart = root.container.children.push(am5radar.RadarChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      innerRadius: am5.percent(35),
      startAngle: -90,
      endAngle: 180
    }));

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
    let cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {
      behavior: "zoomX"
    }));

    cursor.lineY.set("visible", false);

    // Create axes and their renderers
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
    let xRenderer = am5radar.AxisRendererCircular.new(root, {
      //minGridDistance: 50
    });

    xRenderer.labels.template.setAll({
      radius: 10
    });

    xRenderer.grid.template.setAll({
      forceHidden: true
    });

    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: xRenderer,
      min: 0,
      max: 100,
      strictMinMax: true,
      numberFormat: "''",
      // tooltip: am5.Tooltip.new(root, {})
    }));


    let yRenderer = am5radar.AxisRendererRadial.new(root, {
      minGridDistance: 2
    });

    yRenderer.labels.template.setAll({
      centerX: am5.p100,
      fontWeight: "700",
      fontSize: 10,
      templateField: "columnSettings"
    });

    yRenderer.grid.template.setAll({
      forceHidden: true
    });

    let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: yRenderer
    }));

    const getPercentage = (year) => {
      if (attData.filter(el => el.branch === group && el.year === year)[0]?.attendancePercent) {
        return parseFloat(attData.filter(el => el.branch === group && el.year === year)[0].attendancePercent)
      }
      return 0;


    }

    // Data
    let data = [{
      category: "I Year" + " : "+getPercentage("I")+"%",
      value: getPercentage("I"),
      full: 100,
      columnSettings: {
        fill: color
      }
    }, {
      category: "II Year" + " : "+getPercentage("II")+"%",
      value: getPercentage("II"),
      full: 100,
      columnSettings: {
        fill: color
      }
    }, {
      category: "III Year" + " : "+getPercentage("III")+"%",
      value: getPercentage("III"),
      full: 100,
      columnSettings: {
        fill: color
      }
    }
    ];
    // console.log(data);

    yAxis.data.setAll(data);


    // Create series
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
    let series1 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      clustered: false,
      valueXField: "full",
      categoryYField: "category",
      fill: root.interfaceColors.get("alternativeBackground")
    }));

    series1.columns.template.setAll({
      width: am5.p100,
      fillOpacity: 0.08,
      strokeOpacity: 0,
      cornerRadius: 20,

    });

    series1.data.setAll(data);


    let series2 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      clustered: false,
      valueXField: "value",
      categoryYField: "category"
    }));

    series2.columns.template.setAll({
      width: am5.p100,
      strokeOpacity: 0,
      tooltipText: "{category}",
      cornerRadius: 20,
      templateField: "columnSettings"
    });

    series2.data.setAll(data);


    // var tooltip = series2.set("tooltip", am5.Tooltip.new(root, {
    //   getFillFromSprite: false,
    //   getStrokeFromSprite: true,
    //   autoTextColor: false,
    //   pointerOrientation: "horizontal"
    // }));

    // tooltip.get("background").setAll({
    //   fill: am5.color(0xffffff)
    // })

    // tooltip.label.setAll({
    //   text: "[bold]{category}[/]",
    //   fill: am5.color(0x000000)
    // });

    // tooltip.label.adapters.add("text", function(text, target) {

    //     text += '\n[' + series2.get("category") + ']●[/] [bold width:8  0px]' + series2.get("valueXField") + ':[/] {' + series2.get("valueXField") + '}'

    //   return text
    // })

    // Animate chart and series in
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    series1.appear(1000);
    series2.appear(1000);
    chart.appear(1000, 100);


    // } else {
    //   alert('update');
    //   // setDiv(divName + Math.random() * 100);
    //   // document.getElementById(div).remove();

    // }
    return () => {
      root.dispose();
      // console.log('unmount'+divName)
    }
  }, [divName, attData]);

  return (
    //   <div id="attedanceIteg" style={{ width: "100%", height: "276px" }}></div>
    <div id={div} style={{ width: "100%", height: "140px" }}></div>

  );
}

export default Dashboard;
