import React from 'react'
import allUrls from '../../redux/constants/url'
import { useFormik } from 'formik'
import axios from 'axios'
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';


function AddAttendance({ handleClose }) {




    useEffect(() => {
        const getData = async () => {

       
        }
        getData();
    }, []);

    const initialValues = {
        fromDate: "",
        toDate: "",
        remark:""
       
    }

    const validationSchema = Yup.object({

        fromDate: Yup.string().trim().required("Required!"),
        toDate: Yup.string().trim().required("Required!"),
        remark:Yup.string().required("Required!")
        

    })


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            // console.log(values);
            const data = {
                "dateFrom": formik.values.fromDate,
                "dateTo": formik.values.toDate,
                "reason":formik.values.remark
            }
            // {
            //     dateFrom: `${date.a.getFullYear()}-${(parseInt(date.a.getMonth()) >= 9) ? "" : "0"}${date.a.getMonth() + 1}-${(parseInt(date.a.getDate()) >= 9) ? "": "0" }${date.a.getDate()}`,
            //     dateTo: `${date.b.getFullYear()}-${(parseInt(date.b.getMonth()) >= 9) ? "" : "0"}${date.b.getMonth() + 1}-${(parseInt(date.b.getDate()) >= 9) ? "": "0" }${date.b.getDate()}`
            // }
            
        const token = localStorage.getItem("token");
            var con = {
                method: "GET",
                url: allUrls.addHoliday,
                params: data,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };
            const response = await axios(con)
            // console.log(response);
            if (response.status === 200) {
                toast.success('Holiday Added Successfully', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(handleClose(), 2000)
                setTimeout(window.location.reload(), 1000)
            }

        },

    });

    return (
        <>

            <div>
                <form onSubmit={formik.handleSubmit}>


                    <div style={{ borderRadius: '5px' }}>

                        <div className='d-flex fw-bold text-light p-2' style={{
                            justifyContent: 'center',
                            backgroundColor: 'orange', width: '100%', margin: 0
                        }}>Add Holiday</div>
                        <div className="d-flex p-1">
                            <div className="row">
                                <div className='row m-1'>
                                <div className="col">
                                        <label className="addStdLable" htmlFor="">fromDate</label>  <input
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.fromDate}
                                            name="fromDate"
                                            type="date"
                                            className={formik.touched.fromDate ? `form-control ${formik.errors.fromDate ? "invalid" : ""}` : 'form-control'}
                                            placeholder="fromDate"
                                        />
                                        {formik.errors.fromDate && formik.touched.fromDate ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.fromDate}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col">
                                        <label className="addStdLable" htmlFor="">toDate</label>  <input
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.toDate}
                                            name="toDate"
                                            type="date"
                                            className={formik.touched.toDate ? `form-control ${formik.errors.toDate ? "invalid" : ""}` : 'form-control'}
                                            placeholder="toDate"
                                        />
                                        {formik.errors.toDate && formik.touched.toDate ? (
                                            <div className="text-danger fs-6">
                                                {formik.errors.toDate}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                          
                                  
                                </div>
                                <div className='row m-1'>
                                <div className="col">
                                <label className="addStdLable" htmlFor="">Holiday Description</label>  <textarea name="remark"
                                    class="form-control" id="exampleFormControlTextarea1 remark"
                                    placeholder="Holiday description"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} value={formik.values.remark} rows="3"></textarea>
                                {formik.errors.remark && formik.touched.remark ? (
                                    <div className="text-danger fs-6">
                                        {formik.errors.remark}
                                    </div>
                                ) : (
                                    ""
                                )}


                                 </div>
                                 </div>
                               
                                {/* Second Four Input Field */}
                              
                                <div className="d-flex justify-content-end mt-3 m-1">
                                    <div className='me-3'>
                                        <button style={{ width: '116px', height: "41px" }} type='button' onClick={handleClose} className='btn  btn-light m-2'>Cancel</button>
                                        <button style={{ width: '153px', height: '41px' }} type="submit" onSubmit={formik.handleSubmit} className='btn  btn-primary ml-2' >Submit</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </>
    )
}


export default AddAttendance;
