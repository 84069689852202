
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

export const TeacherIDCardExcelDownload = () => {
    const teacherData = [
        {
            name: 'John Doe staff',
            category: 'staff',
            position: 'teacher',
            email: 'john.doe@example.com',
            mobileNumber: '1234567890',
            adhaar: '1234 5678 9012',
            photodata: 'base64_encoded_image_data'
        },
        {
            name: 'Jane Smith sub staff',
            category: 'sub_staff',
            position: 'teacher',
            email: 'jane.smith@example.com',
            mobileNumber: '9876543210',
            adhaar: '9876-5432-1098',
            photodata: 'base64_encoded_image_data'
        },{
            name: 'John Doe iteg',
            category: 'iteg',
            position: 'teacher',
            email: 'john.doe@example.com',
            mobileNumber: '1234567890',
            adhaar: '1234 5678 9012',
            photodata: 'base64_encoded_image_data'
        },
        {
            name: 'Jane Smith beg',
            category: 'beg',
            position: 'teacher',
            email: 'jane.smith@example.com',
            mobileNumber: '9876543210',
            adhaar: '9876-5432-1098',
            photodata: 'base64_encoded_image_data'
        },{
            name: 'John Doe meg',
            category: 'meg',
            position: 'teacher',
            email: 'john.doe@example.com',
            mobileNumber: '1234567890',
            adhaar: '1234 5678 9012',
            photodata: 'base64_encoded_image_data'
        },
        {
            name: 'Jane Smith teacher',
            category: 'teacher',
            position: 'teacher',
            email: 'jane.smith@example.com',
            mobileNumber: '9876543210',
            adhaar: '9876-5432-1098',
            photodata: 'base64_encoded_image_data'
        }
        // Add more teacher data as needed
    ];

    const worksheet = XLSX.utils.json_to_sheet(teacherData,{
        header: ['name','category','position','email','mobileNumber','adhaar','photodata'],
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook,worksheet,'Teacher ID Cards');
    const excelBuffer = XLSX.write(workbook,{
        bookType: 'xlsx',
        type: 'array',
    });
    const fileName = 'teacher_id_cards.xlsx';
    const blob = new Blob([excelBuffer],{type: 'application/octet-stream'});
    FileSaver.saveAs(blob,fileName);

};

