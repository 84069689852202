import {
    DEPOSIT_AMOUNT_TABLE_FAIL,
    DEPOSIT_AMOUNT_TABLE_SUCCESS,
    DEPOSIT_AMOUNT_TABLE_REQUEST
} from '../../constants/actions'



const initialState = {
    loading: false,
    table_data: [],
    error: false,
};

const depositAmountTable = (state = initialState, action) => {
    switch (action.type) {
        case DEPOSIT_AMOUNT_TABLE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DEPOSIT_AMOUNT_TABLE_FAIL:
            return {
                ...state,
                loading: false,
                error: true
            };
        case DEPOSIT_AMOUNT_TABLE_SUCCESS:
            return {
                error: false,
                loading: false,
                table_data: action.payload,
            };

        default:
            return state;
    }
}

export default depositAmountTable;