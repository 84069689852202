import { CFormCheck, CModal, CModalBody } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import crossButton from "../assests/image/crossButton.svg";
import "./styles/Notification.css";
import { sendNotification } from "../../redux/actionDispatcher/superAdmin/sendNotificationDispatcher";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../assests/common/LoaderButton";
import AllUrl from "../../redux/constants/url";
import { connect } from "react-redux";
import axios from "axios";

function NotificationPopup({ notificationData, sendNotification }) {
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);

  // Get initial data 

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        AllUrl.getNotification,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const settingData = res.data.settingData ? JSON.parse(res.data.settingData) :{};
      formik.setValues({
        startNotificationBeforeDays: settingData.startNotificationBeforeDays || "",
        sentNotificationIntervalDays: settingData.sentNotificationIntervalDays || "",
        startNotificationAfterDays: settingData.startNotificationAfterDays || "",
        sentNotificationIntervalDaysAfter: settingData.sentNotificationIntervalDaysAfter || "",
        installment: settingData.installment || [],
        postInputValue: settingData.postInputValue || "",
        gkbInputValue: settingData.gkbInputValue || "",
      });
    };
    fetchData();
  }, []);

  // Yup validation schema
  const validationSchema = Yup.object().shape({
    startNotificationBeforeDays: Yup.number()
      .typeError("Please enter a valid number")
      .required("Start Notification Before Days is required"),
    sentNotificationIntervalDays: Yup.number()
      .typeError("Please enter a valid number")
      .required("Sent Notification Interval Days is required"),
    startNotificationAfterDays: Yup.number()
      .typeError("Please enter a valid number")
      .required("Start Notification After Days is required"),
    sentNotificationIntervalDaysAfter: Yup.number()
      .typeError("Please enter a valid number")
      .required("Sent Notification Interval Days After is required"),
    installment: Yup.array()
      .min(1, "At least one installment must be selected")
      .required("At least one installment must be selected"),
    postInputValue: Yup.string().when("installment", {
      is: (val) => val.includes("post"),
      then: Yup.string()
        .typeError("Please enter a valid date")
        .required("This field is required when Post is selected"),
    }),
    gkbInputValue: Yup.string().when("installment", {
      is: (val) => val.includes("gkb"),
      then: Yup.string()
        .typeError("Please enter a valid date")
        .required("This field is required when GKB is selected"),
    }),
  });

  // Formik initialization
  const formik = useFormik({
    initialValues: {
      startNotificationBeforeDays: "",
      sentNotificationIntervalDays: "",
      startNotificationAfterDays: "",
      sentNotificationIntervalDaysAfter: "",
      installment: [],
      postInputValue: "",
      gkbInputValue: "",
    },
    validationSchema,

    onSubmit: (values) => {
      var data = JSON.stringify({
        startNotificationBeforeDays: formik.values.startNotificationBeforeDays,
        sentNotificationIntervalDays:
          formik.values.sentNotificationIntervalDays,
        startNotificationAfterDays: formik.values.startNotificationAfterDays,
        sentNotificationIntervalDaysAfter:
          formik.values.sentNotificationIntervalDaysAfter,
        installment: formik.values.installment,
        postInputValue: formik.values.postInputValue,
        gkbInputValue: formik.values.gkbInputValue,
      });
      var config = {
        method: "put",
        url: AllUrl.notificationStudent,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      sendNotification(config, navigate);
    },
  });

  const isPostChecked = () => {
    return formik.values.installment.includes("post");
  };

  const isGKBChecked = () => {
    return formik.values.installment.includes("gkb");
  };

  return (
    <div>
      <CModal
        size="xl"
        alignment="center"
        visible={visible}
        onClose={() => {
          formik.handleReset();
          setVisible(false);
        }}
      >
        <CModalBody className="px-4">
          <div className="first_div createAdmin">
            <div
              className="notification_modal p-3"
              style={{ marginTop: "0px" }}
            >
              <form
                className="d-flex flex-column align-items-center"
                onSubmit={formik.handleSubmit}
              >
                <div className="w-100">
                  <img
                    onClick={() => navigate(-1)}
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "100%",
                      marginTop: "-10px",
                      cursor: "pointer",
                    }}
                    src={crossButton}
                    alt="close"
                    className="logo_img"
                  />
                  <h4
                    className=" text-aligns-center createAdminhead"
                    style={{ color: "#5A607F", fontWeight: "bold" }}
                  >
                    Notification Setting
                  </h4>
                </div>
                <div className="notification_div my-4 w-100">
                  <div className="container">
                    <div
                      className="row d-flex justify-content-start align-items-start text-start"
                      style={{ borderBottom: "1px solid #E8E8E8" }}
                    >
                      <div className="col-5">
                        <h5 className="m-0 py-3" style={{ color: "#5A607F" }}>
                          Notifications Before Installment
                        </h5>
                      </div>
                      <div className="col-2">
                        <h5
                          className="m-0 py-3 text-center"
                          style={{ color: "#5A607F" }}
                        >
                          Installment
                        </h5>
                      </div>
                      <div className="col-5">
                        <h5 className="m-0 py-3" style={{ color: "#5A607F" }}>
                          Notifications After Installment
                        </h5>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-evenly text-start py-3">
                      <div className="col-5">
                        <div className="row  text-start">
                          <div className="col">
                            <div className="form-group">
                              <label
                                className=""
                                style={{ fontSize: "14px" }}
                                htmlFor="startNotificationBeforeDays"
                              >
                                Start Notification Before Days:
                              </label>
                              <input
                                value={
                                  formik.values.startNotificationBeforeDays
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="number"
                                placeholder="Enter Before days"
                                id="startNotificationBeforeDays"
                                className="form-control"
                                {...formik.getFieldProps(
                                  "startNotificationBeforeDays"
                                )}
                              />
                              {formik.touched.startNotificationBeforeDays &&
                              formik.errors.startNotificationBeforeDays ? (
                                <div
                                  className="text-danger"
                                  style={{ fontSize: "11px" }}
                                >
                                  {formik.errors.startNotificationBeforeDays}
                                </div>
                              ) : null}
                              <p style={{ fontSize: "12px" }}>
                                Ex. 20 <br /> Send notification 20 days before
                                installment date
                              </p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label
                                className=""
                                style={{ fontSize: "14px" }}
                                htmlFor="sentNotificationIntervalDays"
                              >
                                Sent Notification Interval Days:
                              </label>
                              <input
                                value={
                                  formik.values.sentNotificationIntervalDays
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="number"
                                placeholder="Enter gap days"
                                id="sentNotificationIntervalDays"
                                className="form-control"
                                {...formik.getFieldProps(
                                  "sentNotificationIntervalDays"
                                )}
                              />
                              {formik.touched.sentNotificationIntervalDays &&
                              formik.errors.sentNotificationIntervalDays ? (
                                <div
                                  className="text-danger"
                                  style={{ fontSize: "11px" }}
                                >
                                  {formik.errors.sentNotificationIntervalDays}
                                </div>
                              ) : null}
                              <p style={{ fontSize: "12px" }}>
                                Ex. 5 <br /> Send notification in 5 days
                                interval
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="row text-start px-3">
                          <div className="col">
                            {/* First Installment Checkbox */}
                            <CFormCheck
                              style={{ width: "15px", height: "15px" }}
                              id="installment1"
                              label="First"
                              value="I"
                              checked={formik.values.installment.includes(
                                "I"
                              )}
                              onChange={(e) => {
                                const value = e.target.value;
                                const checked = e.target.checked;
                                if (checked) {
                                  formik.setFieldValue("installment", [
                                    ...formik.values.installment,
                                    value,
                                  ]);
                                } else {
                                  formik.setFieldValue(
                                    "installment",
                                    formik.values.installment.filter(
                                      (val) => val !== value
                                    )
                                  );
                                }
                              }}
                            />
                            {/* Second Installment Checkbox */}
                            <CFormCheck
                              style={{ width: "15px", height: "15px" }}
                              id="installment2"
                              label="Second"
                              value="II"
                              checked={formik.values.installment.includes(
                                "II"
                              )}
                              onChange={(e) => {
                                const value = e.target.value;
                                const checked = e.target.checked;
                                if (checked) {
                                  formik.setFieldValue("installment", [
                                    ...formik.values.installment,
                                    value,
                                  ]);
                                } else {
                                  formik.setFieldValue(
                                    "installment",
                                    formik.values.installment.filter(
                                      (val) => val !== value
                                    )
                                  );
                                }
                              }}
                            />
                            {/* Third Installment Checkbox */}
                            <CFormCheck
                              style={{ width: "15px", height: "15px" }}
                              id="installment3"
                              label="Third"
                              value="III"
                              checked={formik.values.installment.includes(
                                "III"
                              )}
                              onChange={(e) => {
                                const value = e.target.value;
                                const checked = e.target.checked;
                                if (checked) {
                                  formik.setFieldValue("installment", [
                                    ...formik.values.installment,
                                    value,
                                  ]);
                                } else {
                                  formik.setFieldValue(
                                    "installment",
                                    formik.values.installment.filter(
                                      (val) => val !== value
                                    )
                                  );
                                }
                              }}
                            />
                            {/* Post Installment Checkbox */}
                            <CFormCheck
                              style={{ width: "15px", height: "15px" }}
                              id="installment4"
                              label="Post"
                              value="postmetric"
                              checked={formik.values.installment.includes(
                                "postmetric"
                              )}
                              onChange={(e) => {
                                const value = e.target.value;
                                const checked = e.target.checked;
                                if (checked) {
                                  formik.setFieldValue("installment", [
                                    ...formik.values.installment,
                                    value,
                                  ]);
                                } else {
                                  formik.setFieldValue(
                                    "installment",
                                    formik.values.installment.filter(
                                      (val) => val !== value
                                    )
                                  );
                                }
                              }}
                            />
                            <label
                              htmlFor="postInputValue"
                              style={{ fontSize: "14px" }}
                            >
                              Postmetrics installment date
                            </label>
                            {/* Input field for Postmetrics installment date */}
                            <input
                              type="date"
                              placeholder="Enter post value"
                              id="postInputValue"
                              value={formik.values.postInputValue}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              style={{ width: "7vw" }}
                              className="form-control"
                              {...formik.getFieldProps("postInputValue")}
                              disabled={
                                !formik.values.installment.includes("postmetric")
                              } // Enable the input field if the checkbox is checked
                            />
                          </div>
                          {/* Additional input fields for GKB option */}
                          <div className="col">
                            {/* GKB Installment Checkbox */}
                            <CFormCheck
                              style={{ width: "15px", height: "15px" }}
                              id="installment5"
                              label="GKB"
                              value="gkb"
                              checked={formik.values.installment.includes(
                                "gkb"
                              )}
                              onChange={(e) => {
                                const value = e.target.value;
                                const checked = e.target.checked;
                                if (checked) {
                                  formik.setFieldValue("installment", [
                                    ...formik.values.installment,
                                    value,
                                  ]);
                                } else {
                                  formik.setFieldValue(
                                    "installment",
                                    formik.values.installment.filter(
                                      (val) => val !== value
                                    )
                                  );
                                }
                              }}
                            />
                            <label
                              htmlFor="gkbInputValue"
                              style={{ fontSize: "14px" }}
                            >
                              gkb installment date
                            </label>
                            {/* Input field for GKB installment date */}
                            <input
                              type="date"
                              placeholder="Enter GKB value"
                              value={formik.values.gkbInputValue}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              style={{ width: "7vw" }}
                              id="gkbInputValue"
                              className="form-control"
                              {...formik.getFieldProps("gkbInputValue")}
                              disabled={
                                !formik.values.installment.includes("gkb")
                              } // Enable the input field if the checkbox is checked
                            />
                          </div>
                        </div>
                        {/* Error message for installment checkboxes */}
                        {formik.touched.installment &&
                        formik.errors.installment ? (
                          <div
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {formik.errors.installment}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-5">
                        <div className="row text-start">
                          <div className="col">
                            <div className="form-group">
                              <label
                                className=""
                                style={{ fontSize: "14px" }}
                                htmlFor="startNotificationAfterDays"
                              >
                                Start Notification After Days:
                              </label>
                              <input
                                value={formik.values.startNotificationAfterDays}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="number"
                                placeholder="Enter After days"
                                id="startNotificationAfterDays"
                                className="form-control "
                                {...formik.getFieldProps(
                                  "startNotificationAfterDays"
                                )}
                              />
                              {formik.touched.startNotificationAfterDays &&
                              formik.errors.startNotificationAfterDays ? (
                                <div
                                  className="text-danger"
                                  style={{ fontSize: "11px" }}
                                >
                                  {formik.errors.startNotificationAfterDays}
                                </div>
                              ) : null}
                              <p style={{ fontSize: "12px" }}>
                                Ex. 20 <br /> Send notification up to 20 days
                                after installment date
                              </p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label
                                className=""
                                style={{ fontSize: "14px" }}
                                htmlFor="sentNotificationIntervalDaysAfter"
                              >
                                Sent Notification Interval Days:
                              </label>
                              <input
                                value={
                                  formik.values
                                    .sentNotificationIntervalDaysAfter
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="number"
                                placeholder="Enter gap days"
                                id="sentNotificationIntervalDaysAfter"
                                className="form-control"
                                {...formik.getFieldProps(
                                  "sentNotificationIntervalDaysAfter"
                                )}
                              />
                              {formik.touched
                                .sentNotificationIntervalDaysAfter &&
                              formik.errors
                                .sentNotificationIntervalDaysAfter ? (
                                <div
                                  className="text-danger"
                                  style={{ fontSize: "11px" }}
                                >
                                  {
                                    formik.errors
                                      .sentNotificationIntervalDaysAfter
                                  }
                                </div>
                              ) : null}
                              <p style={{ fontSize: "12px" }}>
                                Ex. 5 <br /> Send notification in 5 days
                                interval
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center align-items-center w-100">
                  <button
                    disabled={isLoading}
                    className="sent_btn w-25 btn-md text-light font-weight-bold"
                    type="submit"
                  >
                    {isLoading ? <LoaderButton /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
}

//Getting the state from the store
const mapStateToProps = (state) => {
  return {
    notificationData: state.notificationData,
  };
};

//passing the userData in createNewAdmin function and also dispatch method
const mapDispatchToProps = (dispatch) => {
  return {
    sendNotification: (config, navigate) =>
      dispatch(sendNotification(config, navigate)),
  };
};

//Connecting the component to our store
export default connect(mapStateToProps, mapDispatchToProps)(NotificationPopup);
