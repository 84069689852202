import { useEffect, useState } from 'react';
import allUrls from '../../redux/constants/url'
import axios from 'axios';
import eyeIcon from "../assests/image/eyeIcon.svg"
import BCA from "../assests/image/BCA.svg"
import BBA from "../assests/image/BBA.svg"
import BMICRO from "../assests/image/BSCM.svg"
import BCOM from "../assests/image/BCOM.svg"
import BSC from "../assests/image/BSC.svg"
import BSCBT from "../assests/image/BSC(BT).svg"
import ITEGDiploma from "../assests/image/ITEGDiploma.svg"
import BBAITEG from "../assests/image/BBAITEG.svg"
import ITEG from "../assests/image/ITEG.svg"
import MEG from "../assests/image/MEG.svg"
import BEG from "../assests/image/BEG.svg"
import { useNavigate } from 'react-router-dom';
import { Tooltip, Whisper } from 'rsuite';

const AttedanceClassCards = ({ date, setIsLoading }) => {
    const [attedance, setAttendance] = useState([]);
    const [groupAttedance, setGroupAttendance] = useState([]);
    const [sponsorshipTypeAttedance, setSponsorshipTypeAttedance] = useState([]);
    const [dataOption, setDataOption] = useState('all');
    const navigate = useNavigate();

    useEffect(() => {

        const token = localStorage.getItem("token");
        const getData = async () => {
            var con = {
                method: "GET",
                url: allUrls.getAttendace,
                params: {
                    dateFrom: `${date.a.getFullYear()}-${(parseInt(date.a.getMonth()) >= 9) ? "" : "0"}${date.a.getMonth() + 1}-${(parseInt(date.a.getDate()) >= 9) ? "" : "0"}${date.a.getDate()}`,
                    dateTo: `${date.b.getFullYear()}-${(parseInt(date.b.getMonth()) >= 9) ? "" : "0"}${date.b.getMonth() + 1}-${(parseInt(date.b.getDate()) >= 9) ? "" : "0"}${date.b.getDate()}`
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };

            if (con.params) {
                if (con.params.dateFrom === con.params.dateTo) {
                    setDataOption('1day');
                } else {
                    setDataOption('all');
                }
            }


            setIsLoading(true)
            const att = await axios(con)
            if (att.status === 200) {
                setAttendance(att.data.attedanceList.sort((a, b) => (b.branch.includes("Diploma") ? -1 : 0)))
                setGroupAttendance(att.data.attedanceGroupList)
                setSponsorshipTypeAttedance(att.data.attedanceSponsorshipList)
                setIsLoading(false)

            }

        }
        getData();

    }, [date, setIsLoading]);

    const getImg = (branch) => {
        switch (branch) {
            case "BCA":
                return BCA;
            case "BBA":
                return BBA;
            case "BSC(Micro)":
                return BMICRO;
            case "BSC(CS)":
                return BSC;
            case "B.com(CA)":
                return BCOM;
            case "BSC(BT)":
                return BSCBT;
            case "ITEG Diploma":
                return ITEGDiploma;
            case "BBA+ITEG":
                return BBAITEG;
            case "ITEG":
                return ITEG;
            case "BEG":
                return BEG;
            case "MEG":
                return MEG;
            case "BEG Diploma":
                return BMICRO;
            case "MEG Diploma":
                return MEG;
            case "SNS":
                return BCOM;
            case "SVS":
                return BCOM;
            default:
                break;
        }


    }
    function getColor(value) {
        value = 1 - (parseInt(value) / 100);
        var hue = ((1 - value) * 132).toString(10);
        return ["hsl(", hue, ",59.9%,85%)"].join("");
    }
    function getColorText(value) {
        value = 1 - (parseInt(value) / 100);
        var hue = ((1 - value) * 132).toString(10);
        return ["hsl(", hue, ",100%,22.9%)"].join("");
    }

    const totalDaysActive = attedance[0]?.totalDay || 0;

    var allGroupCount = {
        male: {

            totalAttendace: 0,
            totalAttendaceCount: 0,
        },
        female: {

            totalAttendace: 0,
            totalAttendaceCount: 0,
        },
        total: {

            totalAttendace: 0,
            totalAttendaceCount: 0,
        }
    };
    groupAttedance?.forEach((item) => {
        if (item?.totalMaleStudents) {
            allGroupCount.male.totalAttendace = allGroupCount.male.totalAttendace + parseInt(item.totalMaleAttendace);
            allGroupCount.male.totalAttendaceCount = allGroupCount.male.totalAttendaceCount + parseInt(item?.totalMaleStudents);
        }
        if (item?.totalFemaleStudents) {
            allGroupCount.female.totalAttendace = allGroupCount.female.totalAttendace + parseInt(item.totalFemaleAttendace);
            allGroupCount.female.totalAttendaceCount = allGroupCount.female.totalAttendaceCount + parseInt(item?.totalFemaleStudents);
        }
        if (item?.totalStudents) {
            allGroupCount.total.totalAttendace = allGroupCount.total.totalAttendace + parseInt(item.totalAttendace);
            allGroupCount.total.totalAttendaceCount = allGroupCount.total.totalAttendaceCount + parseInt(item?.totalStudents);
        }
    });


    var allClassCount = {
        male: {

            totalAttendace: 0,
            totalAttendaceCount: 0,
        },
        female: {

            totalAttendace: 0,
            totalAttendaceCount: 0,
        },
        total: {

            totalAttendace: 0,
            totalAttendaceCount: 0,
        }
    };
    attedance?.forEach((item) => {
        if (item?.totalMaleStudents) {
            allClassCount.male.totalAttendace = allClassCount.male.totalAttendace + parseInt(item.totalMaleAttendace);
            allClassCount.male.totalAttendaceCount = allClassCount.male.totalAttendaceCount + parseInt(item?.totalMaleStudents);
        }
        if (item?.totalFemaleStudents) {
            allClassCount.female.totalAttendace = allClassCount.female.totalAttendace + parseInt(item.totalFemaleAttendace);
            allClassCount.female.totalAttendaceCount = allClassCount.female.totalAttendaceCount + parseInt(item?.totalFemaleStudents);
        }
        if (item?.totalStudents) {
            allClassCount.total.totalAttendace = allClassCount.total.totalAttendace + parseInt(item.totalAttendace);
            allClassCount.total.totalAttendaceCount = allClassCount.total.totalAttendaceCount + parseInt(item?.totalStudents);
        }
    });



    var allSponcershipCount = {
        male: {

            totalAttendace: 0,
            totalAttendaceCount: 0,
        },
        female: {

            totalAttendace: 0,
            totalAttendaceCount: 0,
        },
        total: {

            totalAttendace: 0,
            totalAttendaceCount: 0,
        }
    };
    sponsorshipTypeAttedance?.forEach((item) => {
        if (item?.totalMaleStudents) {
            allSponcershipCount.male.totalAttendace = allSponcershipCount.male.totalAttendace + parseInt(item.totalMaleAttendace);
            allSponcershipCount.male.totalAttendaceCount = allSponcershipCount.male.totalAttendaceCount + parseInt(item?.totalMaleStudents);
        }
        if (item?.totalFemaleStudents) {
            allSponcershipCount.female.totalAttendace = allSponcershipCount.female.totalAttendace + parseInt(item.totalFemaleAttendace);
            allSponcershipCount.female.totalAttendaceCount = allSponcershipCount.female.totalAttendaceCount + parseInt(item?.totalFemaleStudents);
        }
        if (item?.totalStudents) {
            allSponcershipCount.total.totalAttendace = allSponcershipCount.total.totalAttendace + parseInt(item.totalAttendace);
            allSponcershipCount.total.totalAttendaceCount = allSponcershipCount.total.totalAttendaceCount + parseInt(item?.totalStudents);
        }
    });

    const getAttendacePercentage = (data, option) => {
        let percent = ((data.totalAttendace / (data.totalAttendaceCount * totalDaysActive)) * 100).toFixed(2) + "%"

        if (option === 'all') {
            return percent === 'NaN%' ? '0%' : percent;
        } else if (option === '1day') {
            return percent === 'NaN%' ? '0%' : `${percent} | ${data.totalAttendace} / ${data.totalAttendaceCount}`;
        }
    };

    const handleFormula = (att) => {
        var formula = '';
        formula = `${att.Tatt} (Total Attendance) / ${att.Tdays} * ${att.Tstu}`
        return (
            <Tooltip>
                {formula}
            </Tooltip>
        )
    }

    return (<div class="overflow-hidden mt-3">
        <div class="accordion" id="accordionExample">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <span className='col-2'>SSISM Group Wise</span>
                        <span className='ps-4 col-2'>Days - {totalDaysActive}</span>
                        <div className='col-2'>
                            <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: allGroupCount.male.totalAttendace, Tdays: totalDaysActive, Tstu: allGroupCount.male.totalAttendaceCount })}>
                                <span className='ps-4'>Male - {getAttendacePercentage(allGroupCount.male, dataOption)}</span>
                            </Whisper>
                        </div>
                        <div className="col-2">
                            <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: allGroupCount.female.totalAttendace, Tdays: totalDaysActive, Tstu: allGroupCount.female.totalAttendaceCount })}>
                                <span className='ps-4'>Female - {getAttendacePercentage(allGroupCount.female, dataOption)}</span>
                            </Whisper>
                        </div>

                        <div className="col-2">
                            <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: allGroupCount.total.totalAttendace, Tdays: totalDaysActive, Tstu: allGroupCount.total.totalAttendaceCount })}>
                                <span className='ps-4 col-2'>Total - {getAttendacePercentage(allGroupCount.total, dataOption)}</span>
                            </Whisper>
                        </div>
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <div class="row g-1">
                            <div className="col-12 classAttedanceCardText  rounded"></div>
                            {groupAttedance.map((ele, i) => {

                                return (<div key={i} onClick={() => navigate(`${ele.branch}/${ele.year}`)} className="col-12 col-sm-6 col-lg-4 col-xl-4 rounded">
                                    <div className='m-2 p-3 classAttedanceCard ' >

                                        <div className='row align-items-center'>
                                            <div className='col-2'>
                                                <img src={getImg(ele.branch)} alt="..." />
                                            </div>
                                            <div className='col-8 fw-bold' >
                                                &nbsp;{ele.branch} {ele.year === "I" ? "1st Year" : ele.year === "II" ? "2nd Year" : "3rd Year"}
                                            </div>
                                            <div className='col-2'>
                                                <img src={eyeIcon} alt="..." />
                                            </div>
                                        </div>
                                        <div className='row  g-2'>
                                            <div className='col-4 mt-4 px-2' style={{ borderRight: '2px dashed #c4c6d1' }}>
                                                <div>
                                                    <h6 className='text-center fw-bold'>Male</h6>
                                                </div>

                                                <div style={{ fontWeight: "700", fontSize: '16px', marginTop: "2px", marginBottom: "4px" }} className='text-center'>
                                                    {dataOption === 'all' ? ele.totalMaleStudents : `${ele.totalMaleAttendace} / ${ele.totalMaleStudents}`}
                                                </div>
                                                <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: ele.totalMaleAttendace, Tdays: ele.totalDay, Tstu: ele.totalMaleStudents })}>
                                                    <div className='p-2 fw-bold d-flex justify-content-center' style={{ borderRadius: "10px", backgroundColor: ele.maleStudentPercent === 'NaN%' ? getColor('0%') : getColor(ele.maleStudentPercent), color: ele.maleStudentPercent === 'NaN%' ? getColorText('0%') : getColorText(ele.maleStudentPercent) }}>
                                                        {ele.maleStudentPercent === 'NaN%' ? '0%' : ele.maleStudentPercent}
                                                    </div>
                                                </Whisper>
                                            </div>
                                            <div className='col-4 mt-4 px-2' style={{ borderRight: '2px dashed #c4c6d1' }}>
                                                <div>
                                                    <h6 className='text-center fw-bold'>Female</h6>
                                                </div>
                                                <div style={{ fontWeight: "700", fontSize: '16px', marginTop: "2px", marginBottom: "4px" }} className='text-center'>
                                                    {dataOption === 'all' ? ele.totalFemaleStudents : `${ele.totalFemaleAttendace} / ${ele.totalFemaleStudents}`}
                                                </div>
                                                <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: ele.totalFemaleAttendace, Tdays: ele.totalDay, Tstu: ele.totalFemaleStudents })}>
                                                    <div className='p-2 fw-bold d-flex justify-content-center' style={{ borderRadius: "10px", backgroundColor: ele.femaleStudentPercent === 'NaN%' ? getColor('0%') : getColor(ele.femaleStudentPercent), color: ele.femaleStudentPercent === 'NaN%' ? getColorText('0%') : getColorText(ele.femaleStudentPercent) }}>
                                                        {ele.femaleStudentPercent === 'NaN%' ? '0%' : ele.femaleStudentPercent}
                                                    </div>
                                                </Whisper>
                                            </div>

                                            <div className='col-4 mt-4 px-2'>
                                                <div>
                                                    <h6 className='text-center fw-bold'>Total</h6>
                                                </div>
                                                <div style={{ fontWeight: "700", fontSize: '16px', marginTop: "2px", marginBottom: "4px" }} className='text-center'>
                                                    {dataOption === 'all' ? ele.totalStudents : `${ele.totalAttendace} / ${ele.totalStudents}`}
                                                </div>
                                                <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: ele.totalAttendace, Tdays: ele.totalDay, Tstu: ele.totalStudents })}>
                                                    <div className='p-2 fw-bold d-flex justify-content-center' style={{ borderRadius: "10px", backgroundColor: ele.attendancePercent === 'NaN%' ? getColor('0%') : getColor(ele.attendancePercent), color: ele.attendancePercent === 'NaN%' ? getColorText('0%') : getColorText(ele.attendancePercent) }}>
                                                        {ele.attendancePercent === 'NaN%' ? '0%' : ele.attendancePercent}
                                                    </div>
                                                </Whisper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )

                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span className='col-2'>SSISM sponsorship Wise</span>
                        <span className='ps-4 col-2'>Days - {totalDaysActive}</span>
                        <div className='col-2'>
                            <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: allSponcershipCount.male.totalAttendace, Tdays: totalDaysActive, Tstu: allSponcershipCount.male.totalAttendaceCount })}>
                                <span className='ps-4'>Male - {getAttendacePercentage(allSponcershipCount.male, dataOption)}</span>
                            </Whisper>
                        </div>
                        <div className="col-2">
                            <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: allSponcershipCount.female.totalAttendace, Tdays: totalDaysActive, Tstu: allSponcershipCount.female.totalAttendaceCount })}>
                                <span className='ps-4'>Female - {getAttendacePercentage(allSponcershipCount.female, dataOption)}</span>
                            </Whisper>
                        </div>

                        <div className="col-2">
                            <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: allSponcershipCount.total.totalAttendace, Tdays: totalDaysActive, Tstu: allSponcershipCount.total.totalAttendaceCount })}>
                                <span className='ps-4 col-2'>Total - {getAttendacePercentage(allSponcershipCount.total, dataOption)}</span>
                            </Whisper>
                        </div>
                    </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <div class="row g-1 mt-3">
                            <div className="col-12 classAttedanceCardText  rounded"></div>
                            {sponsorshipTypeAttedance.map((ele, i) => {

                                return (

                                    <div key={i} onClick={() => navigate(`${ele.branch}/${ele.year}`)} className="col-12 col-sm-6 col-lg-4 col-xl-4 rounded">
                                        <div className='m-2 p-3 classAttedanceCard ' >

                                            <div className='row align-items-center'>
                                                <div className='col-2'>
                                                    <img src={getImg(ele.branch)} alt="..." />
                                                </div>
                                                <div className='col-8 fw-bold' >
                                                    &nbsp;{ele.branch} {ele.year === "I" ? "1st Year" : ele.year === "II" ? "2nd Year" : "3rd Year"}
                                                </div>
                                                <div className='col-2'>
                                                    <img src={eyeIcon} alt="..." />
                                                </div>
                                            </div>
                                            <div className='row  g-2'>
                                                <div className='col-4 mt-4 px-2' style={{ borderRight: '2px dashed #c4c6d1' }}>
                                                    <div>
                                                        <h6 className='text-center fw-bold'>Male</h6>
                                                    </div>

                                                    <div style={{ fontWeight: "700", fontSize: '16px', marginTop: "2px", marginBottom: "4px" }} className='text-center'>
                                                        {dataOption === 'all' ? ele.totalMaleStudents : `${ele.totalMaleAttendace} / ${ele.totalMaleStudents}`}
                                                    </div>
                                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: ele.totalMaleAttendace, Tdays: ele.totalDay, Tstu: ele.totalMaleStudents })}>

                                                        <div className='p-2 fw-bold d-flex justify-content-center' style={{ borderRadius: "10px", backgroundColor: ele.maleStudentPercent === 'NaN%' ? getColor('0%') : getColor(ele.maleStudentPercent), color: ele.maleStudentPercent === 'NaN%' ? getColorText('0%') : getColorText(ele.maleStudentPercent) }}>
                                                            {ele.maleStudentPercent === 'NaN%' ? '0%' : ele.maleStudentPercent}
                                                        </div>
                                                    </Whisper>
                                                </div>
                                                <div className='col-4 mt-4 px-2' style={{ borderRight: '2px dashed #c4c6d1' }}>
                                                    <div>
                                                        <h6 className='text-center fw-bold'>Female</h6>
                                                    </div>
                                                    <div style={{ fontWeight: "700", fontSize: '16px', marginTop: "2px", marginBottom: "4px" }} className='text-center'>
                                                        {dataOption === 'all' ? ele.totalFemaleStudents : `${ele.totalFemaleAttendace} / ${ele.totalFemaleStudents}`}
                                                    </div>
                                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: ele.totalFemaleAttendace, Tdays: ele.totalDay, Tstu: ele.totalFemaleStudents })}>
                                                        <div className='p-2 fw-bold d-flex justify-content-center' style={{ borderRadius: "10px", backgroundColor: ele.femaleStudentPercent === 'NaN%' ? getColor('0%') : getColor(ele.femaleStudentPercent), color: ele.femaleStudentPercent === 'NaN%' ? getColorText('0%') : getColorText(ele.femaleStudentPercent) }}>
                                                            {ele.femaleStudentPercent === 'NaN%' ? '0%' : ele.femaleStudentPercent}
                                                        </div>
                                                    </Whisper>
                                                </div>

                                                <div className='col-4 mt-4 px-2'>
                                                    <div>
                                                        <h6 className='text-center fw-bold'>Total</h6>
                                                    </div>
                                                    <div style={{ fontWeight: "700", fontSize: '16px', marginTop: "2px", marginBottom: "4px" }} className='text-center'>
                                                        {dataOption === 'all' ? ele.totalStudents : `${ele.totalAttendace} / ${ele.totalStudents}`}
                                                    </div>
                                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: ele.totalAttendace, Tdays: ele.totalDay, Tstu: ele.totalStudents })}>
                                                        <div className='p-2 fw-bold d-flex justify-content-center' style={{ borderRadius: "10px", backgroundColor: ele.attendancePercent === 'NaN%' ? getColor('0%') : getColor(ele.attendancePercent), color: ele.attendancePercent === 'NaN%' ? getColorText('0%') : getColorText(ele.attendancePercent) }}>
                                                            {ele.attendancePercent === 'NaN%' ? '0%' : ele.attendancePercent}
                                                        </div>
                                                    </Whisper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )

                            })}
                        </div> </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <span className='col-2'>SSISM Class Wise</span>
                        <span className='ps-4 col-2'>Days - {totalDaysActive}</span>
                        <div className='col-2'>
                            <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: allClassCount.male.totalAttendace, Tdays: totalDaysActive, Tstu: allClassCount.male.totalAttendaceCount })}>
                                <span className='ps-4'>Male - {getAttendacePercentage(allClassCount.male, dataOption)}</span>
                            </Whisper>
                        </div>
                        <div className="col-2">
                            <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: allClassCount.female.totalAttendace, Tdays: totalDaysActive, Tstu: allClassCount.female.totalAttendaceCount })}>
                                <span className='ps-4'>Female - {getAttendacePercentage(allClassCount.female, dataOption)}</span>
                            </Whisper>
                        </div>

                        <div className="col-2">
                            <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: allClassCount.total.totalAttendace, Tdays: totalDaysActive, Tstu: allClassCount.total.totalAttendaceCount })}>
                                <span className='ps-4 col-2'>Total - {getAttendacePercentage(allClassCount.total, dataOption)}</span>
                            </Whisper>
                        </div>
                    </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <div class="row g-1 mt-3">
                            <div className="col-12 classAttedanceCardText  rounded"></div>
                            {attedance.map((ele, i) => {

                                return (

                                    <div key={i} onClick={() => navigate(`${ele.branch}/${ele.year}`)} className="col-12 col-sm-6 col-lg-4 col-xl-4 rounded">
                                        <div className='m-2 p-3 classAttedanceCard ' >

                                            <div className='row align-items-center'>
                                                <div className='col-2'>
                                                    <img src={getImg(ele.branch)} alt="..." />
                                                </div>
                                                <div className='col-8 fw-bold' >
                                                    &nbsp;{ele.branch} {ele.year === "I" ? "1st Year" : ele.year === "II" ? "2nd Year" : "3rd Year"}
                                                </div>
                                                <div className='col-2'>
                                                    <img src={eyeIcon} alt="..." />
                                                </div>
                                            </div>
                                            <div className='row  g-2'>
                                                <div className='col-4 mt-4 px-2' style={{ borderRight: '2px dashed #c4c6d1' }}>
                                                    <div>
                                                        <h6 className='text-center fw-bold'>Male</h6>
                                                    </div>

                                                    <div style={{ fontWeight: "700", fontSize: '16px', marginTop: "2px", marginBottom: "4px" }} className='text-center'>
                                                        {dataOption === 'all' ? ele.totalMaleStudents : `${ele.totalMaleAttendace} / ${ele.totalMaleStudents}`}
                                                    </div>
                                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: ele.totalMaleAttendace, Tdays: ele.totalDay, Tstu: ele.totalMaleStudents })}>
                                                        <div className='p-2 fw-bold d-flex justify-content-center' style={{ borderRadius: "10px", backgroundColor: ele.maleStudentPercent === 'NaN%' ? getColor('0%') : getColor(ele.maleStudentPercent), color: ele.maleStudentPercent === 'NaN%' ? getColorText('0%') : getColorText(ele.maleStudentPercent) }}>
                                                            {ele.maleStudentPercent === 'NaN%' ? '0%' : ele.maleStudentPercent}
                                                        </div>
                                                    </Whisper>
                                                </div>
                                                <div className='col-4 mt-4 px-2' style={{ borderRight: '2px dashed #c4c6d1' }}>
                                                    <div>
                                                        <h6 className='text-center fw-bold'>Female</h6>
                                                    </div>
                                                    <div style={{ fontWeight: "700", fontSize: '16px', marginTop: "2px", marginBottom: "4px" }} className='text-center'>
                                                        {dataOption === 'all' ? ele.totalFemaleStudents : `${ele.totalFemaleAttendace} / ${ele.totalFemaleStudents}`}
                                                    </div>
                                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: ele.totalFemaleAttendace, Tdays: ele.totalDay, Tstu: ele.totalFemaleStudents })}>
                                                        <div className='p-2 fw-bold d-flex justify-content-center' style={{ borderRadius: "10px", backgroundColor: ele.femaleStudentPercent === 'NaN%' ? getColor('0%') : getColor(ele.femaleStudentPercent), color: ele.femaleStudentPercent === 'NaN%' ? getColorText('0%') : getColorText(ele.femaleStudentPercent) }}>
                                                            {ele.femaleStudentPercent === 'NaN%' ? '0%' : ele.femaleStudentPercent}
                                                        </div>
                                                    </Whisper>
                                                </div>

                                                <div className='col-4 mt-4 px-2'>
                                                    <div>
                                                        <h6 className='text-center fw-bold'>Total</h6>
                                                    </div>
                                                    <div style={{ fontWeight: "700", fontSize: '16px', marginTop: "2px", marginBottom: "4px" }} className='text-center'>
                                                        {dataOption === 'all' ? ele.totalStudents : `${ele.totalAttendace} / ${ele.totalStudents}`}
                                                    </div>
                                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={handleFormula({ Tatt: ele.totalAttendace, Tdays: ele.totalDay, Tstu: ele.totalStudents })}>
                                                        <div className='p-2 fw-bold d-flex justify-content-center' style={{ borderRadius: "10px", backgroundColor: ele.attendancePercent === 'NaN%' ? getColor('0%') : getColor(ele.attendancePercent), color: ele.attendancePercent === 'NaN%' ? getColorText('0%') : getColorText(ele.attendancePercent) }}>
                                                            {ele.attendancePercent === 'NaN%' ? '0%' : ele.attendancePercent}
                                                        </div>
                                                    </Whisper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )

                            })}
                        </div>  </div>
                </div>
            </div>
        </div>



    </div>)
}

export default AttedanceClassCards;