/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../styles/Table.css";
import { Fragment, useMemo } from "react";
import {
    useTable,
    useFilters,
    useSortBy,
    useGlobalFilter,
    usePagination,
    useRowSelect,
} from "react-table";
import updown_sort from "../../assests/image/updown_sort.svg";
import { TableCheckbox } from "../tableComponents/TableCheckbox";
import {
    CDropdown,
    CDropdownMenu,
    CDropdownToggle,
    CPopover,
} from "@coreui/react";
import filtericon from "../../assests/image/AccountIcons/filter.svg";
import pendingScholarship from "../../../redux/actionDispatcher/account/pendingScholarshipTableDispatcher";
import AllUrl from "../../../redux/constants/url";
import { connect } from "react-redux";
import SkeletonColor from "../../../helpers/Skeletrone";
import { ToastContainer } from "react-toastify";
import Pagination from "../../assests/common/Pagination";
import { GlobalFilter } from "../tableComponents/GlobalFilter";
import { Tooltip, Whisper } from "rsuite";
import rightArrow from "../../assests/image/right_arrow_icon.svg";
import NoDataFound from "../../assests/common/NoDataFound";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import allUrls from "../../../redux/constants/url";
import { isSuperAdmin } from "../../../helpers/SuperAdmin";
import { isAccountAdmin } from "../../../helpers/AccountAdmin";
import { CSVLink } from "react-csv";
import { useEffect } from "react";

export const MultipleFilter = (rows, accessor, filterValue) => {
    const arr = [];
    rows.forEach((val) => {
        if (filterValue.includes(val.original[accessor])) arr.push(val);
    });
    return arr;
};

function setFilteredParams(filterArr, val) {
    if (filterArr.includes(val)) {
        filterArr = filterArr.filter((n) => {
            return n !== val;
        });
    } else filterArr.push(val);

    if (filterArr.length === 0) filterArr = undefined;
    return filterArr;
}

function SelectColumnFilter({
    column: { filterValue = [], setFilter, preFilteredRows, id },
}) {
    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    let offsetObj = [0, 0];

    if (id === "year") offsetObj = [60, 18];
    else if (id === "branch") offsetObj = [110, 18];

    let name = id;

    switch (id) {
        case "year":
            name = "Year";
            break;
        case "branch":
            name = "Branch";
            break;
        default:
            break;
    }

    return (
        <Fragment>
            <div
                onClick={(e) => {
                    e.preventDefault();
                }}
                className="d-flex justify-content-end"
            >
                <CPopover
                    offset={offsetObj}
                    content={
                        <div className="">
                            {options.map((option, i) => {
                                let option_label = option;

                                if (id === "is_active") {
                                    if (option === "true") option_label = "Active";
                                    else option_label = "Deactive";
                                }

                                return (
                                    <Fragment key={i}>
                                        <div
                                            id={`${id}`}
                                            style={{ height: "30px", cursor: "pointer" }}
                                            className="filter_btn_hover p-1 pt-2 my-1 d-flex align-items-center "
                                        >
                                            <label
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                className="font-medium text-gray-700 d-flex align-items-center cursor-pointer"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <input
                                                    checked={filterValue.includes(option)}
                                                    type="checkbox"
                                                    className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-1"
                                                    id={option}
                                                    name={option}
                                                    value={option}
                                                    style={{ cursor: "pointer" }}
                                                    onChange={(e) => {
                                                        setFilter(
                                                            setFilteredParams(filterValue, e.target.value)
                                                        );
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                ></input>

                                                {option_label}
                                            </label>
                                        </div>
                                    </Fragment>
                                );
                            })}
                        </div>
                    }
                    placement="right"
                >
                    <div className="btn-group filter_btn_hover">
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            className="btn filter_btn"
                        >
                            {name}
                        </button>
                        <img
                            src={rightArrow}
                            alt=">"
                            width="6px"
                            style={{
                                marginTop: "4px",
                                marginRight: "10px",
                            }}
                        />
                    </div>
                </CPopover>
            </div>
        </Fragment>
    );
}

function BusFeesStudentTable({
    backOriginal,
    getReport,
    scholarData,
    fetchData,
}) {
    const token = localStorage.getItem("token");
    const { sessionId, track, month } = useParams();
    const [sessionsList, setSessionsList] = useState(null);
    const [selectedBatch, setSelectedBatch] = useState(sessionId);
    const [selectedTrack, setSelectedTrack] = useState(track);
    const [selectedMonth, setSelectedMonth] = useState(month);
    const [monthNameList, setMonthNameList] = useState();

    const [MoneyCount, setMoneyCount] = useState({
        TStudents: 0,
        TAmount: 0,
        TpaidAmount: 0,
        RAmount: 0,
        TsetteledAmount: 0,
    });

    const trackNameList = [
        { title: "Harda", dataQuery: `Harda` },
        { title: "Kannod", dataQuery: `Kannod` },
        { title: "Khategaon", dataQuery: `Khategaon` },
        { title: "Nasrullaganj", dataQuery: `Nasrullaganj` },
        { title: "Nemawar", dataQuery: `Nemawar` },
        { title: "Sandalpur", dataQuery: `Sandalpur` },
        { title: "Satwas", dataQuery: `Satwas`, },
        { title: "All Tracks", dataQuery: 'All' }
    ];

    localStorage.setItem("batchYear", selectedBatch);
    const navigate = useNavigate();

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                var con = {
                    method: "GET",
                    url: allUrls.getSessionList,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                };

                const sessionList = await axios(con);

                if (sessionList.status === 200) {
                    setSessionsList(sessionList?.data);
                }
            } catch (error) {
                console.log("error to fetch Session List : ", error);
            }
        };
        fetchData();
    }, []);

    React.useEffect(() => {
        if (sessionId && track && month) {
            var config = {
                method: "GET",
                url: `${AllUrl.getTrackwiseBusDetails}/${selectedBatch}/${selectedTrack}/${selectedMonth}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };
            fetchData(config);
        }
        localStorage.setItem("batchYear", selectedBatch);

        const updateParams = () => {
            isSuperAdmin() && navigate(`/admin_dashboard/pendingbusfeesstudent/${selectedBatch}/${selectedTrack}/${selectedMonth}`);
            isAccountAdmin() && navigate(`/account_admin_dashboard/pendingbusfeesstudent/${selectedBatch}/${selectedTrack}/${selectedMonth}`);
        };

        updateParams();
    }, [ selectedBatch, selectedTrack, selectedMonth]);

    useEffect(async () => {
        var consfigMonth = {
            method: 'get',
            url: allUrls.getChargebleMonth + `?sessionId=${selectedBatch}`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
        }
        var monthName = await axios(consfigMonth);
        if (monthName.status === 200) { setMonthNameList(monthName.data); }
        else { setMonthNameList([]); }
    }, [selectedBatch]);

    const mainColoumns = React.useMemo(
        () => [
            {
                header: "S.No",
                accessor: "Srno",
                Cell: ({ row: { original, index } }) => {
                    return index + 1;
                },
                Filter: "",
                filter: "",
            },
            {
                header: "Name",
                accessor: "name",
                Filter: "",
                filter: "",
            },
            {
                header: "Father Name",
                accessor: "fathersName",
                Filter: "",
                filter: "",
            },
            {
                header: "Branch",
                accessor: "branch",
                Filter: SelectColumnFilter,
                filter: MultipleFilter,
            },
            {
                header: "Year",
                accessor: "year",
                Filter: SelectColumnFilter,
                filter: MultipleFilter,
            },
            {
                header: "Total amount",
                accessor: "totalAmount",
                Filter: "",
                filter: "",
            },
            {
                header: "Received amount",
                accessor: "receivedAmount",
                Filter: "",
                filter: "",
            },
            {
                header: "Pending amount",
                accessor: "remainAmount",
                Cell: ({ row: { original } }) => (
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="d-flex justify-content-center align-items-center" style={{ justifyContent: 'center' }}>
                            <span
                                className="recieved-fee-circle"
                                style={{
                                    backgroundColor: `${original.remainAmount <= 0 ? "#56F000" : "#ffc107"
                                        }`,
                                    marginRight: "10px",
                                    marginLeft: "20px",
                                }}
                            ></span>
                            {original.remainAmount}
                        </div>
                    </div>
                ),
                Filter: "",
                filter: "",
            },
            {
                header: "Waive off",
                accessor: "setteldAmount",
                Filter: "",
                filter: "",
            },
        ],
        []
    );

    const [columns, setColoumns] = useState(mainColoumns);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        gotoPage,
        pageCount,
        previousPage,
        canPreviousPage,
        canNextPage,
        setPageSize,
        pageOptions,
        selectedFlatRows,
        state,
        setGlobalFilter,
        rows,
        preGlobalFilteredRows,
        prepareRow,
    } = useTable(
        { columns, data: scholarData.table_data },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id: "selection",
                        header: ({ getToggleAllRowsSelectedProps }) => (
                            <TableCheckbox {...getToggleAllRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => (
                            <TableCheckbox {...row.getToggleRowSelectedProps()} />
                        ),
                    },
                    ...columns,
                ];
            });
        }
    );

    const { globalFilter, pageSize, pageIndex } = state;

    var idData = [];
    var exportCsv = [];
    const checkboxData = JSON.stringify(
        {
            selectedFlatRows: selectedFlatRows.forEach((row) => {
                let data = Object.assign({}, row.original);
                delete data.photo;
                idData.push(data.id);
                exportCsv.push(data);
            }),
        },
        null,
        2
    );

    React.useEffect(() => {
        let data = rows;
        let RAmount = 0;
        let TAmount = 0;
        let TpaidAmount = 0;
        let TsetteledAmount = 0
        data?.forEach((ele) => {
            ele = ele.original;
            RAmount += ele?.remainAmount;
            TAmount += ele?.totalAmount;
            TpaidAmount += ele?.receivedAmount;
            TsetteledAmount += ele?.setteldAmount;
        });

        setMoneyCount((val) => {
            return {
                TStudents: data.length,
                RAmount,
                TAmount,
                TpaidAmount,
                TsetteledAmount,
            };
        });
    }, [rows]);


    return scholarData.loading ? (
        <SkeletonColor></SkeletonColor>
    ) : (
        <Fragment>
            <ToastContainer
                position="top-center"
                autoClose={2500}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="container-fluid">
                <div
                    style={{
                        position: "sticky",
                        top: "80px",
                        width: "100%",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        backgroundColor: "#f4f7fc",
                        zIndex: "6",
                    }}
                >
                    <div
                        className="d-flex row Stu-Acc-info "
                        style={{
                            color: "rgb(90, 96, 127)",
                            margin: "Auto",
                            height: "70px",
                        }}
                    >
                        <div className="col info-col m-2">
                            <h5 style={{ marginTop: "12px" }}>
                                {" "}
                                {MoneyCount.TStudents} <br /> <p>Total Students</p>{" "}
                            </h5>
                        </div>
                        <div className="col info-col m-2">
                            <h5 style={{ marginTop: "12px" }}>
                                {MoneyCount.TAmount
                                    ? MoneyCount.TAmount.toLocaleString("en-IN")
                                    : "-"}{" "}
                                <br /> <p>{"Total Amount"}</p>{" "}
                            </h5>
                        </div>
                        <div className="col info-col m-2">
                            <h5 style={{ marginTop: "12px" }}>
                                {MoneyCount.TpaidAmount.toLocaleString("en-IN")} <br />{" "}
                                <p>{"Total Paid Amount"}</p>{" "}
                            </h5>
                        </div>
                        <div className="col info-col m-2">
                            <h5 style={{ marginTop: "12px" }}>
                                {MoneyCount.RAmount
                                    ? MoneyCount.RAmount.toLocaleString("en-IN")
                                    : "-"}{" "}
                                <br /> <p>{"Remaining Amount"}</p>{" "}
                            </h5>
                        </div>

                        <div className="col info-col m-2">
                            <h5 style={{ marginTop: "12px" }}>
                                {MoneyCount.TsetteledAmount.toLocaleString("en-IN")} <br />{" "}
                                <p>{"Waive Off"}</p>{" "}
                            </h5>
                        </div>
                    </div>

                    <div className="d-flex mt-3 mx-0">
                        <div className="">
                            <select
                                className="form-select table_select_row_options"
                                value={pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}
                            >
                                {[10, 25, 50, 100].map((pageSize) => (
                                    <option value={pageSize} key={pageSize}>
                                        Show Entries {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="ml-4">
                            <select
                                className="form-select table_select_row_options"
                                value={selectedBatch}
                                onChange={(e) => setSelectedBatch(e.target.value)}
                            >
                                {sessionsList?.map((item, index) => (
                                    <option value={item?.sessionId} key={index}>
                                        Session: {item?.sessionName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="ml-4">
                            <select
                                className="form-select table_select_row_options"
                                value={selectedTrack}
                                onChange={(e) => setSelectedTrack(e.target.value)}
                            >
                                {trackNameList?.map((item, index) => (
                                    <option value={item?.dataQuery} key={index}>
                                        {item?.title}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="ml-4">
                            <select
                                className="form-select table_select_row_options"
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(e.target.value)}
                            >
                                <option value='All'>All Months</option>
                                {monthNameList?.map((item, index) => (
                                    <option value={item?.month} key={index}>
                                        {`${item?.month} ${item?.year}`}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="ms-4">
                            <div className="btn-group  ml-3">
                                <button
                                    className="btn  btn-sm download-btn dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Download
                                </button>
                                <div className="dropdown-menu mt-1" style={{ zIndex: "10" }}>
                                    <div>
                                        <div>
                                            <CSVLink
                                                className="dropdown-item"
                                                style={{ fontWeight: "bold" }}
                                                data={exportCsv}
                                            >
                                                Excel
                                            </CSVLink>
                                        </div>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex ml-auto me-1">
                            <div
                                className="d-flex mr-2"
                                style={{
                                    height: "40px",
                                    width: "42px",
                                    backgroundColor: "#fff",
                                    borderRadius: "3px",
                                    border: "1px solid #EDEDED",
                                }}
                            >
                                <CDropdown variant="nav-item" style={{ color: "white" }}>
                                    <CDropdownToggle
                                        placement="bottom-end"
                                        className="py-0"
                                        caret={false}
                                    >
                                        {" "}
                                        <Whisper
                                            placement="top"
                                            controlId="control-id-hover"
                                            trigger="hover"
                                            speaker={<Tooltip>Filter Data .</Tooltip>}
                                        >
                                            <img
                                                src={filtericon}
                                                alt=""
                                                style={{
                                                    height: "22px",
                                                    width: "35px",
                                                    marginTop: "-35px",
                                                    marginLeft: "-13px",
                                                }}
                                            />
                                        </Whisper>
                                    </CDropdownToggle>

                                    <CDropdownMenu
                                        component={"div"}
                                        className="pt-0 filter-dropdown-menu"
                                        placement="bottom-end"
                                    >
                                        <div className="p-lg-2 pb-0">
                                            {headerGroups.map((headerGroup) => (
                                                <div
                                                    style={{ display: "flex flex-column" }}
                                                    {...headerGroup.getHeaderGroupProps()}
                                                >
                                                    {headerGroup.headers.map((column, i) => (
                                                        <div
                                                            key={i}
                                                            style={{
                                                                display: "block",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            {column.canFilter
                                                                ? column.render("Filter")
                                                                : null}
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    </CDropdownMenu>
                                </CDropdown>
                            </div>

                            <div className="ml-auto me-4">
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    filter={globalFilter}
                                    setFilter={setGlobalFilter}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <table {...getTableProps()} id="customers">
                    <thead
                        style={{
                            position: "sticky",
                            top: "135px",
                            width: "100%",
                            backgroundColor: "#f4f7fc",
                            zIndex: "5",
                        }}
                    >
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render("header")}
                                        <span>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <img
                                                        src={updown_sort}
                                                        style={{ marginLeft: "5px" }}
                                                        alt=""
                                                    />
                                                ) : (
                                                    <img
                                                        src={updown_sort}
                                                        style={{ marginLeft: "5px" }}
                                                        alt=""
                                                    />
                                                )
                                            ) : (
                                                ""
                                            )}
                                            {column.isSorted ? (column.isSortedDesc ? "" : "") : ""}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <NoDataFound rows={rows} />

                <Pagination
                    data={rows}
                    rows={rows}
                    page={page}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    pageSize={pageSize}
                    canPreviousPage={canPreviousPage}
                    previousPage={previousPage}
                    pageOptions={pageOptions}
                    gotoPage={gotoPage}
                    canNextPage={canNextPage}
                    nextPage={nextPage}
                />
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        scholarData: state.pendingScholarship,
    };
};

//passing the userData in fetchData function and also dispatch method
const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (data) => dispatch(pendingScholarship(data)),
    };
};

//Connecting the component to our store
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusFeesStudentTable);
