import { Modal } from "react-bootstrap";
import allUrls from '../../redux/constants/url'
import axios from "axios";
import { Calendar, Badge } from "rsuite";
import { useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../assests/common/Loader";
import './styles/Table.css';
import closeButton from '../assests/image/crossButton.svg';

const ViewStudentAttendanceModal = (props) => {
    const token = localStorage.getItem("token");
    const [getedData, setgetedData] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [allDateArray, setDateArray] = useState([]);

    useEffect(() => {
        setLoading(true);
        const getData = async () => {
            var con1 = {
                method: "POST",
                url: allUrls.getAttendanceOfStuentById,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                data: props.data,
            };

            const attendance = await axios(con1)
            if (attendance.status === 200) {
                setgetedData(attendance?.data);
            }
        }
        getData();
        const startDate = new Date(props.data.dateFrom);
        const endDate = new Date(props.data.dateTo);
        setDateArray(createDateArray(startDate, endDate));
        setLoading(false);
    }, [props.data]);


    function createDateArray(startDate, endDate) {
        const dateArray = [];
        const currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');

            const formattedDate = `${year}-${month}-${day}`;
            dateArray.push({ date: formattedDate });
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateArray;
    }

    // const getBadgeCounts = (choice) => {
    //     let presentCount = 0;
    //     let holidayCount = 0;
    //     let liveDayCount = 0;
    //     let notPresentCount = 0;

    //     for (const item2 of getedData) {
    //         const dateExistsInArray1 = allDateArray.find(item1 => item1.date === item2.date);

    //         if (dateExistsInArray1) {
    //             if (item2.is_present !== undefined && item2.is_present === 1) {
    //                 presentCount++;
    //             } else if (item2.is_present !== undefined && item2.is_present === 0) {
    //                 liveDayCount++;
    //             } else if (item2.is_holiday !== undefined && item2.is_holiday === true) {
    //                 const date = new Date(item2.date);
    //                 if (date.getDay() !== 0) {
    //                     holidayCount++;
    //                 }
    //             }
    //         }
    //     }

    //     for (const dateItem of allDateArray) {
    //         const date = new Date(dateItem.date);
    //         if (date.getDay() === 0) {
    //             // const isSundayInGetedData = getedData.find(item => item.date === dateItem.date);
    //             // if (!isSundayInGetedData) {
    //                 holidayCount++;
    //             // }
    //         }
    //     }

    //     notPresentCount = allDateArray.length - (presentCount + holidayCount + liveDayCount);

    //     if (choice === 1) return presentCount;
    //     if (choice === 2) return liveDayCount;
    //     if (choice === 3) return notPresentCount;
    //     if (choice === 4) return holidayCount;
    // };




    const getBadgeCounts = (choice) => {

        const filteredData = getedData.filter(item => {
            const dateExistsInArray1 = allDateArray.find(dateItem => dateItem.date === item.date);
            if (dateExistsInArray1) {
                const date = new Date(item.date);
                if (item.is_holiday === true && date.getDay() === 0) {
                    return false; // Remove if is_holiday is true and the date is Sunday
                }
            }
            return true;
        });

        console.log('FILTERED Data:', filteredData);

        let presentCount = 0;
        let holidayCount = 0;
        let liveDayCount = 0;
        let notPresentCount = 0;
        const countedDates = new Set();



        for (const item2 of filteredData) {
            const dateExistsInArray1 = allDateArray.find(item1 => item1.date === item2.date);

            if (dateExistsInArray1 && !countedDates.has(item2.date)) {
                if (item2.is_present !== undefined && item2.is_present === 1) {
                    presentCount++;
                    countedDates.add(item2.date);
                } else if (item2.is_present !== undefined && item2.is_present === 0) {
                    liveDayCount++;
                    countedDates.add(item2.date);
                } else if (item2.is_holiday !== undefined && item2.is_holiday === true) {
                    const date = new Date(item2.date);
                    if (item2.is_present !== 1 && date.getDay() !== 0) {
                        holidayCount++;
                    } else if (item2.is_present === 1) {
                        // Increment presentCount when is_present is 1 and the day is Sunday
                        presentCount++;
                    }
                }
            }
        }

        for (const dateItem of allDateArray) {
            const date = new Date(dateItem.date);
            if (date.getDay() === 0) {
                const isSundayInGetedData = filteredData.find(item => item.date === dateItem.date);
                if (!isSundayInGetedData) {
                    holidayCount++;
                }
            }
        }

        notPresentCount = allDateArray.length - (presentCount + holidayCount + liveDayCount);

        if (choice === 1) return presentCount;
        if (choice === 2) return liveDayCount;
        if (choice === 3) return notPresentCount;
        if (choice === 4) return holidayCount;
    };

    function getTodoList(date) {
        const dateString = date.toISOString().slice(0, 10);
        const matchingDate = getedData?.find((item) => item.date === dateString);
        console.log('Matched Dates: ', matchingDate, date)

        var maxDate = new Date(Math.max(new Date(props.data.dateTo)));
        var minDate = new Date(Math.min(new Date(props.data.dateFrom)));

        if (matchingDate) {
            if (matchingDate.is_present === 1) {
                // if (matchingDate.is_holiday !== true) {
                //     return [{ time: 'Green Badge' }];
                // }else{
                //     return [{ time: 'Green Badge' }];
                // }
                return [{ time: 'Green Badge' }];
            } else if (matchingDate.is_present === 0) {
                return [{ time: 'Orange Badge' }];
            } else if (matchingDate.is_holiday === true) {
                return [{ time: 'Blue Badge' }];
            }
        } else {
            if (date.getDay() === 0 && matchingDate?.is_holiday !== true) {
                return [{ time: 'Blue Badge' }];
            }
            return [{ time: 'Red Badge' }];
        }

        return [];
    }




    // function getTodoList(date) {
    //     const dateString = date.toISOString().slice(0, 10);
    //     const matchingDate = getedData?.find((item) => item.date === dateString);

    //     var maxDate = new Date(Math.max(new Date(props.data.dateTo)));
    //     var minDate = new Date(Math.min(new Date(props.data.dateFrom)));

    //     if (matchingDate) {
    //         if (matchingDate.is_present === 1 && matchingDate?.is_holiday !== true) {
    //             return [{ time: 'Green Badge' }];
    //         } else if (matchingDate.is_present === 0) {
    //             return [{ time: 'Orange Badge' }];
    //         } else if (matchingDate.is_holiday === true && matchingDate.is_present !== 1) {
    //             return [{ time: 'Blue Badge' }];
    //         }
    //     }
    //     else {
    //         if (date <= maxDate && date >= minDate) {
    //             if (date.getDay() === 0) {
    //                 return [{ time: 'Blue Badge' }];
    //             }
    //             return [{ time: 'Red Badge' }];
    //         }
    //     }

    //     return [];
    // }










    function renderCell(date) {
        const list = getTodoList(date);
        // console.log('list is ', list)
        if (list.length) {
            return <Badge
                className="calendar-todo-item-badge"
                style={{ backgroundColor: list[0].time === 'Green Badge' ? 'rgb(86, 240, 0)' : list[0].time === 'Orange Badge' ? 'rgb(252, 232, 58)' : list[0].time === 'Blue Badge' ? 'blue' : 'red' }}
            />;
        }
        return [];
    }


    if (getedData) {
        // var minDate = new Date(Math.min(...getedData?.map((item) => new Date(item.date))));
        var maxDate = new Date(Math.max(new Date(props.data.dateTo)));
        var minDate2 = new Date(Math.min(new Date(props.data.dateFrom)));
    }

    const getFormatedDate = (date) => {
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0");
        const day = String(inputDate.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }

    const handleSelectDate = (date) => {

        const formatedGetedDate = getFormatedDate(date);
        const formatedTodaysDate = getFormatedDate(new Date());

        if (formatedGetedDate >= formatedTodaysDate) {
            const existingDate = getedData?.find((item) => item.date === formatedGetedDate);
            if (!existingDate) {
                Swal.fire({
                    title: `Confirmation`,

                    html: `Date : ${formatedGetedDate}` +
                        '<hr>' +
                        'Are you sure?' +
                        '<br>' +
                        `You want to Add Leave.`,
                    showCancelButton: true,
                    showConfirmButton: true,
                    cancelButtonText: 'No',
                    confirmButtonText: `Yes `,
                    reverseButtons: true,
                    showCloseButton: true,
                    confirmButtonColor: "#F8A72C",

                    showLoaderOnDeny: true,

                    showClass: {
                        backdrop: 'swal2-noanimation', // disable backdrop animation
                        popup: '',                     // disable popup animation
                        icon: ''                       // disable icon animation
                    },
                    hideClass: {
                        popup: '',                     // disable popup fade-out animation
                    }

                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const addAttendanceApi = async () => {
                            var addAttendanceData = {
                                "userId": props.data.userId,
                                "date": formatedGetedDate,
                                "is_present": 0
                            };

                            const attachmentConfig = {
                                method: 'post',
                                url: allUrls.addLeaveOrAttendance,
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                    'Content-Type': 'application/json'
                                },
                                data: addAttendanceData
                            };

                            try {
                                var result = await axios(attachmentConfig);
                                if (result.status === 200) {
                                    Swal.fire('Leave Updated successfully', '', 'success').then((result) => {
                                        if (result.isConfirmed) {
                                            props.onHide();
                                        }
                                    })
                                }
                            } catch (error) {
                                toast.warn(error.response.data.error, {
                                    position: "top-center",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            }
                        }
                        addAttendanceApi();
                    }
                })
            } else {
                if (existingDate?.is_holiday === true) {
                    Swal.fire(`Holiday In \n Date ${formatedGetedDate}`, '', 'info')
                }
                if (existingDate?.is_present === 1) {
                    Swal.fire(`Attendance Already Taken In \n Date ${formatedGetedDate}`, '', 'info')
                }
                if (existingDate?.is_present === 0) {
                    Swal.fire(`Leave Already Taken In \n Date ${formatedGetedDate}`, '', 'info')
                }

            }
            if (date.getDay() === 0) {
                Swal.fire(`Sunday Holiday In \n Date  ${formatedGetedDate}`, '', 'info')
            }
        }

        if (formatedGetedDate < formatedTodaysDate) {
            const existingDate = getedData?.find((item) => item.date === formatedGetedDate);

            if (!existingDate) {
                Swal.fire({
                    title: `Confirmation`,

                    html: `Date : ${formatedGetedDate}` +
                        '<hr>' +
                        'Are you sure?' +
                        '<br>' +
                        `You want to Add Attendance.`,
                    showCancelButton: true,
                    showConfirmButton: true,
                    cancelButtonText: 'No',
                    confirmButtonText: `Yes `,
                    reverseButtons: true,
                    showCloseButton: true,
                    confirmButtonColor: "#F8A72C",

                    showLoaderOnDeny: true,

                    showClass: {
                        backdrop: 'swal2-noanimation', // disable backdrop animation
                        popup: '',                     // disable popup animation
                        icon: ''                       // disable icon animation
                    },
                    hideClass: {
                        popup: '',                     // disable popup fade-out animation
                    }

                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const addAttendanceApi = async () => {
                            var addAttendanceData = {
                                "userId": props.data.userId,
                                "date": formatedGetedDate,
                                "is_present": 1
                            };

                            const attachmentConfig = {
                                method: 'post',
                                url: allUrls.addLeaveOrAttendance,
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                    'Content-Type': 'application/json'
                                },
                                data: addAttendanceData
                            };

                            try {
                                var result = await axios(attachmentConfig);
                                if (result.status === 200) {
                                    Swal.fire('Attendance Updated successfully', '', 'success').then((result) => {
                                        if (result.isConfirmed) {
                                            props.onHide();
                                        }
                                    })
                                }
                            } catch (error) {
                                toast.warn(error.response.data.error, {
                                    position: "top-center",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            }
                        }
                        addAttendanceApi();
                    }
                })
            } else {
                if (existingDate?.is_holiday === true) {
                    Swal.fire(`Holiday In \n Date ${formatedGetedDate}`, '', 'info')
                }
                if (existingDate?.is_present === 1) {
                    Swal.fire(`Attendance Already Taken In \n Date ${formatedGetedDate}`, '', 'info')
                }
                if (existingDate?.is_present === 0) {
                    Swal.fire(`Leave Already Taken In \n Date ${formatedGetedDate}`, '', 'info')
                }
            }
            if (date.getDay() === 0) {
                Swal.fire(`Sunday Holiday In \n Date ${formatedGetedDate}`, '', 'info')
            }
        }
    }
    return (
        <>
            {Loading ? <Loader /> :
                <>
                    <ToastContainer
                        position="top-center"
                        autoClose={2500}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Modal
                        show={props.show}
                        onHide={props.onHide}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        contentClassName={'calender_modal'}
                    >
                        <Modal.Body>
                            <div style={{ width: '100%', height: '100%' }}>
                                <div className='d-flex justify-content-between align-items-start' style={{ borderBottom: '2px solid #5A607F' }}>
                                    <div className='fw-semibold' style={{ paddingLeft: '34px', fontSize: '28px', color: '#5A607F' }}>
                                        {props.data.stuName?.name}
                                        <p style={{ fontSize: '14px' }}>{`${props.data.stuName?.class}  ( ${props.data.dateFrom} ~ ${props.data?.dateTo})`}</p>
                                    </div>
                                    <div className='text-danger'>
                                        <img src={closeButton} alt='X' onClick={props.onHide} style={{ cursor: 'pointer' }} />
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <div className='d-flex align-items-center mb-2'>
                                        <div className="col d-flex align-items-center" style={{ marginLeft: '20px' }}>
                                            <div style={{ background: 'rgb(86, 240, 0)', height: '10px', width: '10px', borderRadius: '50%' }}></div>
                                            <span className='fw-semibold col' style={{ paddingLeft: '10px', color: '#5A607F' }}>Present</span> <span style={{ color: '#5A607F' }} className='col'>{getBadgeCounts(1)}</span>
                                        </div>
                                        <div className="col d-flex align-items-center">
                                            <div style={{ background: 'rgb(252, 232, 58)', height: '10px', width: '10px', borderRadius: '50%' }}></div>
                                            <span className='fw-semibold col' style={{ paddingLeft: '10px', color: '#5A607F' }}>Leave</span> <span style={{ color: '#5A607F' }} className='col'>{getBadgeCounts(2)}</span>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center mb-4' style={{ marginLeft: '20px' }}>
                                        <div className="col d-flex align-items-center">
                                            <div style={{ background: 'red', height: '10px', width: '10px', borderRadius: '50%' }}></div>
                                            <span className='fw-semibold col' style={{ paddingLeft: '10px', color: '#5A607F' }}>Absent</span> <span style={{ color: '#5A607F' }} className='col'>{getBadgeCounts(3)}</span>
                                        </div>
                                        <div className="col d-flex align-items-center">
                                            <div style={{ background: 'blue', height: '10px', width: '10px', borderRadius: '50%' }}></div>
                                            <span className='fw-semibold col' style={{ paddingLeft: '10px', color: '#5A607F' }}>Holiday</span> <span style={{ color: '#5A607F' }} className='col'>{getBadgeCounts(4)}</span>
                                        </div>
                                    </div>
                                </div>


                                <div className='d-flex justify-content-center align-items-center'>
                                    {(getedData) && !Loading ?
                                        <div style={{ width: 500, borderRadius: '8px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 14px', padding: '20px' }}>
                                            <Calendar
                                                defaultValue={minDate2}
                                                onSelect={handleSelectDate}
                                                compact
                                                bordered
                                                renderCell={renderCell}
                                                disabledDate={(date) => date > maxDate || date < minDate2}
                                            />{' '}
                                        </div> : <div class="ml-1 spinner-border text-warning" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            }
        </>
    )
}

export default ViewStudentAttendanceModal;
