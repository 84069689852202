import * as React from "react";
import { Fragment, useMemo } from "react";

import Edit_icon from "../../assests/image/Edit_icon.svg";
import Swal from "sweetalert2";
import {
  useTable,
  useFilters,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useAsyncDebounce,
} from "react-table";
import rightArrow from "../../assests/image/right_arrow_icon.svg";
import filtericon from "../../assests/image/AccountIcons/filter.svg";

import {
  CDropdown,
  CDropdownMenu,
  CDropdownToggle,
  CPopover,
} from "@coreui/react";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import axios from "axios";
import { connect } from "react-redux";
import Loader from "../../assests/common/Loader";

import "../../components/styles/Table.css";
import { fetchStudentTable } from "../../../redux/actionDispatcher/superAdmin/studentTableDatadispatcher";
import SkeletonColor from "../../../helpers/Skeletrone";
import Archived_icon from "../../assests/image/Archived_icon.svg";
import { TableCheckbox } from "../tableComponents/TableCheckbox";
import { VerifyStudent } from "../../../redux/actionDispatcher/superAdmin/studentVerifyTableDataDispatcher";
// import AddNewStudent from "./AddNewStudent";
import AllUrl from "../../../redux/constants/url";
import updown_sort from "../../assests/image/updown_sort.svg";
import {
  DeactivateButton,
  PendingButton,
  RecievedButton,
} from "../../assests/common/Color";
import Pagination from "../../assests/common/Pagination";
import { useNavigate } from "react-router-dom";
// import { Tooltip, Whisper } from "rsuite";
import NoDataFound from "../../assests/common/NoDataFound";
import { CSVLink } from "react-csv";
import { Tooltip, Whisper } from "rsuite";
import { isStudentAdmin } from "../../../helpers/StudentAdmin";
import { isSuperAdmin } from "../../../helpers/SuperAdmin";
import { isAccountAdmin } from "../../../helpers/AccountAdmin";
// import downloadPdfStudentList from './PdfGeneratorStudentList'

// import { baseUrl } from "../../redux/constants/url";
export const MultipleFilter = (rows, accessor, filterValue) => {
  const arr = [];
  rows.forEach((val) => {
    if (filterValue.includes(val.original[accessor])) arr.push(val);
  });
  return arr;
};

function setFilteredParams(filterArr, val) {
  if (filterArr.includes(val)) {
    filterArr = filterArr.filter((n) => {
      return n !== val;
    });
  } else filterArr.push(val);

  if (filterArr.length === 0) filterArr = undefined;
  return filterArr;
}

function SelectColumnFilter({
  column: { filterValue = [], setFilter, preFilteredRows, id },
}) {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  let offsetObj = [0, 0];

  if (id === "branch") offsetObj = [76, 18];
  else if (id === "trackName") offsetObj = [93, 18];
  else if (id === "year") offsetObj = [33, 18];
  else if (id === "joinBatch") offsetObj = [50, 18];
  else if (id === "isActive") offsetObj = [33, 18];
  else if (id === "gender") offsetObj = [33, 18];
  else if (id === "ssismGroup") offsetObj = [60, 18];

  let name = id;

  switch (id) {
    case "isActive":
      name = "Student Status";
      break;
    case "year":
      name = "Select Year";
      break;

    case "branch":
      name = "Select Class";
      break;

    case "joinBatch":
      name = "Select Session";
      break;
    case "trackName":
      name = "Select Track";
      break;
    case "gender":
      name = "Select Gender";
      break;
    case "ssismGroup":
      name = "Select Group";
      break;

    default:
      break;
  }

  return (
    <Fragment>
      <div className="d-flex justify-content-end">
        {/* <span className="block capitalize mb-4">{id}</span> */}
        <CPopover
          // trigger={['focus','click','hover']}
          offset={offsetObj}
          content={
            <div className="">
              {options.map((option, i) => {
                let option_label = option;

                if (id === "isActive") {
                  if (option === "true") option_label = "Active";
                  else option_label = "Deactive";
                } else if (id === "year") {
                  if (option === "I") option_label = "I Year";
                  else if (option === "II") option_label = "II Year";
                  else if (option === "III") option_label = "III Year";
                } else if (id === "gender") {
                  if (option === "female") option_label = "Female";
                  else if (option === "male") option_label = "Male";
                } else if (id === "trackName") {
                  if (option === null) {
                    option_label = "Not Available";
                  }
                } else if (id === "ssismGroup") {
                  if (option === null || option === "none") {
                    option_label = "Other";
                  }
                }

                return (
                  <Fragment key={i}>
                    <div
                      id={`${id}`}
                      style={{ height: "30px", cursor: "pointer" }}
                      className="filter_btn_hover p-1 pt-2 my-1 d-flex align-items-center "
                    >
                      <label
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="font-medium text-gray-700 d-flex align-items-center cursor-pointer"
                        // onCLick={}
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          checked={filterValue.includes(option)}
                          type="checkbox"
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-1"
                          id={option}
                          name={option}
                          value={option}
                          style={{ cursor: "pointer" }}
                          onChange={(e) => {
                            setFilter(
                              setFilteredParams(filterValue, e.target.value)
                            );
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        ></input>

                        {option_label}
                      </label>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          }
          placement="right"
        >
          <div className="btn-group filter_btn_hover">
            <button
              onClick={(e) => {
                // document.getElementsByClassName('filter_btn').forEach(ele => ele.click())
              }}
              className="btn filter_btn"
            >
              {name}
            </button>
            <img
              src={rightArrow}
              alt=">"
              width="6px"
              style={{
                marginTop: "4px",
                marginRight: "10px",
              }}
            />
          </div>
        </CPopover>
      </div>
    </Fragment>
  );
}

// Define a default UI for filtering
function GlobalFilter({ filter, setFilter, preGlobalFilteredRows }) {
  // const count = preGlobalFilteredRows.length;
  // const [value, setValue] = React.useState(filter);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 1);

  return (
    <>
      <input
        style={{
          width: "270px",
          height: "41px",
          outline: "none",
          border: "1px solid #7979792b",
          padding: "5px",
          borderRadius: "4px",
          paddingLeft: "12px",
        }}
        type="search"
        value={filter || ""}
        onChange={(e) => {
          // setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Search..."
      />
      <i
        style={{
          marginLeft: "-31px",
          alignSelf: "center",
          marginBottom: "7px",
          color: "rgb(90, 96, 127,0.7)",
        }}
        className="fas fa-search"
      ></i>
    </>
  );
}

function StudentTable({ table_data, fetchStudentTable, VerifyStudent }) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const backToUpdatePage = (e) => {
    // e.preventDefault()
    if (isAccountAdmin()) {
      // console.log("Navigated ");

      navigate("/account_admin_dashboard/addnewstudent");
    } else if (isStudentAdmin()) {
      // console.log("Navigated ");

      navigate("/student_admin_dashboard/addnewstudent");
    } else if (isSuperAdmin()) {
      // console.log("Navigated ");
      navigate("/admin_dashboard/addnewstudent");
    }
  };

  const columns = React.useMemo(
    () => [
      {
        header: "S.No",
        accessor: "Srno",
        Cell: ({ row: { original, index } }) => {
          return index + 1;
        },
        Filter: "",
        filter: "",
      },
      {
        header: "Name",
        accessor: "firstName",
        Cell: ({ row: { original, index } }) => {
          return `${original.firstName} ${original.lastName}`;
        },
        Filter: "",
        filter: "",
      },
      {
        header: "Father's Name",
        accessor: "fathersName",
        Filter: "",
        filter: "",
      },
      {
        header: "Branch",
        accessor: "branch",
        Filter: SelectColumnFilter,
        filter: MultipleFilter,
      },
      {
        header: "Year",
        accessor: "year",
        Filter: SelectColumnFilter,
        filter: MultipleFilter,
      },
      {
        header: "Village",
        accessor: "village",
        Filter: SelectColumnFilter,
        filter: MultipleFilter,
      },
      {
        header: "Mobile",
        accessor: "mobile",
        Filter: "",
        filter: "",
      },
      {
        header: "Receiver",
        accessor: "receiver",
        Filter: SelectColumnFilter,
        filter: MultipleFilter,
      },
      {
        header: "Pay status",
        accessor: "accRegFeesStatus",
        Cell: ({ row: { original } }) => (
          <button
            className="table_btn_size"
            style={
              original.accRegFeesStatus === "Paid"
                ? RecievedButton
                : PendingButton
            }
            disabled={original.accRegFeesStatus === "Paid"}
            onClick={() => {
              Swal.fire({
                title: "Payment Confirmation",

                html: "<hr>" + "have you received the payment ",
                // icon: 'warning',
                showCancelButton: true,
                // showCancelButton: true,
                cancelButtonText: "Cancel",
                confirmButtonText:
                  "<h8 style='color:rgb(5, 106, 12);font-size: 18px'>Yes</h8>",
                showCloseButton: true,
                cancelButtonColor: "gray",
                confirmButtonColor: "rgb(164, 230, 177)",
                confirmButtonTextColor: "black",
                reverseButtons: true,
                showClass: {
                  backdrop: "swal2-noanimation", // disable backdrop animation
                  popup: "", // disable popup animation
                  icon: "", // disable icon animation
                },
                hideClass: {
                  popup: "", // disable popup fade-out animation
                },
              }).then(async (result) => {
                if (result.isConfirmed) {
                  var body = JSON.stringify({
                    email: original.email,
                  });

                  var config = {
                    method: "post",
                    url: `${AllUrl.verifyStudentAccountPaidUnpaid}`,
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                      "Content-Type": "application/json",
                    },
                    data: body,
                  };

                  const result = await axios(config);
                  // // console.log("_____________________________________", result);
                  if (result.status === 200) {
                    toast.success("Registration Fees Paid SuccessFull", {
                      position: "top-center",
                      autoClose: 2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: undefined,
                    });
                    var con = {
                      method: "GET",
                      url: AllUrl.allRegistratedStudentAccountList,
                      headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                      },
                    };
                    fetchStudentTable(con, true);
                  } else if (result.status === 404) {
                    toast.warning("User Not Found", {
                      position: "top-center",
                      autoClose: 2000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: undefined,
                    });
                  }
                }
              });
            }}
          >
            {original.accRegFeesStatus === "Paid" ? "Received" : "Pending"}
          </button>
        ),
        Filter: SelectColumnFilter,
        filter: MultipleFilter,
      },
      {
        header: "Action",
        accessor: "status",
        Cell: ({ row: { original } }) => (
          <button
            className="table_btn_size"
            style={DeactivateButton}
            onClick={() => {
              // setData(original.status)
              if (original.accRegFeesStatus === "Paid") {
                // console.log(original.email);
                Swal.fire({
                  title: "Shift to account",

                  html:
                    "<hr>" +
                    "do you want to shift" +
                    "<br>" +
                    ` ${original.firstName} ${original.lastName} ?` +
                    "<hr>" +
                    "<h5> Confirm Fees Structure </h5>" +
                    "<br>" +
                    `
                  <div style="display:flex;justify-content: space-around;">
                  <span> First Installment</span>
                  <span>Total Fees</span>
                  </div>
                  <div style="display:flex;justify-content: space-around;">
                  <span>   ${original.firstInstallment}</span>
                  <span>${original.totalFee}</span>
                  </div>` +
                    "<hr>" +
                    `<div style="display:flex;justify-content: space-around;">
                  <span> Second Installment</span>
                  <span>Bus Fees</span>
                  </div>
                  <div style="display:flex;justify-content: space-around;">
                  <span>   ${original.secondInstallment}</span>
                  <span>${original.busFee}</span>
                  </div>` +
                    "<hr>" +
                    `
                  <div style="display:flex;justify-content: space-around;">
                  <span> Third Installment</span>
                  <span> Scholarship Amount</span>
                  </div>
                  <div style="display:flex;justify-content: space-around;">
                  <span>   ${original.thirdInstallment}</span>
                  <span>${original.scholarshipAmount}</span>
                  </div>`,
                  // icon: 'warning',
                  showCancelButton: true,
                  // showCancelButton: true,
                  cancelButtonText: "Cancel",
                  confirmButtonText: "Yes",
                  showCloseButton: true,
                  cancelButtonColor: "gray",
                  confirmButtonColor: "#4f83df",
                  reverseButtons: true,
                  showClass: {
                    backdrop: "swal2-noanimation", // disable backdrop animation
                    popup: "", // disable popup animation
                    icon: "", // disable icon animation
                  },
                  hideClass: {
                    popup: "", // disable popup fade-out animation
                  },
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    // alert('ok')
                    const resultofverify = await VerifyStudent(original);
                    // // console.log(resultofverify);
                    if (resultofverify === 200) {
                      var config = {
                        method: "GET",
                        url: AllUrl.allRegistratedStudentAccountList,
                        headers: {
                          Authorization: `Bearer ${token}`,
                          "Content-Type": "application/json",
                        },
                      };
                      fetchStudentTable(config, true);
                      window.location.reload();
                    }
                  }
                });
                // alert("Do you want to change this   : " + original.email)
                // VerifyStudent(original.email);
              } else {
                toast.warning("Firstly Pay Registration Fees", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                });
              }
            }}
          >
            Shift
          </button>
        ),
        Filter: "",
        filter: "",
      },
      {
        header: "Edit",
        accessor: "icon",
        Cell: ({ row: { original } }) => (
          // <i onClick={() => {alert("hii")}} class="far fa-edit"></i>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Edit Student Info</Tooltip>}
          >
            <img
              src={Edit_icon}
              style={{ cursor: "pointer" }}
              alt="Edit"
              onClick={() => {
                // navigate("/admin_dashboard/addnewstudent");

                backToUpdatePage();
                localStorage.setItem(
                  "RegistrationEdit",
                  JSON.stringify(original)
                );
              }}
            />
          </Whisper>
        ),
        Filter: "",
        filter: "",
      },
    ],
    []
  );

  React.useEffect(() => {
    var config = {
      method: "GET",
      url: AllUrl.allRegistratedStudentAccountList,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    fetchStudentTable(config, false);
    // setTableData(table_data.table_data);
    // eslint-disable-next-line
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    nextPage,
    previousPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    canNextPage,
    setPageSize,
    pageOptions,
    selectedFlatRows,
    state,
    setGlobalFilter,
    // exportData,
    preGlobalFilteredRows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: table_data.table_data,
      // getExportFileBlob,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    // useExportData,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            header: ({ getToggleAllRowsSelectedProps }) => (
              <TableCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <TableCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const { globalFilter, pageSize, pageIndex } = state;
  var exportCsv = [];

  const checkboxData = JSON.stringify(
    {
      selectedFlatRows: selectedFlatRows.forEach((row) => {
        let data = Object.assign({}, row.original);
        // console.log(data);
        delete data.photo;
        if (data?.receivedAmount)
          data.receivedAmount = data?.receivedAmount?.toString();
        // console.log(data);
        // exportData.push(data)
        // // console.log(selectedData);
        exportCsv.push(data);
      }),
    },
    null,
    2
  );
  // console.log(checkboxData);
  return table_data.loading ? (
    <SkeletonColor></SkeletonColor>
  ) : (
    // : table_data.error ? (
    //   <h2>{table_data.error}</h2>
    // )
    <>
      {table_data.secondLoading && <Loader />}
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        style={{ backgroundColor: "#F4F7FC", height: "auto", width: "auto" }}
      >
        <div
          style={{
            position: "sticky",
            top: "80px",
            width: "100%",
            paddingTop: "10px",
            paddingBottom: "10px",
            backgroundColor: "#f4f7fc",
            zIndex: "5",
          }}
        >
          <div className="d-flex">
            <div className="">
              <select
                className="form-select table_select_row_options"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 25, 50, 100].map((pageSize) => (
                  <option value={pageSize} key={pageSize}>
                    Show Entries {pageSize}
                  </option>
                ))}
              </select>
            </div>

            {/* ===========  Archived button ============= */}
            <div className=" ml-4 ">
              <button
                type="button"
                className="btn  fw-bold fees-structure-active-button "
              >
                Archive <img src={Archived_icon} alt="downloadIcon" />
              </button>
            </div>

            {/* =================== Download to pdf or excel ================ */}
            <div
              className="btn-group  ml-3"
              style={{ position: "sticky", zIndex: "10" }}
            >
              <button
                className="btn  btn-sm download-btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Download
              </button>
              <div class="dropdown-menu mt-1">
                <div>
                  <div>
                    <CSVLink
                      className="dropdown-item"
                      style={{ fontWeight: "bold" }}
                      data={exportCsv}
                    >
                      Excel
                    </CSVLink>
                  </div>{" "}
                </div>

                {/* <div className="dropdown-item" onClick={() => { downloadPdf(exportCsv) }}><b>Pdf</b></div> */}

                {/* <div className="dropdown-item " style={{ cursor: "pointer" }} onClick={() => { downloadPdfStudentList(exportCsv) }}><b>Pdf</b></div> */}
              </div>
            </div>

            <div className="d-flex ml-auto me-3">
              <div
                className="d-flex mr-2"
                style={{
                  height: "40px",
                  width: "42px",
                  backgroundColor: "#fff",
                  borderRadius: "3px",
                  border: "1px solid #EDEDED",
                }}
              >
                <CDropdown variant="nav-item" style={{ color: "white" }}>
                  <CDropdownToggle
                    placement="bottom-end"
                    className="py-0"
                    caret={false}
                  >
                    <Whisper
                      placement="top"
                      controlId="control-id-hover"
                      trigger="hover"
                      speaker={<Tooltip>Filter Data .</Tooltip>}
                    >
                      <img
                        src={filtericon}
                        alt=""
                        style={{
                          height: "22px",
                          width: "35px",
                          marginTop: "-35px",
                          marginLeft: "-13px",
                        }}
                      />
                    </Whisper>
                  </CDropdownToggle>

                  <CDropdownMenu
                    component={"div"}
                    className="pt-0 filter-dropdown-menu-student_account_table"
                    placement="bottom-end"
                  >
                    <div className="p-lg-2 pb-0">
                      {headerGroups.map((headerGroup) => (
                        <div
                          style={{ display: "flex flex-column" }}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map((column, i) => (
                            <div
                              key={i}
                              style={{
                                display: "block",
                                justifyContent: "center",
                              }}
                            >
                              {column.canFilter
                                ? column.render("Filter")
                                : null}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </CDropdownMenu>
                </CDropdown>
              </div>
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                filter={globalFilter}
                setFilter={setGlobalFilter}
              />
            </div>
          </div>
        </div>
        <table {...getTableProps()} id="customers" className="table table-sm">
          {/* <thead style={{ position: 'sticky', top: '135px', width: '100%', backgroundColor: '#f4f7fc', zIndex: '5' }}> */}
          <thead
            style={{
              position: "sticky",
              top: "135px",
              width: "100%",
              backgroundColor: "#f4f7fc",
            }}
          >
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i
                            style={{ transform: "scale(0.6)" }}
                            className="fas fa-chevron-down"
                          ></i>
                        ) : (
                          <i
                            style={{ transform: "scale(0.6)" }}
                            className="fas fa-chevron-up"
                          ></i>
                          // <img src={updown_sort} style={{ marginLeft: "5px" }} alt="" />
                        )
                      ) : (
                        column.id !== "Srno" &&
                        column.id !== "selection" && (
                          <img
                            src={updown_sort}
                            style={{ marginLeft: "5px" }}
                            alt=""
                          />
                        )
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")} </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <NoDataFound rows={rows} />
        <Pagination
          data={rows}
          rows={rows}
          page={page}
          pageIndex={pageIndex}
          pageCount={pageCount}
          pageSize={pageSize}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          pageOptions={pageOptions}
          gotoPage={gotoPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    table_data: state.studentTableData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStudentTable: (data, isLoading) =>
      dispatch(fetchStudentTable(data, isLoading)),
    VerifyStudent: (data) => dispatch(VerifyStudent(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentTable);
