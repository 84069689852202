import jsPDF from 'jspdf';
import ssismLogo from '../../assests/image/logoimage.png'
import './Poppins-Bold-bold'
import './Poppins-SemiBold-normal'

const generatePdf = async (data, Session) => {


    // console.log(data)
    function chunk(array, count) {
        if (count == null || count < 1) return [];
        var result = [];
        var i = 0, length = array.length;
        while (i < length) {
            result.push(Array.prototype.slice.call(array, i, i += count));
        }
        return result;
    };
    var newList = chunk(data, 10);
    // // console.log(newList)
    const date = new Date()


    var doc = new jsPDF('landscape', 'px', [1366, 768]);
    var width = doc.internal.pageSize.getWidth()
    // var height = doc.internal.pageSize.getHeight()
    doc.setFillColor('#F4F7FC');
    doc.rect(0, 0, width, 94, 'F')
    doc.addImage(ssismLogo, 20, 23, 150, 48)
    doc.setFontSize(26);
    doc.setFont('Poppins', 'bold')
    doc.text('Daily Report', (width / 2) - 42, (94 / 2) - 15)
    doc.setFontSize(17);
    doc.text(`Session -   ${' ' + date.getFullYear() - Session }-${(date.getFullYear() + 1  - Session) - 2000}`, (width / 2) - 42, (94 / 2) + 17)

    doc.setFontSize(18);
    doc.setFont('Poppins-SemiBold', 'normal');
    doc.text(`Print Date ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`, (width) - 200, (94 / 2) - 15)
    doc.text(`Number of Student - ${data?.length}`, (width) - 200, (94 / 2) + 17)

    doc.setFillColor('#F4F7FC');
    doc.rect(0, 107, width, 57, 'F');

    const xPosition = 0;
    doc.setFont('Poppins-SemiBold', 'normal');
    doc.setFontSize(20);
    // console.log(doc.getFontList())
    doc.text('S.No', xPosition + 48, 140)
    doc.text('Name', xPosition + 141, 140)
    doc.text('Father Name', xPosition + 309, 140)
    doc.text('Stream', xPosition + 525, 140)
    doc.text('Village', xPosition + 660, 140)
    doc.text('Installment No', xPosition + 760, 140)
    doc.text('Received Amount', xPosition + 900, 140)
    doc.text('Paid Date', xPosition + 1082, 140)
    doc.text('Challan No.', xPosition + 1238, 140)

    doc.setFontSize(18);
    // doc.setTextColor('#595F7E')



    let yPosition = 200;
    let H = 60;
    let footerHeight = 250;
    let ReceivedAmount = 0;
    let installmentAmount = {
        "1": 0,
        "2": 0,
        "3": 0,
        "POSTMETRIC": 0,
        "GKB": 0,
        "WAIVEOFF": 0,
    }

    let yearWiseAmount = {
        "I": 0,
        "II": 0,
        "III": 0,
    }


     
    const crf = rs => {
        return parseInt(rs) > 0 ? (parseInt(rs)).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
        }) : 0;
    }
    let count = 1;
    newList.forEach((data, pageNO) => {



        if ((pageNO + 1) > 1) {
            doc.addPage();
            yPosition = 115;
            H = 60;
            footerHeight = 120;

        }
      

        data.forEach((ele, i) => {
            footerHeight += H;
            ReceivedAmount += parseInt(ele.receivedAmount);
            doc.text((count++).toString(), xPosition + 48 + 3, yPosition + (i * H))
            doc.text((ele.name)?.toString(), xPosition + 141, yPosition + (i * H))
            doc.text(ele.fathersName, xPosition + 309, yPosition + (i * H))
            doc.text((ele['stream(year)']), xPosition + 525, yPosition + (i * H))
            doc.text(ele.village, xPosition + 660, yPosition + (i * H))
            doc.text(ele.installmentNo, xPosition + 810, yPosition + (i * H))
            doc.text((ele.receivedAmount)?.toString(), xPosition + 940, yPosition + (i * H))
            doc.text(`${ele.accountsReceiptDate.split('-').reverse().join('-')}`, xPosition + 1082, yPosition + (i * H))
            // doc.text('-', xPosition + 1238, yPosition + (i * H))
            installmentAmount[ele.installmentNo] += parseInt(ele.receivedAmount);
            yearWiseAmount[ele['receiptYear']] += parseInt(ele.receivedAmount);

        });
    })

    doc.setFillColor('#F4F7FC');
    doc.rect(0, footerHeight - 56, width, 56, 'F')
    // doc.text(`Amounts: 1st: ${crf(installmentAmount[1])}/-          2nd: ${crf(installmentAmount[2])}/-          3rd: ${crf(installmentAmount[3])}/-          Post: ${crf(installmentAmount.POSTMETRIC)}/-          gbk: ${crf(installmentAmount.GKB)}/-          Total Amount: ${crf(ReceivedAmount.toString())}/-`, xPosition + 48, footerHeight - 23)
    // doc.text(``, xPosition + 897, footerHeight - 23)
    console.log(installmentAmount)
    console.log(yearWiseAmount)
    doc.text(`Year Wise Amounts: 1st Year: ${crf(yearWiseAmount.I)}/-          2nd Year: ${crf(yearWiseAmount.II)}/-          3rd Year: ${crf(yearWiseAmount.III)}/-          Total Amount: ${crf(ReceivedAmount.toString())}/-`, xPosition + 48, footerHeight - 23)

    doc.save(`dailyReport_${date.getFullYear() - Session }-${(date.getFullYear() + 1 - Session) - 2000}-PrintDate-${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}.pdf`);


}

const downloadPdf = (data) => {
    // // console.log(data);
    let arr1 = [];
    let arr2 = [];
    let arr3 = [];
    data.forEach(ele => {
        switch (ele.lastSession) {
            case "1":
                arr1.push(ele)
                break;
            case "2":
                arr2.push(ele)
                break;
            case "3":
                arr3.push(ele)
                break;
            default:
                arr1.push(ele);
                break;
        }
    });
    if (arr1.length > 0) generatePdf(arr1, 0);
    if (arr2.length > 0) generatePdf(arr2, 1);
    if (arr3.length > 0) generatePdf(arr3, 2);

}

export default downloadPdf;