import { CButton, CModal, CModalBody } from "@coreui/react";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import AllUrl from "../../redux/constants/url";
import LoaderButton from "../assests/common/LoaderButton";
import crossButton from "../assests/image/crossButton.svg";
import error_Icon_Green from "../assests/image/error_icon.svg";
import success_icon from "../assests/image/success_icon.png";
import NumberFormat from "react-number-format";

function BankDepositAddPopup({ setDate1, date1 }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [names, setNames] = useState([]);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  const validationSchema = Yup.object().shape({
    date: Yup.date().required("Please select a date."),
    amount: Yup.string()
      .required("Please enter an amount."),
      remark: Yup.string().required("Please enter a depositer name."),
    bankName: Yup.string().required("Please select a bank."),
  });
  useEffect(() => {
    const fetchNames = async () => {
      try {
        const response = await axios.get(AllUrl.getBankNames, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });

        // Map through the entire response data array
        const bankNames = response?.data.map((item) => ({
          id: item.id,
          name: item.bankName,
        }));

        // Set the bankNames array to state
        setNames(bankNames);
      } catch (error) {
        console.error("Error fetching bank names:", error);
        setNames(["Error fetching data"]);
      }
    };

    fetchNames();
  }, []);

  const formik = useFormik({
    initialValues: {
      date: new Date().toISOString().split("T")[0],
      amount: "",
      remark: "",
      bankName: "AXIS Bank",
      feeType: "Tution",
    },
    validationSchema,

    onSubmit: async (values) => {
      setLoading(true);
      try {
        const payload = {
          date: values.date,
          amount: values.amount.split(',').join(''),
          remark: values.remark,
          bankName: values.bankName,
          feeType: values.feeType,
        };
        
        const response = await axios.post(AllUrl.depositAmount, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        if (response.status === 201) {
          setSuccessModalVisible(true);
          setVisible(false);
          setDate1({ ...date1, c: Math.random() });
        } else {
          setErrorModalVisible(true);
        }
      } catch (error) {
        console.error("Error adding deposit:", error);
        setErrorModalVisible(true);
        setVisible(false);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div>
      <CButton
        style={{
          backgroundColor: "#F7922A",
          color: "white",
          outline: "none",
          border: "none",
          fontWeight: "bold",
          marginLeft: "5px",
          marginRight: "5px",
          marginTop: "3px",
        }}
        onClick={() => setVisible(!visible)}
      >
        <i className="fas fa-plus"></i>
      </CButton>
      <CModal
        alignment="center"
        padding="1rem"
        visible={visible}
        onClose={() => {
          formik.resetForm();
          setVisible(false);
        }}
      >
        <CModalBody style={{ width: "136%" }}>
          <div className="first_div ">
            <div className="second_div" style={{ marginTop: "0px" }}>
              <form style={{ width: "151%", marginLeft: "-99px" }}>
                <div>
                  <img
                    onClick={() => {
                      formik.resetForm();
                      setVisible(!visible);
                    }}
                    style={{
                      height: "20px",
                      marginLeft: "86%",
                      marginTop: "-1px",
                      cursor: "pointer",
                    }}
                    src={crossButton}
                    alt="close"
                    className="logo_img"
                  />
                  <h4
                    className="text-aligns-center createAdminhead"
                    style={{ color: "#5A607F", fontWeight: "bold" }}
                  >
                    Add Bank Deposit
                  </h4>
                </div>
                <div>
                  <label htmlFor="date" className="labels mb-1">
                    Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="date"
                    name="date"
                    value={formik.values.date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.date && formik.touched.date ? (
                    <div className="text-danger fs-6">{formik.errors.date}</div>
                  ) : null}

                  <label className="labels mb-1" style={{ marginTop: "10px" }}>
                    Fee Type
                  </label>

                  <div className="form-check">
                    <input
                      type="radio"
                      id="tuitionFees"
                      name="feeType"
                      value="Tuition"
                      className="form-check-input"
                      checked={formik.values.feeType === "Tution"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor="tuitionFees" className="form-check-label">
                      Tuition Fees
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      id="busFees"
                      name="feeType"
                      value="Bus"
                      className="form-check-input"
                      checked={formik.values.feeType === "Bus"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor="busFees" className="form-check-label">
                      Bus Fees
                    </label>
                  </div>
                  <label htmlFor="amount" className="labels mb-1">
                    Amount
                  </label>
                  <NumberFormat
// /type="number"
                    className="form-control"
                    id="amount"
                    name="amount"
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter amount"
                    thousandSeparator={true}
                    thousandsGroupStyle='lakh'
                  />
                  {formik.errors.amount && formik.touched.amount ? (
                    <div className="text-danger fs-6">
                      {formik.errors.amount}
                    </div>
                  ) : null}

                  <label htmlFor="remark" className="labels mb-1">
                    Depositer Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="remark"
                    name="remark"
                    value={formik.values.remark}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Depositer Name"
                  />
                  {formik.errors.remark && formik.touched.remark ? (
                    <div className="text-danger fs-6">
                      {formik.errors.remark}
                    </div>
                  ) : null}

                  <label htmlFor="bankName" className="labels mb-1">
                    Select Bank Name
                  </label>
                  <select
                    name="bankName"
                    className="form-select text-secondary border-secondary"
                    value={formik.values.bankName || "AXIS Bank"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="bankName"
                    type="text"
                    style={{ fontSize: "large" }}
                  >
                    {names.map((bank, id) => (
                      <option
                        key={id}
                        value={bank.name}
                        className="form-select"
                        style={{ color: "#5A607F" }}
                      >
                        {bank.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    marginTop: "15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "-6px",
                      gap: "10px",
                    }}
                  >
                    <button
                      disabled={loading}
                      style={{ height: "39px", flex: "1.1" }}
                      className="submit_btn btn-md text-light font-weight-bold"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();

                        if (
                          !formik.values.date ||
                          !formik.values.amount ||
                          !formik.values.remark
                        ) {
                          formik.setTouched({
                            ...formik.touched,
                            date: true,
                            amount: true,
                            remark: true,
                          });
                        } else {
                          setLoading(true);
                          formik.handleSubmit();
                        }
                      }}
                    >
                      {loading ? <LoaderButton /> : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </CModalBody>
      </CModal>
      <CModal
        alignment="center"
        className="custom-success-modal"
        padding="1rem"
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
      >
        <CModalBody className="success-error-model">
          <div className="text-center">
            <img
              src={success_icon}
              style={{ maxWidth: "100%" }}
              height={"53%"}
              alt=""
              className=" w-25 mt-md-4"
            />
            <h5 style={{ color: "#00FF00", fontSize: "1.6rem" }}>Success</h5>
            <p style={{ fontSize: "1.3rem" }}>Deposit added successfully!</p>
            <button
              className="custom-success-button"
              onClick={() => setSuccessModalVisible(false)}
            >
              OK
            </button>
          </div>
        </CModalBody>
      </CModal>

      <CModal
        alignment="center"
        className="custom-error-modal"
        padding="1rem"
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
      >
        <CModalBody className="success-error-model">
          <div className="text-center">
            <img
              src={error_Icon_Green}
              style={{ maxWidth: "100%" }}
              height={"53%"}
              alt=""
              className=" w-25 mt-md-4"
            />
            <h5 style={{ color: "#FF0000", fontSize: "1.6rem" }}>Error</h5>
            <p style={{ fontSize: "1.3rem" }}>
              Something went wrong, please try again!
            </p>
            <button
              className="custom-error-button"
              onClick={() => setErrorModalVisible(false)}
            >
              OK
            </button>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
}

export default BankDepositAddPopup;
