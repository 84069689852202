import react, { useEffect } from 'react';



const IframeLink = ({ url }) => {


//     useEffect(() => {
//       // Click handler to redirect to the main site useEffect(() => {
//     const iframe = document.getElementById('myIframe');

//     const handleClickInIframe = (event) => {
//       // Check if the click target is a link
//       console.log(event.target);
//       if (!event.target.closest('a') && !event.target.closest('button')) {
//         window.location.href = '/'; // Redirect to the main page
//       }
//     };

//     // Listen for clicks inside the iframe
//     iframe.contentWindow.addEventListener('click', handleClickInIframe);

//     return () => {
//       iframe.contentWindow.removeEventListener('click', handleClickInIframe);
//     };
//   }, []);

    return (
        <>
            <iframe id="myIframe" style={{ width: '100%', height: '100vh' }} src={url}></iframe>
        </>
    )
}


export default IframeLink;