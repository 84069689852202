import {Modal} from 'react-bootstrap';

// eslint-disable-next-line react/prop-types
export const ShowError = ({type,error,onClick,modalShow}) => {

    return (
        <div className=' w-100 h-100 d-flex justify-content-center align-items-center'>
            <Modal
                show={modalShow}
                size="md"

                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={onClick}
                className=' m-auto'
            >
                <Modal.Header closeButton > {type} </Modal.Header>
                <Modal.Body style={{width: '100%'}}>
                    <p className=' '>
                        {error}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={onClick} className=' btn  btn-outline-dark '> Close </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
