
import React,{useState} from 'react';
import {jsPDF} from 'jspdf';
import QRCode from 'qrcode';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {capitalizeFirstLetter} from './utils/capitalizeFirstLetter.js';
import MEG_BG from './images/MEG_S.jpg';
import BEG_BG from './images/BEG_S.jpg';
import ITEG_BG from './images/ITEG_S2.jpg';

const Beg_Meg_Iteg_Id_card = () => {
    const initialValues = {
        studentName: '',
        fatherName: '',
        branch: 'BCA',
        mobileNumber: '',
        adhaar: '',
        photo: null,
        session: '2024-27',
        category: 'ITEG',
    };

    const validationSchema = Yup.object().shape({
        studentName: Yup.string().required('Student name is required'),
        fatherName: Yup.string().required('Father name is required'),
        branch: Yup.string().required('Branch is required'),
        mobileNumber: Yup.string()
            .matches(/^[0-9]{10}$/,'Mobile number must be 10 digits')
            .required('Mobile number is required'),
        adhaar: Yup.string()
            .matches(/^\d{4}-\d{4}-\d{4}$/,'Adhaar must be in format XXXX-XXXX-XXXX')
            .required('Adhaar number is required'),
        photo: Yup.mixed().required('Photo is required'),
        session: Yup.string().required('Session is required'),
        category: Yup.string().required('Category is required'),
    });

    const [photoData,setPhoto] = useState();

    const handlePhoto = e => {
        console.log(e);
        const file = e.target.files[0];
        setPhoto(file);
    };

    const generateQR = async text => {
        text = text.split('-').join('');
        try {
            const opts = {
                errorCorrectionLevel: 'H',
                type: 'image/png',
                quality: 1,
                margin: 0,
                color: {
                    dark: '#000000',
                },
            };
            return await QRCode.toDataURL(text,opts);
        } catch(err) {
            console.error(err);
        }
    };

    const print = async data => {
        // e.preventDefault();
        console.log("ADhaar is ",data.adhaar)
        const QR = await generateQR(data.adhaar);

        const doc = new jsPDF('l','mm',[84,54]);
        if(data.category.toUpperCase() === 'BEG') {
            doc.addImage(BEG_BG,'png',0,0,84,54);
        } else if(data.category.toUpperCase() === 'MEG') {
            doc.addImage(MEG_BG,'png',0,0,84,54);
        } else if(data.category.toUpperCase() === 'ITEG') {
            doc.addImage(ITEG_BG,'jpeg',0,0,84,54);
        }
        doc.setFont(undefined,'bold');
        doc.setFontSize(8);
        doc.text(data.session,45,15);

        doc.setFontSize(7);
        doc.text(data.studentName,45,19.5);
        doc.text(data.fatherName,45,24);
        doc.text(data.branch,45,28.5);
        doc.text(data.mobileNumber,45,32.5);
        console.log("Photo Data is ",photoData)
        if(data.category.toUpperCase() === 'ITEG') {
            doc.addImage(QR,'png',64,28.5,16.5,16.5);
        } else {
            doc.addImage(QR,'png',63.5,29.2,16.5,16.5);
        }

        const imgData = await convertImageToBase64(photoData);
        doc.addImage(imgData,'jpeg',4,14.5,17.5,21.55);

        doc.save(data.studentName + '.pdf');
    };

    const convertImageToBase64 = file => {
        return new Promise((resolve,reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            // handlePhoto(values.photo);
            print({...values,studentName: capitalizeFirstLetter(values.studentName),fatherName: capitalizeFirstLetter(values.fatherName)});
            // print();
        },
    });

    return (
        <div className=" w-100  d-flex justify-content-center align-items-center" style={{height: "calc(100vh - 150px)"}}>
            <form className=' w-75  mx-auto py-5 bg-white rounded-2 shadow-sm' onSubmit={formik.handleSubmit}>
            <div className="row m-0 p-0 mb-3">
                <div className="col-5 offset-1">
                    <label htmlFor="studentName" className="addStdLable">
                        Student Name*
                    </label>
                    <input
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.studentName}
                        name="studentName"
                        type="text"
                        className={formik.touched.studentName ? `form-control ${formik.errors.studentName ? "invalid" : ""}` : 'form-control'}
                        // className={formik.touched.studentName && formik.errors.studentName ? 'form-control is-invalid' : 'form-control'}
                        placeholder="Enter student name"
                    />
                    {formik.touched.studentName && formik.errors.studentName ? (
                        <div className="text-danger fs-6">{formik.errors.studentName}</div>
                    ) : null}
                </div>
                <div className="col-5 ">
                    <label htmlFor="fatherName" className="addStdLable">
                        Father's Name*
                    </label>
                    <input
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.fatherName}
                        name="fatherName"
                        className={formik.touched.fatherName ? `form-control ${formik.errors.fatherName ? "invalid" : ""}` : 'form-control'}
                        type="text"
                        // className={formik.touched.fatherName && formik.errors.fatherName ? 'form-control is-invalid' : 'form-control'}
                        placeholder="Enter father's name"
                    />
                    {formik.touched.fatherName && formik.errors.fatherName ? (
                        <div className="text-danger fs-6">{formik.errors.fatherName}</div>
                    ) : null}
                </div>
            </div>
            <div className="row m-0 p-0 mb-3">

                <div className="col-5 offset-1">
                    <label htmlFor="branch" className="addStdLable">
                        Branch*
                    </label>
                    <input
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.branch}
                        name="branch"
                        type="text"
                        className={formik.touched.branch ? `form-control ${formik.errors.branch ? "invalid" : ""}` : 'form-control'}
                        // className={formik.touched.branch && formik.errors.branch ? 'form-control is-invalid' : 'form-control'}
                        placeholder="Enter branch"
                    />
                    {formik.touched.branch && formik.errors.branch ? (
                        <div className="text-danger fs-6">{formik.errors.branch}</div>
                    ) : null}
                </div>
                <div className="col-5 ">
                    <label htmlFor="mobileNumber" className="addStdLable">
                        Mobile Number*
                    </label>
                    <NumberFormat
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mobileNumber}
                        name="mobileNumber"
                        className={formik.touched.mobileNumber ? `form-control ${formik.errors.mobileNumber ? "invalid" : ""}` : 'form-control'}

                        // className={formik.touched.mobileNumber && formik.errors.mobileNumber ? 'form-control is-invalid' : 'form-control'}
                        format="##########"
                        pattern="[0-9]*"
                        // maxLength="10"
                        // minLength="10"
                        placeholder="Enter mobile number"
                    />
                    {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                        <div className="text-danger fs-6">{formik.errors.mobileNumber}</div>
                    ) : null}
                </div>
            </div>
            <div className="row m-0 p-0 mb-3">
                <div className="col-5 offset-1">
                    <label htmlFor="adhaar" className="addStdLable">
                        Adhaar Number*
                    </label>
                    <NumberFormat
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.adhaar}
                        name="adhaar"
                        className={formik.touched.adhaar ? `form-control ${formik.errors.adhaar ? "invalid" : ""}` : 'form-control'}
                        // className={formik.touched.adhaar && formik.errors.adhaar ? 'form-control is-invalid' : 'form-control'}
                        format="####-####-####"
                        pattern="[0-9\-]+"
                        placeholder="Enter adhaar number"
                    />
                    {formik.touched.adhaar && formik.errors.adhaar ? (
                        <div className="text-danger fs-6">{formik.errors.adhaar}</div>
                    ) : null}
                </div>
                <div className="col-5 ">
                    <label htmlFor="session" className="addStdLable">
                        Session*
                    </label>
                    <select
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.session}
                        name="session"
                        className={formik.touched.session ? `form-select ${formik.errors.session ? "invalid" : ""}` : 'form-select'}
                    // className={formik.touched.session && formik.errors.session ? 'form-select is-invalid' : 'form-select'}
                    >
                        <option value="2024-27" selected >2024-27</option>
                        <option value="2023-26">2023-26</option>
                        <option value="2022-25">2022-25</option>
                        <option value="2021-24">2021-24</option>
                        <option value="2025-28">2025-28</option>
                        <option value="2026-29">2026-29</option>

                        <option value="2022-23">2022-23</option>
                        <option value="2023-24">2023-24</option>
                        <option value="2024-25">2024-25</option>
                        <option value="2025-26">2025-26</option>
                        <option value="2026-27">2026-27</option>

                    </select>
                    {formik.touched.session && formik.errors.session ? (
                        <div className="text-danger fs-6">{formik.errors.session}</div>
                    ) : null}
                </div>
            </div>
            <div className="row m-0 p-0 mb-3">
                <div className="col-5 offset-1">
                    <label htmlFor="category" className="addStdLable">
                        Category*
                    </label>
                    <select
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.category}
                        name="category"
                        className={formik.touched.category ? `form-select ${formik.errors.category ? "invalid" : ""}` : 'form-select'}
                    // className={formik.touched.category && formik.errors.category ? 'form-select is-invalid' : 'form-select'}
                    >
                        <option value="BEG">BEG</option>
                        <option value="MEG">MEG</option>
                        <option value="ITEG">ITEG</option>
                    </select>
                    {formik.touched.category && formik.errors.category ? (
                        <div className="text-danger fs-6">{formik.errors.category}</div>
                    ) : null}
                </div>
                <div className="col-5 ">
                    <label htmlFor="photo" className="addStdLable">
                        Photo*
                    </label>
                    <input
                        onChange={(e) => {formik.handleChange(e); handlePhoto(e)}}
                        onBlur={formik.handleBlur}
                        value={formik.values.photo}
                        name="photo"
                        accept=".jpeg,.jpg"
                        type="file"
                        className={formik.touched.photo ? `form-control ${formik.errors.photo ? "invalid" : ""}` : 'form-control'}
                    // className={formik.touched.photo && formik.errors.photo ? 'form-control is-invalid' : 'form-control'}
                    />
                    {formik.touched.photo && formik.errors.photo ? (
                        <div className="text-danger fs-6">{formik.errors.photo}</div>
                    ) : null}
                </div>
            </div>
            <div className=' mt-5 d-flex justify-content-center'>
                <button type="submit" className="btn btn-dark">
                    Print ID Card
                </button>
            </div>
        </form>
        </div>
    );
};

export default Beg_Meg_Iteg_Id_card;
