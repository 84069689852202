import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import fontFamily from '../assets/Roboto-Medium.ttf';
import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';
import AllUrl from '../../../../redux/constants/url';
import { showToast } from '../utils/toast';
let fetchedFont;

export const DownloadCertificatePdfAsZip = async (excelData) => {
    try {
        console.log('Getting Certificate data...');
        const zip = new JSZip();
        const notEligibleStudents = [];
        const totalStudents = excelData.length;

        let level1File, level2File, level3File;

        // temporarily keep track of missing group certificates
        let missingGroupCertificatesCount = 0;

        // Fetch font once
        const fontBytes = await fetchFontFamily();

        for (const student of excelData) {
            const { name, level, group, knowledge } = student;

            // Validate student information
            if (!name || !level || !group || !knowledge) {
                notEligibleStudents.push(student);
                continue;
            }

            // Cache certificate file based on level and group
            const groupLower = group.toLowerCase();
            const levelLower = level.toLowerCase();

            // Fetch certificate file based on group and level
            if (groupLower === "iteg") {
                if (["1", "1a", "1b", "1c", "a"].includes(levelLower) && !level1File) {
                    level1File = await fetchLevelCertificate(AllUrl.certificate_url);
                } else if (["2", "2a", "2b", "2c", "b"].includes(levelLower) && !level2File) {
                    level2File = await fetchLevelCertificate(AllUrl.certificate_url);
                } else if (["3", "3a", "3b", "3c", "c"].includes(levelLower) && !level3File) {
                    level3File = await fetchLevelCertificate(AllUrl.certificate_url);
                }
            } else {
                console.log(`No Certificates available for group: ${group}`);
                missingGroupCertificatesCount++;
                continue;
            }

            const certificateFile = level1File || level2File || level3File;


            // Generate PDF
            const pdfBytes = await generatePDF(capitalizeFirstLetter(name), level, knowledge, certificateFile, fontBytes);
            if (pdfBytes) {
                zip.file(`${name.toUpperCase()}-Level-${parseInt(level)}-${group.toUpperCase()}.pdf`, pdfBytes);
            }
        }

        // temporarily keep track of missing group certificates
        const eligibleStudentsCount = totalStudents - notEligibleStudents.length - missingGroupCertificatesCount;
        const notEligibleCount = totalStudents - eligibleStudentsCount;
        console.log(`Not Eligible Students: ${notEligibleCount}`);

        // Handle toast notifications for not eligible students and total students
        handleToastNotifications(notEligibleCount, totalStudents);

        if (eligibleStudentsCount !== 0) {
            const zipBlob = await zip.generateAsync({ type: 'blob' });
            saveAs(zipBlob, 'certificates.zip');
            showToast(`${eligibleStudentsCount} students level certificates downloaded successfully!`, 'success');

        }
    } catch (error) {
        console.error('Error generating PDFs:', error);
    }
};

// Genrate PDF for each student
const generatePDF = async (name, level, knowledge, file, fontBytes) => {
    try {
        const pdfDoc = await PDFDocument.load(file);
        pdfDoc.registerFontkit(fontkit)
        const customFont = await pdfDoc.embedFont(fontBytes);
        const firstPage = pdfDoc.getPages()[0];
        const textWidth = customFont.widthOfTextAtSize(name, 30);
        const x = (firstPage.getSize().width - textWidth) / 2;

        firstPage.drawText(name, {
            x: x + 100,
            y: firstPage.getSize().height / 2 - 50,
            size: 30,
            color: rgb(0, 0, 0),
            font: customFont,
        });

        firstPage.drawText(`"Level ${parseInt(level)} Certification"`, {
            x: 415,
            y: 170,
            size: 22,
            color: rgb(0, 0.5, 0.5),
            font: customFont,
        });

        firstPage.drawText(knowledge, {
            x: 545,
            y: 143,
            size: 18,
            color: rgb(0.9, 0.5, 0),
            font: customFont,
        });

        return await pdfDoc.save();
    } catch (error) {
        console.error(`Error generating PDF for ${name} - Level ${level}:`, error);
        return null;
    }
};
// Fetch the certificate template for different levels
const fetchLevelCertificate = async (url) => {
    try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        return await response.arrayBuffer();
    } catch (error) {
        console.error('Error fetching certificate:', error);
        return null;
    }
};

// Fetch the font family
const fetchFontFamily = async () => {
    if (!fetchedFont) {
        const response = await fetch(fontFamily);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        fetchedFont = await response.arrayBuffer();
    }
    return fetchedFont;
};

// Function to handle toast notifications for not eligible students and total students
const handleToastNotifications = (notEligibleCount, totalStudents) => {
    if (notEligibleCount > 0) {
        if (totalStudents === 1) {
            showToast(`Certification details are incomplete! Please update their profile to proceed.`, 'warn');
        } else if (totalStudents === notEligibleCount) {
            showToast(`All students certification details are incomplete! Please update their profile to proceed..`, 'warn');
        } else {
            showToast(`${notEligibleCount} Certification details are incomplete! Please update their profile to proceed.`, 'warn');
        }
    }
};  